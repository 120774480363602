import React, { Component } from "react";
import MainScreenDefault from "../../components/MainScreenDefault";
import MainScreen from "../../components/MainScreen";
import Block from "../../models/Block";
import { getBlocks } from "../../api";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { WithTranslation, withTranslation } from "react-i18next";

interface State {
    isLoading: boolean;
    blocks: Block[];
}

class Main extends Component<WithTranslation, State> {
    state: State = {
        isLoading: true,
        blocks: []
    };

    async componentDidMount(): Promise<void> {
        const blocks = await getBlocks();
        this.setState({ isLoading: false, blocks });
    }

    render() {
        const { isLoading, blocks } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <MainScreenDefault title={t("mainScreen.title")} subtitle={t("mainScreen.subtitle")} />
                {blocks.map(block => {
                    return <MainScreen key={block.id} {...block} />;
                })}
            </Layout>
        );
    }
}

export default withTranslation()(Main);

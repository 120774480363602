import React, { Component } from "react";
import { MainScreenWrapper } from "./Atoms";
import { constants } from "../../config";
import { WithTranslation, withTranslation } from "react-i18next";
interface Props {
    className?: string;
    title: string;
    subtitle: string;
}

const BITRIX_FORM = `
        (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
                (w[b].forms=w[b].forms||[]).push(arguments[0])};
                if(w[b]['forms']) return;
                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://b24.iq-charity.com/bitrix/js/crm/form_loader.js','b24form');

        b24form({"id":"14","lang":"ru","sec":"shnlrn","type":"button","click":""});
`;
const BITRIX_FORM_EN = `
        (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
                (w[b].forms=w[b].forms||[]).push(arguments[0])};
                if(w[b]['forms']) return;
                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://b24.iqcharity.org/bitrix/js/crm/form_loader.js','b24form');

        b24form({"id":"6","lang":"ru","sec":"xonb3j","type":"button","click":""});
`;

class MainScreenDefault extends Component<WithTranslation & Props> {
    componentDidMount(): void {
        const script = document.createElement("script");
        script.async = true;
        script.id = "bx24_form_button";
        script.setAttribute("data-skip-moving", "true");
        script.innerHTML = BITRIX_FORM;
        script.innerHTML = constants.LOCALE === "ru" ? BITRIX_FORM : BITRIX_FORM_EN;
        document.getElementById("formDiv")!.appendChild(script);
    }

    render() {
        const { className, title, subtitle, t } = this.props;
        return (
            <React.Fragment>
                {constants.LOCALE === "ru" && (
                    <MainScreenWrapper className={className}>
                        <div className="intro">
                            <div className="mobileBg" />
                            <div className="main_text">
                                <h1 className="h1">
                                    <span>{t("mainScreen.title.text1")}</span>
                                    <span>{t("mainScreen.title.text2")}</span>
                                    <span>{t("mainScreen.title.text3")}</span>
                                </h1>
                                <p className="subhead">{subtitle}</p>
                            </div>
                            <div id="formDiv" />
                        </div>
                    </MainScreenWrapper>
                )}
                {constants.LOCALE === "en" && (
                    <MainScreenWrapper className={`mainScreenEn ` + className}>
                        <div className="intro">
                            <div className="mobileBg" />
                            <div className="main_text">
                                <h1 className="h1">{title}</h1>
                                <p className="subhead">{subtitle}</p>
                            </div>
                            <div id="formDiv" />
                            <button className="b24-web-form-popup-btn-6 formBtn">Subscribe to news</button>
                        </div>
                    </MainScreenWrapper>
                )}
            </React.Fragment>
        );
    }
}

export default withTranslation()(MainScreenDefault);

import styled from "styled-components";
import { asset } from "../../../utils";

export const HeadSlideBg = styled.div<{ background: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${props => asset(props.background)}) center center no-repeat;
    background-size: cover;
    &:before {
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
`;

export const HeadSlideTextBlock = styled.div<{ color: string; subtitleColor: string }>`
    width: 100%;
    max-width: 1088px;
    padding: 40px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    top: 0;
    text-align: left;
    z-index: 2;
    div {
        max-width: 524px;
    }
    h1 {
        font-size: 30px;
        line-height: 1.2;
        color: ${props => props.color};
        text-transform: none;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        padding-bottom: 25px;
    }
    p {
        font-size: 18px;
        color: ${props => props.subtitleColor};
        text-transform: none;
    }
    .date_block {
        time {
            font-size: 16px;
            color: ${props => props.color};
        }
    }
`;

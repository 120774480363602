import React from "react";
import { asset } from "../../utils";
import Country from "../../models/Country";
import "moment/locale/ru";
import { MainScreenWrapper } from "./Atoms";
import DateBlock from "../DateBlock";
import { useTranslation } from "react-i18next";

interface Props {
    className?: string;
    title: string;
    subtitle: string;
    buttonText: string;
    backgroundPath: string | null;
    mobileBackgroundPath: string | null;
    link: string;
    titleColor: string;
    subtitleColor: string;
    buttonTextColor: string;
    buttonBodyColor: string;
    country: Country | null;
    startedAt: string;
}

const MainScreen: React.FC<Props> = props => {
    const bgElem = props.backgroundPath;
    let isVideoBg = false;
    function checkFileType(file: string) {
        file.toString().toLocaleLowerCase();
        return file !== "png" && file !== "jpg" && file !== "jpeg" && file !== "svg" && file !== "svg";
    }
    if (bgElem) {
        const [fileExtension] = bgElem.split(/\.(?=[^.]+$)/);
        isVideoBg = checkFileType(fileExtension);
    }

    const { t } = useTranslation();

    return (
        <MainScreenWrapper {...props}>
            <div className="intro">
                {isVideoBg && bgElem && (
                    <div className="videoBg">
                        <video autoPlay muted loop preload="auto" src={asset(bgElem)} />
                    </div>
                )}
                <div className="mobileBg" />
                <div className="main_text">
                    <DateBlock
                        flagPath={props.country ? props.country.flagPath : null}
                        publicationDate={props.startedAt}
                        dateColor={props.subtitleColor}
                    />
                    <h1 className="h1">{props.title}</h1>
                    <p className="subhead">{props.subtitle}</p>
                    <div className="btn_wrap">
                        <a className="button" href={props.link}>
                            {t("blocks.buttonText." + props.buttonText)}
                        </a>
                    </div>
                </div>
            </div>
        </MainScreenWrapper>
    );
};

export default MainScreen;

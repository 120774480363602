import axios from "axios";
import Block from "../models/Block";
import Partner from "../models/Partner";
import Project from "../models/Project";
import Report from "../models/Report";

export const getBlocks = async (): Promise<Block[]> => {
    const result = await axios.get("/api/front/blocks");
    return result.data.data as Block[];
};

export const getPartners = async (): Promise<Partner[]> => {
    const result = await axios.get("/api/front/partners");
    return result.data.data as Partner[];
};

export const getOnePartner = async (id: number): Promise<Partner> => {
    const result = await axios.get(`/api/front/partners/${id}`);
    return result.data.data as Partner;
};

export const getProjects = async (): Promise<Project[]> => {
    const result = await axios.get("/api/front/projects");
    return result.data.data as Project[];
};

export const getOneProject = async (slug: string): Promise<Project> => {
    const result = await axios.get(`/api/front/projects/${slug}`);
    return result.data.data as Project;
};

export const getReports = async (): Promise<Report[]> => {
    const result = await axios.get("/api/front/reports");
    return result.data.data as Report[];
};

export const getOneReport = async (slug: string): Promise<Report> => {
    const result = await axios.get(`/api/front/reports/${slug}`);
    return result.data.data as Report;
};

import styled from "styled-components";

export const ErrorPage = styled.div`
    padding-top: 180px;
    padding-bottom: 40px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    text-align: center;
    .notfound {
        max-width: 450px;
        width: 100%;
        text-align: center;
        line-height: 1.4;
        margin: 0 auto;
    }
    .notfound-404 {
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto 50px;
        & > div:first-child {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #ff9c00;
            transform: rotate(45deg);
            border: 5px dashed #000;
            border-radius: 5px;
            &:before {
                content: "";
                position: absolute;
                left: -5px;
                right: -5px;
                bottom: -5px;
                top: -5px;
                box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1) inset;
                border-radius: 5px;
            }
        }
        h1 {
            color: #000;
            font-weight: 700;
            margin: 0;
            font-size: 90px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
            text-align: center;
            height: 40px;
            line-height: 40px;
        }
    }
    h2 {
        font-size: 28px;
        margin-bottom: 5px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 7px;
        color: #000;
    }
    p {
        font-size: 16px;
        color: #000;
        font-weight: 400;
        margin-bottom: 20px;
    }
    a {
        font-size: 18px;
        line-height: 26px;
        height: 60px;
        display: inline-block;
        padding: 17px 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 285px;
        background: #ff9c00;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        transition: opacity 0.5s ease-out;
        cursor: pointer;
        position: relative;
        &:after {
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: transparent;
            transition: background-color 0.5s ease-out;
            position: absolute;
            content: "";
        }
        &:hover {
            &:after {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
`;

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Slider from "react-slick";
import Layout from "../../../components/Layout";
import Project from "../../../models/Project";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Loader from "../../../components/Loader/Loader";
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    VKShareButton,
    VKShareCount,
    VKIcon,
    OKShareButton,
    OKShareCount,
    OKIcon
} from "react-share";
import girlImg from "./assets/img/girl.png";
import shareImage from "./assets/img/share.jpg";
import aim from "../Python/assets/img/aim.png";
import meet from "./assets/img/meet-logo.png";
import girl01 from "./assets/img/girl_01.jpg";
import girl02 from "./assets/img/girl_02.jpg";
import girl03 from "./assets/img/girl_03.jpg";
import girl04 from "./assets/img/girl_04.jpg";
import girl05 from "./assets/img/girl_05.jpg";
import "./assets/style.scss";

interface State {
    isLoading: boolean;
    project: Project | null;
    showSlides: boolean;
}

interface Props {
    slug: string;
}

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true
};

class GirlsDoIt extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true,
        showSlides: false
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }
        this.updateShowSlides();
        window.addEventListener("resize", this.updateShowSlides);
    }

    updateShowSlides = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.setState({
            showSlides: width < 1200
        });
    };

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateShowSlides);
    }
    render() {
        const { isLoading, project } = this.state;
        const shareUrl = "https://iqcharity.ru/projects/girlsdoit";
        const title = "IQcharity";

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="girlsDoItPage" id="girlsDoItPage">
                    <div className="main__slide girlsDoIt__main__slide">
                        <div className="section-inner clear">
                            <div className="main__slide__text-block">
                                <h1 className="h1">All You Need is IT</h1>
                                <p>
                                    Бесплатное онлайн-мероприятие для девушек,
                                    <br /> которые интересуются IT
                                    <br /> <strong>17 октября, 13:00 (мск)</strong>
                                </p>
                                <div className="buttonWrapper">
                                    <a
                                        href="https://docs.google.com/forms/d/1QZw7QZkfNP5AflbwaRtKq79vfu7bGepeuCn8cyKeEbs/edit?usp=sharing"
                                        className="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Участвовать
                                    </a>
                                </div>
                                <div className="timerWrapper">Успей зарегистрироваться!</div>
                            </div>
                        </div>
                        <div className="main__slide__background-block">
                            <img src={girlImg} alt="" />
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="girlsDoIt__about about">
                            <div className="section-inner">
                                <div className="about__img">
                                    <img src={meet} alt="" />
                                </div>
                                <div className="about__text">
                                    All You Need is IT – бесплатное онлайн-мероприятие, организованное{" "}
                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                        Благотворительный фонд «Возможности интеллекта»
                                    </a>{" "}
                                    для девушек в возрасте 15-24 лет в рамках инициативы{" "}
                                    <a
                                        href="https://www.meet-and-code.org/ru/ru/explore"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Meet and Code
                                    </a>{" "}
                                    и Европейской недели программирования.
                                </div>
                            </div>
                        </div>
                        <div className="girlsDoIt__includes course__includes">
                            <div className="section-inner">
                                <div className="project_in-number">
                                    <div className="project_in-number__column">
                                        <div className="stats">
                                            <span>
                                                90 <span>минут</span>
                                            </span>
                                            длительность мероприятия
                                        </div>
                                    </div>
                                    <div className="project_in-number__column">
                                        <div className="stats">
                                            <span>ZOOM</span>
                                            площадка мероприятия
                                        </div>
                                    </div>
                                    <div className="project_in-number__column">
                                        <div className="stats">
                                            <span>
                                                15-24 <span>лет</span>
                                            </span>
                                            возраст участниц
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="girlsDoIt__target target target-bordered">
                            <div className="section-inner clear">
                                <div className="target__icon">
                                    <span>
                                        <img src={aim} alt="" />
                                    </span>
                                </div>
                                <div className="target__text">
                                    <p>
                                        <strong>Цель мероприятия</strong> – вдохновить участниц и показать девушкам, что
                                        каждая из них может найти и профессионально реализовать себя в IT. Поделиться
                                        опытом, как работать со стереотипами и потенциальными сложностями.
                                    </p>
                                    <p>
                                        <strong>
                                            Давайте вместе развеем миф о том, что девушки и IT несовместимы!
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="girlsDoIt__reviews reviews">
                            <div className="section-inner">
                                <h2 className="h2">Спикеры мероприятия</h2>
                                <div className="subhead">
                                    Встреча пройдет в онлайн-формате. Приглашенные эксперты, представительницы
                                    IT-профессий, поделятся своим опытом и ответят на вопросы участниц мероприятия.
                                </div>
                                <div className="slider-wrapper">
                                    <Slider {...sliderSettings} className="sliderReview">
                                        <div>
                                            <div className="review">
                                                <div className="review__img">
                                                    <img src={girl01} alt="" />
                                                </div>
                                                <div className="review__owner">
                                                    Анна Плоткина,{" "}
                                                    <span>
                                                        Основательница проекта WOMEN POWER IT, Product Manager в
                                                        SkillFactory
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__img">
                                                    <img src={girl02} alt="" />
                                                </div>
                                                <div className="review__owner">
                                                    Алёна Медведева,{" "}
                                                    <span>Head of Products for teachers в «Skyeng»</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__img">
                                                    <img src={girl03} alt="" />
                                                </div>
                                                <div className="review__owner">
                                                    Оксана Дереза,{" "}
                                                    <span>
                                                        Researcher Insight Centre for Data Analytics (National
                                                        University of Ireland Galway)
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__img">
                                                    <img src={girl04} alt="" />
                                                </div>
                                                <div className="review__owner">
                                                    Дарья Колесова,{" "}
                                                    <span>Инженер-разработчица в компании «Тензор»</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__img">
                                                    <img src={girl05} alt="" />
                                                </div>
                                                <div className="review__owner">
                                                    Маргарита Овчинникова,{" "}
                                                    <span>QA-инженер (в тестировании 1 год)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="girlsDoIt__course project__course">
                            <div className="section-inner clear">
                                <div className="project__course__course-text">
                                    <h2 className="h2">Что обсудим на мероприятии?</h2>
                                    <ul>
                                        <li>Истории спикеров о том, как они начинали свою карьеру в IT;</li>
                                        <li>
                                            Стереотипы, с которыми они сталкивались на пути в профессию и как их
                                            преодолевали;
                                        </li>
                                        <li>
                                            Как прийти в IT из другой сферы и/или, если у тебя нет профессионального
                                            образования;
                                        </li>
                                        <li>
                                            Как можно получить необходимые знания ещё в школе и где получать образование
                                            для старта карьеры;
                                        </li>
                                        <li>Где брать первый опыт работы в IT;</li>
                                        <li>
                                            Профессиональные сообщества, в которых можно найти поддержку и помощь в
                                            процессе обучения программированию.
                                        </li>
                                    </ul>
                                </div>
                                <div className="project__course__img" />
                            </div>
                        </div>
                        <div className="girlsDoIt__target2 target target-bordered">
                            <div className="section-inner">
                                <div className="target__text">
                                    <p>
                                        После выступления спикеры дадут рекомендации, которых можно придерживаться на
                                        пути в профессию. По итогам мероприятия участницы получат чек-лист с
                                        необходимыми действиями для старта карьеры в IT, а также ресурсы для
                                        самостоятельного обучения и сообщества, в которых можно получить
                                        профессиональную поддержку.
                                    </p>
                                    <div className="text-center">
                                        <a
                                            href="https://docs.google.com/forms/d/1QZw7QZkfNP5AflbwaRtKq79vfu7bGepeuCn8cyKeEbs/edit?usp=sharing"
                                            className="button"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Участвовать
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="girlsDoIt__target_bottom-text bottom-text">
                                Инициатива{" "}
                                <a
                                    href="https://www.meet-and-code.org/ru/ru/explore"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Meet and Code
                                </a>{" "}
                                поддерживает мероприятия, которые пройдут в 35 странах мира в рамках Европейской недели
                                программирования. Целью инициативы является вовлечение молодых людей и детей в возрасте
                                от 8 до 24 лет в мир технологий и программирования.
                            </div>
                        </div>
                        <div className="pythonGen__share share_buttons_block">
                            <div className="section-inner">
                                <h2 className="h2">Рассказать о мероприятии друзьям</h2>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                            {count => count}
                                        </FacebookShareCount>
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <VKShareButton
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKIcon size={32} round />
                                    </VKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <OKShareButton
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKIcon size={32} round />
                                    </OKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default GirlsDoIt;

import React, { Component } from "react";
import Slider from "react-slick";
import { RouteComponentProps } from "react-router";
import Layout from "../../../components/Layout";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import book2 from "./assets/img/icon-books2.svg";
import access2 from "./assets/img/icon-access2.svg";
import support2 from "./assets/img/icon-support2.svg";
import cursor from "./assets/img/icon-cursor.svg";
import text from "./assets/img/icon-text.svg";
import pencil from "./assets/img/icon-pencil.svg";
import projector from "./assets/img/icon-projector.svg";
import "./assets/style.css";
import "./assets/new_style.scss";

interface State {
    isLoading: boolean;
    project: Project | null;
    showSlides: boolean;
}

interface Props {
    slug: string;
}

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true
};

const COSMOS_LAYOUT_SLIDES = [
    <div key="1" className="grant-block">
        <div className="grant-img">
            <img src={book2} alt="" />
        </div>
        <div className="grant-text">
            <strong>Бесплатное современное методическое пособие</strong>, включающее наглядные презентации к каждому
            уроку, интерактивные задания, руководства по использованию приложений для проведения опросов и тестов среди
            учеников, эталонную верстку макета для итоговой работы учащихся.
        </div>
    </div>,
    <div key="2" className="grant-block">
        <div className="grant-img">
            <img src={access2} alt="" />
        </div>
        <div className="grant-text">
            <strong>Доступ к онлайн-кабинету</strong> с занятиями для учителей и учеников с возможностью отслеживания
            прогресса учащихся.
        </div>
    </div>,
    <div key="3" className="grant-block">
        <div className="grant-img">
            <img src={support2} alt="" />
        </div>
        <div className="grant-text">
            <strong>Бесплатные консультации</strong> по ведению курса в чате со специалистами.
        </div>
    </div>
];

const SPACE_REQUIREMENTS = [
    <div key="1" className="requirements-block">
        <div className="requirements-img">
            <img src={cursor} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Современный браузер</div>
            <p>Актуальная версия браузеров Chrome, Firefox или Edge. Скорость интернета более 5 МБ\сек</p>
        </div>
    </div>,
    <div key="2" className="requirements-block">
        <div className="requirements-img">
            <img src={text} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Текстовый редактор</div>
            <p>
                Мы рекомендуем выбрать редактор с подсветкой кода и возможностью добавлять полезные плагины. Например,{" "}
                <a href="http://brackets.io/" target="_blank" rel="noreferrer noopener">
                    Brackets
                </a>
                .
            </p>
        </div>
    </div>,
    <div key="3" className="requirements-block">
        <div className="requirements-img">
            <img src={pencil} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Графический редактор</div>
            <p>
                Работа верстальщика тесно связана с макетом, поэтому нужен графический редактор. Для этого подойдет,
                например, бесплатный редактор{" "}
                <a href="https://www.gimp.org/" target="_blank" rel="noreferrer noopener">
                    GIMP
                </a>
                .
            </p>
        </div>
    </div>,
    <div key="4" className="requirements-block">
        <div className="requirements-img">
            <img src={projector} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Большой экран</div>
            <p>Желательно наличие проектора или большого экрана для демонстрации презентаций ученикам.</p>
        </div>
    </div>
];

const renderFoundationSlider = (inSlider: boolean, slides: React.ReactNode[]): React.ReactNode => {
    if (inSlider) {
        return (
            <Slider {...sliderSettings} className="slider2">
                {slides}
            </Slider>
        );
    }

    return slides;
};

class CosmosLayout extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true,
        showSlides: false
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }

        this.updateShowSlides();
        window.addEventListener("resize", this.updateShowSlides);
    }

    updateShowSlides = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.setState({
            showSlides: width < 1200
        });
    };

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateShowSlides);
    }

    render() {
        const { isLoading, project, showSlides } = this.state;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="kosmos_page">
                    <div className="cosmosLayout-slide">
                        <div className="cosmosLayout-head">
                            <div className="section-inner clear">
                                <div className="background-block" />
                                <div className="text-block">
                                    <h1 className="h1">Космическая верстка</h1>
                                    <p>
                                        Благотворительный проект, благодаря которому преподаватели получают методические
                                        рекомендации, спланированные занятия с интерактивными заданиями, а школьники в
                                        возрасте 12-18 лет под их руководством бесплатно изучают основы Web-разработки
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="cosmosLayout__about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    Благотворительный фонд «Возможности интеллекта» реализует проект совместно с{" "}
                                    <a href="http://htmlacademy.ru/" target="_blank" rel="noopener noreferrer">
                                        HTML Academy
                                    </a>{" "}
                                    при участии{" "}
                                    <a href="http://geekteachers.ru/" target="_blank" rel="noopener noreferrer">
                                        Geek Teachers
                                    </a>
                                    . Курс бесплатный для учителей бюджетных образовательных учреждений и их учеников,
                                    предназначен для внеурочной деятельности в школах и центрах творчества.
                                </div>
                            </div>
                        </div>
                        <div className="cosmosLayout__target target target-bordered">
                            <div className="section-inner">
                                <div className="target__text">
                                    <h2 className="h2">Цель проекта</h2>
                                    <p>
                                        Предоставить возможность ученикам получить навыки Web-разработки еще в школе и
                                        оказать поддержку учителям с высокой мотивацией в развитии в профессиональной
                                        сфере.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="space_geography">
                            <div className="select">
                                <h2 className="h2">География проекта</h2>
                            </div>
                            <div className="map">
                                <iframe
                                    src="https://snazzymaps.com/embed/143365"
                                    width="100%"
                                    height="450px"
                                    title="map"
                                />
                            </div>
                        </div>
                        <div className="space_grant">
                            <div className="section-inner">
                                <h2 className="h2">Проект в цифрах за 2019/20 и 2020/21 учебные годы:</h2>
                                <div className="grant-text">
                                    <div className="stats">
                                        <span>300+</span> преподавателей
                                    </div>
                                    <div className="stats">
                                        <span>3000+</span> учеников
                                    </div>
                                    <div className="stats">
                                        <span>300+</span> учебных заведений
                                    </div>
                                    <div className="stats">
                                        <span>60+</span> регионов
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space_grant">
                            <div className="section-inner">
                                <h2 className="h2">
                                    Благотворительный фонд <br />
                                    предоставляет:
                                </h2>
                                <div className="slider-wrapper">
                                    {renderFoundationSlider(showSlides, COSMOS_LAYOUT_SLIDES)}
                                </div>
                            </div>
                        </div>
                        <div className="space_course">
                            <div className="section-inner clear">
                                <div className="course-img" />
                                <div className="course-text">
                                    <h2 className="h2">Учебный курс</h2>
                                    <div className="subhead">
                                        Учебный курс включает знакомство с принципами работы Интернета, освоение навыков
                                        веб-дизайна и создание собственного сайта.
                                    </div>
                                    <ul>
                                        <li>
                                            <strong>18 готовых занятий</strong> по 90 минут
                                        </li>
                                        <li>
                                            Контроль преподавателем индивидуального прогресса ученика в онлайн-кабинете
                                        </li>
                                        <li>
                                            Продолжительность: <strong>до 36 академических часов</strong>
                                        </li>
                                        <li>
                                            Рекомендованное количество учеников в группе: <strong>до 15 человек</strong>{" "}
                                            – (максимум 2 группы на преподавателя, 30 аккаунтов для учеников на
                                            образовательное учреждение)
                                        </li>
                                        <li>
                                            Возраст учеников: <strong>12-18 лет</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="space_requirements">
                            <div className="section-inner">
                                <h2 className="h2">
                                    Технические требования <br />
                                    для работы с курсом
                                </h2>
                                <div className="slider-wrapper">
                                    {renderFoundationSlider(showSlides, SPACE_REQUIREMENTS)}
                                </div>
                            </div>
                        </div>
                        <div className="space_layout-reviews">
                            <div className="section-inner">
                                <h2 className="h2">Отзывы</h2>
                                <div className="slider-wrapper">
                                    <Slider {...sliderSettings} className="sliderReview">
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Участвую в проекте «Космическая верстка» с самого начала. За это
                                                    время узнала много интересных вещей из мира верстки сайтов,
                                                    почерпнула идеи для занятий, начала использовать новые сервисы в
                                                    работе. Многим из ребят понравился мир создания веб-сайтов. Кто-то
                                                    всерьез задумался связать свою жизнь с профессией
                                                    фронтенд-разработчика. Итут я чувствую некую ответственность за это,
                                                    хочу дать максимум знаний, помочь им. А проект «Космическая верстка»
                                                    – то, что нужно».
                                                </div>
                                                <div className="review__owner">
                                                    Валентина Крылова,{" "}
                                                    <span>педагог дополнительного образования, г. Красноярск</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Большое спасибо за предоставленный материал и вашу работу. Это
                                                    бесценная помощь нам, преподавателям, в организации внеурочной
                                                    деятельности. Многому научилась сама и надеюсь смогла научить и ещё
                                                    научу своих учеников. Огромное вам спасибо!»
                                                </div>
                                                <div className="review__owner">
                                                    Демакова Наталья,{" "}
                                                    <span>
                                                        учитель математики и информатики, МБОУ "СШ № 1 г.Вельска"
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Спасибо за отличный курс! Таких материалов очень не хватает для
                                                    проведения качественных занятий. Спасибо за возможность получать
                                                    новые знания вместе с детьми, за то, что помогаете справиться с
                                                    возникающими сложностями».
                                                </div>
                                                <div className="review__owner">
                                                    Андреева Мария, <span>учитель информатики, ГБОУ школа № 337</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default CosmosLayout;

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Layout from "../../../components/Layout";
import Project from "../../../models/Project";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Loader from "../../../components/Loader/Loader";
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    VKShareButton,
    VKShareCount,
    VKIcon,
    OKShareButton,
    OKShareCount,
    OKIcon
} from "react-share";
import pythonImg from "./assets/img/python_stp_header.png";
import robo from "./assets/img/p3.png";
import aim from "./assets/img/p4.png";
import shareImage from "./assets/img/share.jpg";
import "./assets/style.scss";

interface State {
    isLoading: boolean;
    project: Project | null;
}

interface Props {
    slug: string;
}

class PythonPro extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }
    }
    render() {
        const { isLoading, project } = this.state;
        const shareUrl = "https://iqcharity.ru/projects/pythononlinepro";
        const title = "IQcharity";

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="pythonOnlineProPage" id="pythonOnlineProPage">
                    <div className="main__slide pythonOnlinePro">
                        <div className="section-inner clear">
                            <div className="main__slide__text-block">
                                <h1 className="h1">
                                    Поколение Python:
                                    <br /> курс для продвинутых на Stepik.org
                                </h1>
                                <p>
                                    <strong>Бесплатный онлайн-курс</strong>
                                    <br /> про особенности и возможности языка Python
                                </p>
                                <div className="buttonWrapper">
                                    <a
                                        href="https://stepik.org/course/68343/promo"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="button"
                                    >
                                        Пройти курс
                                    </a>
                                </div>
                            </div>
                            <div className="main__slide__background-block">
                                <img src={pythonImg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="pythonOnlinePro__about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    Продолжение онлайн-курса по изучению основ программирования «Поколение Python: курс
                                    для начинающих» на{" "}
                                    <a href="https://stepik.org/" target="_blank" rel="noopener noreferrer">
                                        Stepik
                                    </a>{" "}
                                    разработанного{" "}
                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                        Благотворительный фонд «Возможности интеллекта»
                                    </a>{" "}
                                    («Возможность Интеллекта») совместно со школой{" "}
                                    <a href="https://stepik.org/org/beegeek" target="_blank" rel="noopener noreferrer">
                                        BEEGEEK
                                    </a>{" "}
                                    Курс бесплатный, является частью проекта{" "}
                                    <a
                                        href="https://iqcharity.ru/projects/python"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        «Поколение Python»
                                    </a>{" "}
                                    для школьников и учителей, но доступен всем желающим, независимо от возраста и
                                    местоположения.
                                </div>
                            </div>
                        </div>
                        <div className="pythonOnlinePro__include course__includes">
                            <div className="section-inner">
                                <div className="course__includes__wrapper">
                                    <h2 className="h2">Курс включает:</h2>
                                    <div className="course__includes_in-number project_in-number">
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>63</span>
                                                урока
                                            </div>
                                        </div>
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>285</span>
                                                интерактивных задач
                                            </div>
                                        </div>
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>428</span>
                                                тестов
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kosmos-target python">
                            <div className="section-inner">
                                <div className="course__includes__bottom">
                                    <a
                                        href="https://stepik.org/course/68343/promo"
                                        className="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ПРОГРАММА КУРСА
                                    </a>
                                    <div className="course__includes__bottom__img">
                                        <img src={robo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pythonOnlinePro__course project__course">
                            <div className="section-inner clear">
                                <div className="project__course__course-text">
                                    <h2 className="h2">Учебный курс:</h2>
                                    <ul>
                                        <li>
                                            <strong>Продолжает линию</strong> «Поколение Python: курс для начинающих».
                                        </li>
                                        <li>
                                            Для тех,{" "}
                                            <strong>
                                                кто уже знает базовые алгоритмические конструкции на уровне школьной
                                                программы.
                                            </strong>
                                        </li>
                                        <li>
                                            <strong>Знакомит с дополнительными возможностями языка Python.</strong>
                                        </li>
                                        <li>
                                            <strong>
                                                Включает изучение двумерных массивов, кортежей, множеств, словарей,
                                                парадигмы функционального программирования
                                            </strong>{" "}
                                            и многого другого. В курсе используется версия языка Python ветки 3.x.
                                        </li>
                                    </ul>
                                </div>
                                <div className="project__course__img" />
                            </div>
                        </div>
                        <div className="pythonOnlinePro__target target target-bordered">
                            <div className="section-inner">
                                <div className="target__icon">
                                    <span>
                                        <img src={aim} alt="" />
                                    </span>
                                </div>
                                <div className="target__text">
                                    <p>
                                        <strong>Платформа Stepik позволяет</strong> пройти весь курс с мобильного
                                        устройства, но мы рекомендуем проходить курс на компьютере в интегрированной
                                        среде разработки (IDE), такой как{" "}
                                        <a
                                            href="https://wingware.com/downloads/wing-101"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            WIng 101
                                        </a>{" "}
                                        ,{" "}
                                        <a href="https://thonny.org/" target="_blank" rel="noopener noreferrer">
                                            Thonny
                                        </a>
                                        ,{" "}
                                        <a
                                            href="https://www.jetbrains.com/ru-ru/pycharm/download/#section=mac"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            PyCharm
                                        </a>{" "}
                                        и др.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="kosmos_audience_bottom_text pythonOnlinePro__bottom_text">
                            Для успешного освоения данного курса нужно пройти предыдущий курс, а также иметь базовые
                            знания школьной программы по информатике и математике. По окончании курса слушатели могут
                            получить Сертификат Stepik. Документ позволяет участвовать в{" "}
                            <a href="https://talent2035.kruzhok.org/python" target="_blank" rel="noopener noreferrer">
                                конкурсе «Талант 20.35»
                            </a>
                        </div>
                        <div className="pythonOnlinePro__share share_buttons_block">
                            <div className="section-inner">
                                <h2 className="h2">Рассказать о курсе друзьям</h2>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <FacebookShareButton
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                            {count => count}
                                        </FacebookShareCount>
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <VKShareButton
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKIcon size={32} round />
                                    </VKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <OKShareButton
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKIcon size={32} round />
                                    </OKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default PythonPro;

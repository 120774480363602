import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Slider from "react-slick";
import Popup from "reactjs-popup";
import Iframe from "react-iframe";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Layout from "../../../components/Layout";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import peoples from "./assets/img/header_pic.png";
import geo from "./assets/img/geo.png";
import algo from "./assets/img/algo.png";
import icon1 from "./assets/img/include1.png";
import icon2 from "./assets/img/include2.png";
import icon3 from "./assets/img/include3.png";
import icon4 from "./assets/img/include4.png";
import icon5 from "./assets/img/include5.png";
import goal from "./assets/img/goal.png";
import teacher1 from "./assets/img/teacher1.png";
import teacher2 from "./assets/img/teacher2.png";
import teacher3 from "./assets/img/teacher3.png";
import algoritmika from "./assets/img/robo1.png";
import techIcon1 from "./assets/img/classes.png";
import techIcon2 from "./assets/img/browser.png";
import objective1 from "./assets/img/objective1.png";
import objective3 from "./assets/img/objective3.png";
import objective4 from "./assets/img/objective4.png";
import robo2 from "./assets/img/robo2.png";
import pdf from "./assets/img/pdf.png";
import screen1 from "./assets/img/screen1.png";
import screen2 from "./assets/img/screen2.png";
import polojenie from "./assets/files/polojenie.pdf";
import opisanie from "./assets/files/opisanie.pdf";
import metodicheskieUkazaniya from "./assets/files/metodicheskie_ukazaniya.pdf";
import faq from "./assets/files/faq.pdf";
import evaluationCriteria from "./assets/files/evaluationCriteria.pdf";
import competitionTask from "./assets/files/competitionTask.pdf";

import "./assets/style.css";

interface State {
    isLoading: boolean;
    project: Project | null;
    showSlides: boolean;
}

interface Props {
    slug: string;
}

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true
};

// @ts-ignore
const COSMOS_INCLUDES = [
    <div key="1" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={icon5} alt="" />
            </div>
            <div className="slide_head">
                <strong>Учебно-методический комплекс </strong>по изучению основ программирования
            </div>
        </div>
    </div>,
    <div key="2" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={icon1} alt="" />
            </div>
            <div className="slide_head">
                <strong>
                    Бесплатные методические <br />
                    материалы
                </strong>{" "}
                с поурочным
                <br /> планированием на весь курс
                <br />{" "}
                <Popup
                    trigger={<button className="textButton">посмотреть пример</button>}
                    onOpen={() => {
                        // @ts-ignore
                        window.gtag("event", "просмотр методичек мпк", {
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            event_category: "link",
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            event_action: "materials_mpk_click"
                        });
                        // @ts-ignore
                        window.ym(56419471, "reachGoal", "materials_mpk_click");
                        console.log("клик на посмотреть пример методичек мпк");
                    }}
                    modal
                    closeOnDocumentClick
                >
                    {close => (
                        <div className="modal modal-pdf">
                            <span className="close" onClick={close}>
                                &times;
                            </span>
                            <Iframe url={metodicheskieUkazaniya} width="100%" height="100%" />
                        </div>
                    )}
                </Popup>
            </div>
        </div>
    </div>,
    <div key="3" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={icon2} alt="" />
            </div>
            <div className="slide_head">
                <strong>
                    Бесплатные индивидуальные
                    <br /> аккаунты
                </strong>{" "}
                для учеников
                <br /> и учителя
            </div>
        </div>
    </div>,
    <div key="4" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={icon4} alt="" />
            </div>
            <div className="slide_head">
                <strong>
                    Бесплатную подготовку
                    <br /> учителя{" "}
                </strong>
                и административную
                <br /> поддержку
            </div>
        </div>
    </div>,
    <div key="5" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={icon3} alt="" />
            </div>
            <div className="slide_head">
                <strong>
                    Бесплатную сопутствующую
                    <br /> печатную продукцию
                    <br />{" "}
                </strong>
                (яркие рабочие тетради и<br /> студенческие билеты с<br /> космическими званиями)
            </div>
        </div>
    </div>
];

const TARGET_AUDIENCE = [
    <div key="1" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={teacher1} alt="" />
            </div>
            <div className="slide_head">Учителя информатики</div>
        </div>
    </div>,
    <div key="2" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={teacher2} alt="" />
            </div>
            <div className="slide_head">Учителя начальной и средней школы</div>
        </div>
    </div>,
    <div key="3" className="slide-block">
        <div className="slide">
            <div className="slide_img">
                <img src={teacher3} alt="" />
            </div>
            <div className="slide_head">Педагоги дополнительного образования</div>
        </div>
    </div>
];

const SPACE_REQUIREMENTS = [
    <div key="1" className="requirements-block">
        <div className="requirements-img">
            <img src={techIcon1} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Компьютерный класс </div>
            <p>Компьютерный класс с компьютерами по количеству учеников в группе</p>
        </div>
    </div>,
    <div key="2" className="requirements-block">
        <div className="requirements-img">
            <img src={techIcon2} alt="" />
        </div>
        <div className="requirements-text">
            <div className="text-head">Современный браузер.</div>
            <p>Актуальная версия браузеров Chrome, Firefox или Edge Скорость интернета более 5 МБ\сек</p>
        </div>
    </div>
];

const renderFoundationSlider = (inSlider: boolean, slides: React.ReactNode[]): React.ReactNode => {
    if (inSlider) {
        return (
            <Slider {...sliderSettings} className="slider2">
                {slides}
            </Slider>
        );
    }

    return slides;
};

class KonkursCode extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true,
        showSlides: false
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }

        this.updateShowSlides();
        window.addEventListener("resize", this.updateShowSlides);

        // const script = document.createElement("script");
        // script.async = true;
        // script.id = "bx24_form_inline";
        // script.setAttribute("data-skip-moving", "true");
        // script.innerHTML = BITRIX_FORM_RU;
        // document.getElementById("formDiv")!.appendChild(script);
    }

    updateShowSlides = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.setState({
            showSlides: width < 1200
        });
    };

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateShowSlides);
    }

    render() {
        const { isLoading, project, showSlides } = this.state;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="kosmos_page">
                    <div className="konkursCode-slide">
                        <div className="konkursCode-slide-head">
                            <div className="section-inner clear">
                                <div className="background-block">
                                    <img src={peoples} alt="" />
                                </div>
                                <div className="text-block">
                                    <h1 className="h1">Мой первый код</h1>
                                    <p>
                                        <strong>Бесплатный проект</strong>
                                        <br /> для преподавателей 1-7 классов и их учеников по изучению программирования
                                        на Scratch
                                    </p>
                                    <div className="buttonWrapper">
                                        <a
                                            onClick={() => {
                                                // @ts-ignore
                                                window.gtag("event", "переход к форме_мпк", {
                                                    // eslint-disable-next-line @typescript-eslint/camelcase
                                                    event_category: "button",
                                                    // eslint-disable-next-line @typescript-eslint/camelcase
                                                    event_action: "form_mpk_click"
                                                });
                                                // @ts-ignore
                                                window.ym(56419471, "reachGoal", "form_mpk_click");
                                                console.log("Выполнен переход к форме_мпк");
                                            }}
                                            href="https://docs.google.com/forms/d/e/1FAIpQLSeiFfL9PiXxXFSELZXEwBgJpvtyufffGjbZefJAgJLLpAEPTA/viewform"
                                            className="button"
                                        >
                                            Участвовать в следующем отборе
                                        </a>
                                    </div>
                                    <div className="timerWrapper">
                                        Прием заявок завершен.
                                        {/* <span>До окончания приема заявок осталось:</span>
                                        Запустится 2 марта 00:00, остановится 20 апреля 23:59 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="konkursCode-about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    Благотворительный фонд «Возможности интеллекта» реализует проект совместно с{" "}
                                    <a href="https://algoritmika.org/" target="_blank" rel="noopener noreferrer">
                                        Лабораторией программирования «Алгоритмика»
                                    </a>
                                    . Мы помогаем школам и центрам дополнительного образования организовать интересное и
                                    качественное обучение основам программирования без инвестиций в методологию.
                                </div>
                            </div>
                        </div>
                        <div className="konkursCode-geo">
                            <div className="section-inner">
                                <div className="section-icon">
                                    <span>
                                        <img src={geo} alt="" />
                                    </span>
                                </div>
                                <h2 className="h2">География участников</h2>
                                <div className="subhead">Северо-Западный федеральный округ</div>
                                <div className="text-block">
                                    <p>
                                        (Архангельская область, Вологодская область, Калининградская область, республика
                                        Карелия, <br /> республика Коми, Ленинградская область, Мурманская область,
                                        Ненецкий автономный округ,
                                        <br /> Новгородская область, Псковская область, город Санкт-Петербург).
                                    </p>
                                </div>
                                {/*<div className="text-center">*/}
                                {/*    <Link*/}
                                {/*        activeClass="active"*/}
                                {/*        to="form"*/}
                                {/*        spy={true}*/}
                                {/*        smooth={true}*/}
                                {/*        offset={-120}*/}
                                {/*        duration={500}*/}
                                {/*        className="button"*/}
                                {/*    >*/}
                                {/*        Подать заявку*/}
                                {/*    </Link>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="konkursCode-offer">
                            <div className="section-inner">
                                <div className="konkursCode-offer-text">
                                    <p>
                                        По результатам конкурсного отбора участие в проекте «Мой первый код» в 2020/21
                                        учебном году принимает 81 преподаватель из 11 регионов Северо-Западного
                                        федерального округа.
                                    </p>
                                </div>
                                <div className="konkursCode-offer-img">
                                    <img src={algo} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="konkursCode-includes">
                            <div className="section-inner">
                                <div className="includes">
                                    <h2 className="h2">Курс включает в себя:</h2>
                                    <div className="slider_wrapper slider-includes">
                                        {renderFoundationSlider(showSlides, COSMOS_INCLUDES)}
                                    </div>
                                    {/*<div className="text-center">*/}
                                    {/*    <Link*/}
                                    {/*        activeClass="active"*/}
                                    {/*        to="form"*/}
                                    {/*        spy={true}*/}
                                    {/*        smooth={true}*/}
                                    {/*        offset={-120}*/}
                                    {/*        duration={500}*/}
                                    {/*        className="button"*/}
                                    {/*    >*/}
                                    {/*        Подать заявку*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="konkursCode__target target target-bordered">
                            <div className="section-inner">
                                <div className="target__icon">
                                    <span>
                                        <img src={goal} alt="" />
                                    </span>
                                </div>
                                <div className="target__text">
                                    <p>
                                        <strong>Цель конкурсного отбора</strong> – выявление учителей и педагогов
                                        дополнительного образования, способных эффективно использовать современные
                                        методики, передаваемые в рамках благотворительного проекта{" "}
                                        <a
                                            onClick={() => {
                                                // @ts-ignore
                                                window.gtag(
                                                    "event",
                                                    "переход на страницу проекта мпк со страницы конкурса",
                                                    {
                                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                                        event_category: "link",
                                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                                        event_action: "project_mpk_click"
                                                    }
                                                );
                                                // @ts-ignore
                                                window.ym(56419471, "reachGoal", "project_mpk_click");
                                                console.log(
                                                    "Выполнен переход со страницы конкурса на страницу проекта мпк"
                                                );
                                            }}
                                            href="https://iqcharity.ru/projects/myfirstcode"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            «Мой первый код»
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="konkursCode-target-audience">
                            <div className="section-inner">
                                <h2 className="h2">Кто может подать заявку?</h2>
                                <div className="slider_wrapper slider-target">
                                    {renderFoundationSlider(showSlides, TARGET_AUDIENCE)}
                                </div>
                                <div className="audience_bottom_text">
                                    Участвовать в конкурсном отборе могут педагоги государственных и муниципальных
                                    общеобразовательных организаций и организаций дополнительного образования.
                                    Посмотрите{" "}
                                    <a href="https://iqcharity.ru/projects/myfirstcode">
                                        текущую географию проекта и охват участников
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="first_code-course konkurs">
                            <div className="section-inner clear">
                                <div className="course-img">
                                    <img src={algoritmika} alt="" />
                                </div>
                                <div className="course-text">
                                    <h2 className="h2">Требования к педагогу:</h2>
                                    <ul>
                                        <li>
                                            Cамостоятельная подготовка к занятиям (занимает около 1-2 часов в неделю);
                                        </li>
                                        <li>
                                            Группа из учеников в возрасте 7-14 лет.{" "}
                                            <strong>Количество – до 15 человек</strong>, максимум 2 группы на учителя;
                                        </li>
                                        <li>
                                            Возможность провести <strong>32 занятия по 90 минут</strong> (64 ак. ч) в
                                            2020\2021 учебном году;
                                        </li>
                                        <li>
                                            регулярное заполнение онлайн-форм обратной связи в течение курса
                                            (самостоятельно и с учениками);
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="space_requirements konkurs">
                            <div className="section-inner">
                                <h2 className="h2">Технические требования</h2>
                                <div className="slider-wrapper">
                                    {renderFoundationSlider(showSlides, SPACE_REQUIREMENTS)}
                                </div>
                            </div>
                        </div>

                        <div className="konkurs-objective">
                            <div className="section-inner">
                                <h2 className="h2">Как принять участие?</h2>
                                <div className="objective-wrap">
                                    <div className="objective-block">
                                        <div className="objective-block-inner">
                                            <div className="objective_block_img">
                                                <img src={objective1} alt="" />
                                            </div>
                                            <div className="objective-block-text">
                                                <span className="objective-text-num">1</span>
                                                <div className="objective-text">
                                                    <strong>Заполнить заявку на участие</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="objective-block">
                                        <div className="objective-block-inner">
                                            <div className="objective_block_img">
                                                <img src={objective3} alt="" />
                                            </div>
                                            <div className="objective-block-text">
                                                <span className="objective-text-num">2</span>
                                                <div className="objective-text">
                                                    <strong>Выполнить </strong>
                                                    <Popup
                                                        trigger={
                                                            <button className="textButton">конкурсное задание</button>
                                                        }
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {close => (
                                                            <div className="modal modal-pdf">
                                                                <span className="close" onClick={close}>
                                                                    &times;
                                                                </span>
                                                                <Iframe
                                                                    url={competitionTask}
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            </div>
                                                        )}
                                                    </Popup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="objective-block">
                                        <div className="objective-block-inner">
                                            <div className="objective_block_img">
                                                <img src={objective4} alt="" />
                                            </div>
                                            <div className="objective-block-text">
                                                <span className="objective-text-num">3</span>
                                                <div className="objective-text">
                                                    <strong>Дождаться оценки </strong> Конкурсной комиссии{" "}
                                                    <Popup
                                                        trigger={
                                                            <button className="textButton">
                                                                Подробнее о критериях оценки
                                                            </button>
                                                        }
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {close => (
                                                            <div className="modal modal-pdf">
                                                                <span className="close" onClick={close}>
                                                                    &times;
                                                                </span>
                                                                <Iframe
                                                                    url={evaluationCriteria}
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            </div>
                                                        )}
                                                    </Popup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="section section-steps">
                            <div className="section-inner">
                                <h2 className="h2">Этапы проведения Конкурса</h2>
                                <div className="steps clear">
                                    <div className="step-blocks step-1">
                                        <div className="steps-date">2 марта</div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Старт приема заявок на участие</div>
                                    </div>
                                    <div className="step-blocks step-2">
                                        <div className="steps-date">
                                            20 апреля
                                            <span>до 23:59 (МСК)</span>
                                        </div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Окончание приема заявок и выполненных заданий</div>
                                    </div>
                                    <div className="step-blocks step-3">
                                        <div className="steps-date">20-26 апреля</div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Проверка конкурсных заданий</div>
                                    </div>
                                    <div className="step-blocks step-4">
                                        <div className="steps-date">27-30 апреля</div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Отбор участников</div>
                                    </div>
                                    <div className="step-blocks step-5">
                                        <div className="steps-date">30 апреля</div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Объявление результатов</div>
                                    </div>
                                    <div className="step-blocks step-6">
                                        <div className="steps-date">Май – Июль</div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">Обучение преподавателей работе с курсом</div>
                                    </div>
                                    <div className="step-blocks step-7">
                                        <div className="steps-date">
                                            <span>Не ранее</span> 01 сентября
                                        </div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">
                                            Начало занятий по программе «Миссия Коперник: колонизация планеты Марс»
                                        </div>
                                    </div>
                                    <div className="step-blocks step-8">
                                        <div className="steps-date">
                                            25 мая – 25 июня <span>2021 г.</span>
                                        </div>
                                        <div className="steps-dot" />
                                        <div className="steps-desc">
                                            Предоставление отчёта о целевом расходовании пожертвования по утверждённой
                                            форме.
                                        </div>
                                    </div>
                                </div>
                                <div className="text-block">
                                    <p>
                                        После заполнения формы заявки на сайте участник выполняет задания на платформе
                                        “Алгоритмика” и проект на платформе Scratch. Работы оцениваются в баллах на
                                        основании критериев, указанных в Положении о Конкурсе.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/*<div id="form">*/}
                        {/*    <div className="container">*/}
                        {/*        <div className="form_contact">*/}
                        {/*            <h2 className="h3">Заявка на участие</h2>*/}
                        {/*            <div id="formDiv" />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="section-documents">
                            <div className="section-inner">
                                <h2 className="h2">
                                    Юридические документы
                                    <span>(для скачивания)</span>
                                </h2>
                                <div className="documents">
                                    <a id="juristic_documents_link" href={polojenie} download>
                                        <div className="link_img">
                                            <img src={pdf} alt="" />
                                        </div>
                                        <div className="link_text">
                                            <span>Положение о конкурсе</span>
                                        </div>
                                    </a>
                                    <a id="juristic_documents_link" href={opisanie} download>
                                        <div className="link_img">
                                            <img src={pdf} alt="" />
                                        </div>
                                        <div className="link_text">
                                            <span>Тематический (учебный) план</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="konkursCode-FAQ">
                            <div className="section-inner">
                                <h2 className="h2">Часто задаваемые вопросы</h2>
                                <div className="text-center">
                                    <Popup
                                        trigger={<button className="button">Смотреть</button>}
                                        modal
                                        closeOnDocumentClick
                                        onOpen={() => {
                                            // @ts-ignore
                                            window.gtag("event", "faq_мпк", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "faq_click"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "faq_click");
                                            console.log("Выполнен переход к faq мпк");
                                        }}
                                    >
                                        {close => (
                                            <div className="modal modal-pdf">
                                                <span className="close" onClick={close}>
                                                    &times;
                                                </span>
                                                <Iframe url={faq} width="100%" height="100%" />
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                            </div>
                        </div>

                        <div className="konkursCode-examples">
                            <Tabs>
                                <div className="section-inner">
                                    <h2 className="h2">Примеры работ</h2>
                                    <TabList className="example-links">
                                        <Tab className="example-btn">Пример 1</Tab>
                                        <Tab className="example-btn">Пример 2</Tab>
                                    </TabList>
                                </div>
                                <div className="example-block">
                                    <div className="example-block-inner">
                                        <TabPanel>
                                            <a
                                                onClick={() => {
                                                    // @ts-ignore
                                                    window.gtag(
                                                        "event",
                                                        "переход по ссылке на пример работы ученика_мпк",
                                                        {
                                                            // eslint-disable-next-line @typescript-eslint/camelcase
                                                            event_category: "link",
                                                            // eslint-disable-next-line @typescript-eslint/camelcase
                                                            event_action: "student_project_mpk_click"
                                                        }
                                                    );
                                                    // @ts-ignore
                                                    window.ym(56419471, "reachGoal", "student_project_mpk_click");
                                                    console.log("Просмотр примера работы ученика мпк");
                                                }}
                                                href="https://scratch.mit.edu/projects/366689564/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={screen1} alt="" />
                                            </a>
                                        </TabPanel>
                                        <TabPanel>
                                            <a
                                                href="https://scratch.mit.edu/projects/300073946/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={screen2} alt="" />
                                            </a>
                                        </TabPanel>
                                    </div>
                                </div>
                            </Tabs>
                        </div>

                        <div className="first_code-reviews">
                            <div className="section-inner">
                                <h2 className="h2">Отзывы</h2>
                                <div className="slider-wrapper">
                                    <Slider {...sliderSettings} className="sliderReview">
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Благодаря участию в проекте «Мой первый код», во-первых, я могу
                                                    предоставить ребятам возможность начать программировать с раннего
                                                    возраста. Это очень здорово. Разумеется, у них еще нет информатики в
                                                    таком возрасте, но в наше время такие умения и навыки очень важны:
                                                    не просто «тыкать» в иконки планшета, а иметь представление об азах.
                                                    Во-вторых, проект дает возможность мне самой лишний раз подумать,
                                                    пофантазировать, поэкспериментировать. И, в-третьих, мне просто
                                                    нравится делать интересное и нужное дело.»
                                                </div>
                                                <div className="review__owner">
                                                    Иванова Наталья,{" "}
                                                    <span>преподаватель ГБОУ СОШ № 86, г. Санкт-Петербург</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Для меня участие в проекте «Мой первый код» – профессиональный
                                                    рост. Дети, занимающиеся на курсе, идут в хорошем отрыве от
                                                    остальных ребят потока. У них появляются новые интересы и повышается
                                                    скорость обучаемости. Многие наработки я использую на основных
                                                    уроках, и это даёт колоссальный эффект. Я очень благодарна
                                                    разработчикам. Отдельная благодарность за проведение вебинаров,
                                                    конкурсов и олимпиад.»
                                                </div>
                                                <div className="review__owner">
                                                    Пугачева Ирина,{" "}
                                                    <span>преподаватель МАОУ Гимназия №32, Калининградская обл.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Участие в проектах даёт мне возможность передавать детям знания,
                                                    используя современное программное обеспечение с полной базой,
                                                    абсолютно бесплатно. Не у всех есть возможность оплачивать несколько
                                                    кружков, а дети хотят разносторонне развиваться. Ваша поддержка не
                                                    даёт опустить руки, когда что-то не получается. Спасибо!»
                                                </div>
                                                <div className="review__owner">
                                                    Виктория Карпова,{" "}
                                                    <span>
                                                        преподаватель МАУ ДО ЦТРиГО «Информационные Технологии»,
                                                        Калининградская область
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    «Лично для меня это новый интересный опыт, дополнительное общение с
                                                    детьми в неформальной обстановке, что, в свою очередь, дает мне
                                                    возможность лучше узнать детей и их потребности. Выполнение заданий
                                                    интересно не только детям, но и мне лично. Развиваться и учиться
                                                    интересному, новому, прогрессивному – в любом возрасте удовольствие
                                                    для педагога.»
                                                </div>
                                                <div className="review__owner">
                                                    Марина Блажнова, <span>ГБОУ СОШ №100, Санкт-Петербург</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                    <div className="sliderRobo">
                                        <img src={robo2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default KonkursCode;

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Layout from "../../../components/Layout";
import Project from "../../../models/Project";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Loader from "../../../components/Loader/Loader";
import {
    FacebookShareButton,
    FacebookShareCount,
    FacebookIcon,
    VKShareButton,
    VKShareCount,
    VKIcon,
    OKShareButton,
    OKShareCount,
    OKIcon
} from "react-share";
import pythonImg from "./assets/img/new-people.png";
import robo from "./assets/img/python_stepik_character.png";
import aim from "./assets/img/python_stepik_character3.png";
import shareImage from "./assets/img/share.jpg";
import "./assets/style.scss";

interface State {
    isLoading: boolean;
    project: Project | null;
}

interface Props {
    slug: string;
}

class PythonGen extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }
    }
    render() {
        const { isLoading, project } = this.state;
        const shareUrl = "https://iqcharity.ru/projects/pythononline";
        const title = "IQcharity";

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="pythonGenerationPage" id="pythonGenerationPage">
                    <div className="main__slide pythonGeneration">
                        <div className="section-inner clear">
                            <div className="main__slide__text-block">
                                <h1 className="h1">
                                    Поколение Python:
                                    <br /> курс для начинающих на Stepik.org
                                </h1>
                                <p>
                                    <strong>Бесплатный онлайн-курс для всех,</strong>
                                    <br /> кто хочет научиться программировать
                                    <br /> на Python
                                </p>
                                <div className="buttonWrapper">
                                    <a
                                        onClick={() => {
                                            // @ts-ignore
                                            window.gtag("event", "переход на stepik", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "stepik_click"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "stepik_click");
                                            console.log("Выполнен переход на курс на stepik.org");
                                        }}
                                        href="https://stepik.org/course/58852/promo"
                                        className="button"
                                    >
                                        Пройти курс
                                    </a>
                                </div>
                            </div>
                            <div className="main__slide__background-block">
                                <img src={pythonImg} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="pythonGen__about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    <a href="/" target="_blank" rel="noopener noreferrer">
                                        Благотворительный фонд «Возможности интеллекта»
                                    </a>{" "}
                                    (ранее – БФ развития образования «Айкью Опшн») совместно со школой
                                    <a href="https://stepik.org/org/beegeek" target="_blank" rel="noopener noreferrer">
                                        BEEGEEK
                                    </a>{" "}
                                    предлагают пройти онлайн-курс по изучению основ Python на платформе{" "}
                                    <a href="https://stepik.org/" target="_blank" rel="noopener noreferrer">
                                        Stepik
                                    </a>
                                    . Курс бесплатный, является частью проекта{" "}
                                    <a
                                        href="https://iqcharity.ru/projects/python"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        «Поколение Python»
                                    </a>{" "}
                                    для школьников и учителей, но доступен всем желающим, независимо от возраста и
                                    местоположения.
                                </div>
                            </div>
                        </div>
                        <div className="pythonGen__include course__includes">
                            <div className="section-inner">
                                <div className="course__includes__wrapper">
                                    <h2 className="h2">Курс включает:</h2>
                                    <div className="course__includes_in-number project_in-number">
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>61</span>
                                                урок
                                            </div>
                                        </div>
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>294</span>
                                                задачи с автопроверкой
                                            </div>
                                        </div>
                                        <div className="project_in-number__column">
                                            <div className="stats">
                                                <span>214</span>
                                                тестов
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kosmos-target python">
                            <div className="section-inner">
                                <div className="course__includes__bottom">
                                    <a
                                        onClick={() => {
                                            // @ts-ignore
                                            window.gtag("event", "переход к программе курса_stepik", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "program_click"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "program_click");
                                            console.log("Пользователь перешел к программе курса stepik");
                                        }}
                                        href="https://stepik.org/course/58852/promo"
                                        className="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        ПРОГРАММА КУРСА
                                    </a>
                                    <div className="course__includes__bottom__img">
                                        <img src={robo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pythonGen__course project__course">
                            <div className="section-inner clear">
                                <div className="project__course__course-text">
                                    <h2 className="h2">Учебный курс отличается</h2>
                                    <ul>
                                        <li>
                                            <strong>Хорошим балансом теории</strong> и практики, практики, практики.
                                        </li>
                                        <li>
                                            <strong>Системным микро-обучением:</strong> теория подается маленькими
                                            порциями, не более 15 минут на обработку.
                                        </li>
                                        <li>
                                            <strong>Форматом: никаких видеолекций</strong> – только текст. Легко
                                            перечитывать и возвращаться, чтобы вспомнить детали.
                                        </li>
                                        <li>
                                            <strong>Дружелюбным сообществом в комментариях.</strong> Авторы курса
                                            отвечают на вопросы учеников вежливо и регулярно.
                                        </li>
                                        <li>
                                            <strong>Отличным лаконичным дизайном.</strong>
                                        </li>
                                    </ul>
                                </div>
                                <div className="project__course__img" />
                            </div>
                        </div>
                        <div className="pythonGen__target target target-bordered">
                            <div className="section-inner">
                                <div className="target__icon">
                                    <span>
                                        <img src={aim} alt="" />
                                    </span>
                                </div>
                                <div className="target__text">
                                    <p>
                                        <strong>Платформа Stepik позволяет</strong> пройти весь курс с мобильного
                                        устройства, но мы рекомендуем проходить курс на компьютере в интегрированной
                                        среде разработки (IDE), такой как WIng 101, PyCharm и др.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="kosmos_audience_bottom_text pythonGen__bottom_text">
                            Для освоения курса требуются базовые знания из школьной программы по информатике и
                            математике. По окончании курса слушатели могут получить Сертификат Stepik. Документ
                            позволяет участвовать в{" "}
                            <a href="https://talent2035.kruzhok.org/python" target="_blank" rel="noopener noreferrer">
                                конкурсе «Талант 20.35»
                            </a>
                        </div>
                        <div className="pythonGen__share share_buttons_block">
                            <div className="section-inner">
                                <h2 className="h2">Рассказать о курсе друзьям</h2>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <FacebookShareButton
                                        onClick={() => {
                                            // @ts-ignore
                                            window.gtag("event", "переход_share python_fb", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "social_media_click_fb"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "social_media_click_fb");
                                            console.log("Клик по кнопке Facebook");
                                        }}
                                        url={shareUrl}
                                        quote={title}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
                                            {count => count}
                                        </FacebookShareCount>
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <VKShareButton
                                        onClick={() => {
                                            // @ts-ignore
                                            window.gtag("event", "переход_share python_vk", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "social_media_click_vk"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "social_media_click_vk");
                                            console.log("Клик по кнопке ВКонтакте");
                                        }}
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKIcon size={32} round />
                                    </VKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <VKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                                <div className="Demo__some-network">
                                    {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                    <OKShareButton
                                        onClick={() => {
                                            // @ts-ignore
                                            window.gtag("event", "переход_share python_ok", {
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_category: "button",
                                                // eslint-disable-next-line @typescript-eslint/camelcase
                                                event_action: "social_media_click_ok"
                                            });
                                            // @ts-ignore
                                            window.ym(56419471, "reachGoal", "social_media_click_ok");
                                            console.log("Клик по кнопке Одноклассники");
                                        }}
                                        url={shareUrl}
                                        image={`${String(window.location)}/${shareImage}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKIcon size={32} round />
                                    </OKShareButton>

                                    <div>
                                        {/*
                                    // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
                                        <OKShareCount url={shareUrl} className="Demo__some-network__share-count" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default PythonGen;

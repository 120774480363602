import styled from "styled-components";
import defaultBg from "./assets/techart.jpg";
import internationalDefaultBg from "./assets/african_heart.jpg";

export const MainScreenWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fff url(${defaultBg}) center center no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
    color: #000;
    &.mainScreenEn {
        background: #fff url(${internationalDefaultBg}) center center no-repeat;
        background-size: cover;
    }
    .intro {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        .mobileBg {
            display: none;
        }
        .main_text {
            width: 33%;
            max-width: 450px;
            z-index: 100;
            text-align: left;
            color: #000;
            margin-left: 155px;
            .h1 {
                font-size: 36px;
                line-height: 43px;
                color: #000;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 600;
                padding-top: 13px;
                padding-bottom: 22px;
                span {
                    display: block;
                }
            }
            .subhead {
                font-size: 20px;
                line-height: 30px;
                color: #000;
                width: 100%;
                font-weight: 400;
                margin-left: 0 !important;
                margin-right: 0 !important;
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 33px;
            }
        }
    }
    #formDiv {
        iframe {
            display: block;
        }
    }
    .formBtn {
        font-size: 18px;
        line-height: 20px;
        height: 40px;
        display: inline-block;
        box-sizing: border-box;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        color: rgb(255, 255, 255);
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 100px;
        right: 40px;
        padding: 10px 20px;
        background: rgb(255, 175, 4);
        transition: opacity 0.5s ease-out 0s;
        border: none;
        &:after {
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            content: "";
            background: transparent;
            transition: background-color 0.5s ease-out 0s;
        }
        &:hover {
            &:after {
                background: rgba(0, 0, 0, 0.1);
            }
        }
        @media (max-width: 767px) {
            top: 20px;
            right: 20px;
        }
    }
    @media (max-width: 1024px) {
        .intro {
            .main_text {
                width: 50%;
                padding-left: 40px;
                margin: 0;
                box-sizing: border-box;
            }
        }
    }
    @media (max-width: 767px) {
        height: auto;
        padding-top: 60px;
        .intro {
            height: auto;
            padding-top: 70px;
            padding-bottom: 70px;
            .main_text {
                width: 50%;
                .h1 {
                    font-size: 25px;
                    line-height: 1.2;
                }
                .subhead {
                    font-size: 17px;
                    line-height: 1.2;
                }
            }
        }
    }
    @media (max-width: 500px) {
        background: none;
        .intro {
            padding: 0;
            flex-direction: column;
            .mobileBg {
                display: block;
                width: 100%;
                height: 100vw;
                background: #fff url(${defaultBg}) center center no-repeat;
                background-size: cover;
            }
            .main_text {
                width: 100%;
                padding: 0 20px;
                display: block;
                .h1 {
                    color: #000 !important;
                }
                .subhead {
                    color: #000 !important;
                }
            }
        }
    }
`;

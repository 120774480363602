export interface FooterInfoLink {
    to: string;
    label: string;
}

export const footerInfoLinks: FooterInfoLink[] = [
    {
        to: "/privacy-policy",
        label: "footerInfo.privacy"
    },
    {
        to: "/terms&conditions",
        label: "footerInfo.terms"
    }
];

export default {
    mainMenu: {
        main: "Main Page",
        fond: "Foundation",
        partners: "Partners",
        projects: "Projects",
        reports: "Reports",
        contacts: "Contacts",
        help: "How to help"
    },
    footerInfo: {
        privacy: "Privacy Policy",
        terms: "Terms & Conditions"
    },
    footer: {
        menu: "Menu",
        address:
            "Foundation registration number: ΛΕΜ/Ι/2\n" +
            "Address: Agiou Athanasiou 33,\n" +
            "YIANNIS NICOLAIDES BUSINESS CT,\n" +
            "Agios Athanasios, 4102,\n" +
            "Limassol, Cyprus",
        officialDocuments: "Info",
        offerForDonation: "Публичная оферта для заключения договора пожертвования",
        offerPublic: "Публичная оферта",
        privacyPolicy: "Политика защиты и обработки персональных данных БФ",
        copy: "2017-2018 IQ Option Charity Foundation. All Rights Reserved."
    },
    mainScreen: {
        title: "IQ Option Charitable Foundation",
        subtitle: ""
    },
    foundation: {
        about: "Благотворительный фонд «Возможности интеллекта» был создан в Санкт-Петербурге в январе 2017 года.",
        mission:
            "Миссия - создать условия для доступного образования и оказать содействие в получении\n" +
            "знаний в сфере современных информационных технологий.",
        goalsAndTasks: "Цели и задачи",
        outTeam: "Наша команда",
        goals: {
            goal1: "Содействие формированию системы дополнительного образования в сфере информационных\nтехнологий",
            goal2: "Популяризация современных информационных технологий в профессиональной ориентации учащихся",
            goal3:
                "Техническое оснащение образовательных организаций, осуществляющих программы дополнительного\n" +
                "образования в сфере IT",
            goal4:
                "Поддержка и распространение успешного опыта внедрения современных методов обучения среди\n" +
                "учащихся в рамках дополнительного образования",
            goal5:
                "Формирование сообщества активных учителей, педагогов дополнительного образования,\n" +
                "руководителей образовательных организаций, учеников",
            goal6:
                "Распространение и развитие дополнительного образования в сфере информационных технологий с\n" +
                "помощью современных методов и средств",
            goal7: "Профориентация подростков в IT-сфере, знакомство с языками программирования и профессиями в\nIT"
        },
        team: {
            member1: {
                name: "Роман Белых",
                position: "Методист-координатор",
                scope:
                    'Роман занимается развитием образовательного проекта "Мой первый код". Он не только учит\n' +
                    "детей, но еще и помогает другим учителям учить :)"
            },
            member2: {
                name: "Ольга Быкова",
                position: "Менеджер по документообороту",
                scope:
                    "Ольга занимается такими серьезными и важными вещами, как бюджет и документооборот Фонда. А\n" +
                    "ваше образовательное учреждение уже отправило нам наш экземпляр договора?)"
            },
            member3: {
                name: "Анна Ждановская",
                position: "Менеджер образовательных мероприятий",
                scope:
                    "Анна разрабатывает мероприятия для подростков, которые позволяют ребятам получить\n" +
                    "представление о современных профессиях в сфере IT и овладеть нужными практическими навыками"
            },
            member4: {
                name: "Ксения Севастьянова",
                position: "Методист-координатор",
                scope:
                    'Ксения поддерживает работу проекта "Мой первый код", а также занимается координацией\n' +
                    "волонтеров фонда."
            },
            member5: {
                name: "Ольга Галак",
                position: "PR-менеджер",
                scope:
                    "Ольга работает над тем, чтобы как можно больше людей знали о нашем Фонде и принимали участие\n" +
                    "в наших проектах."
            }
        }
    },
    partners: {
        title: "PARTNERSHIP",
        forPartners: "Partners",
        forVolunteers: "Volunteers",
        partnersText:
            "Meet our Social Partners. Non-governmental organizations with the common objective of providing all possible help to the most needy children. Local organizations that work in the field and know firsthand the needs, problems and solutions of the place.From our foundation, we help as much as possible (economic donations, management, stuff, technology and knowledge) for the purpose of development, both of our partners and of the region in which they work.",
        volunteersText:
            "Volunteer with IQ Charitable Foundation to help us reach the children and young people in greatest need. Join us to help change the world for children.",
        becomeVolunteerText: "Become a volunteer",
        becomeVolunteerFillTheForm: "Fill out the form to become a volunteer Foundation",
        projectPartners: "Project partners",
        informationalPartners: "Info partners",
        buttonText: "Learn More",
        becomeAPartnerHead: "Become a partner",
        becomeAVolunteerHead: "Become a volunteer",
        partnersPage: {
            title: "PARTNERSHIP",
            website: "Official website",
            moreNews: "More news"
        }
    },
    reports: {
        title: "Reports",
        gallery: "Gallery",
        video: "Video"
    },
    projects: {
        title: "IQ OPTION CHARITY PROGRAMS",
        description:
            "IQ Option Charitable Foundation is seeking to broaden and strengthen international partnership in problems solving of Africa region. We are glad to cooperate with African NGOs and foundations. We are honoured to present our partners from different African countries."
    },
    contacts: {
        title: "Контакты",
        vkDesk: "Вступайте в группу Фонда ВКонтакте, чтобы оставаться в курсе всех новостей",
        enterGroup: "Вступить в группу",
        documentQuestions: "По вопросам оформления документов",
        documentManagerName: "Ольга Быкова",
        documentManagerPosition: "Менеджер по документообороту",
        documentManagerEmail: "olga.bykova@iq-charity.com",
        marketingQuestions: "По вопросам размещения информации в СМИ и инфопартнерства",
        marketingManagerName: "Ольга Галак",
        marketingManagerPosition: "PR-менеджер",
        marketingManagerEmail: "olga.galak@iq-charity.com",
        address:
            "195112, г. Санкт-Петербург, \n Малоохтинский проспект, дом 64, литера В, помещение 23Н, комната 49.\n",
        newAddress:
            "195112, г. Санкт-Петербург, \n Малоохтинский проспект, дом 64, литера В, помещение 23Н, комната 49.\n"
    },
    page404: {
        title: "Page not found",
        text:
            "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        buttonText: "Main Page"
    },
    blocks: {
        buttonText: {
            "More info": "More info",
            "Read the article": "Read the article",
            "About the project": "About the project"
        },
        subscribeBtnText: "Subscribe to news"
    }
};

import React from "react";
import { LoaderElem } from "./Atoms";

export default function Loader(): React.ReactElement {
    return (
        <LoaderElem>
            <span />
        </LoaderElem>
    );
}

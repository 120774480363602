import styled from "styled-components";

export const TermsBlock = styled.div`
    color: #5f5f5f;
    padding: 180px 0 100px;
    .container {
        width: 100%;
        max-width: 1031px;
        margin: 0 auto;
        padding: 20px 20px;
        box-sizing: border-box;
        position: relative;
    }
    .h1 {
        font-size: 36px;
        line-height: 43px;
        color: #2b2b2b;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 32px;
        text-align: center;
    }
    .terms_update-date {
        font-size: 14px;
        line-height: 20px;
        color: #9a9a9a;
        margin-top: -26px;
        margin-bottom: 37px;
        span {
            color: #2a2a2a;
        }
    }
    .terms_head {
        line-height: 24px;
        font-weight: 600;
        color: #494949;
        margin-bottom: 5px;
    }
    .terms_text {
        margin-bottom: 67px;
        p {
            font-size: 16px;
            line-height: 24px;
            color: #494949;
            margin-bottom: 25px;
        }
        a {
            color: #009fde;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            &:hover {
                filter: brightness(50%);
            }
        }
    }
    .terms_how-to-use {
        font-size: 16px;
        line-height: 24px;
        color: #494949;
        font-weight: 600;
    }
    .accordion {
        margin-top: 50px;
        border: none;
        .accordion__item {
            border-bottom: 1px solid #e8e8e8;
            .accordion__button {
                display: block;
                background: #fff;
                color: #ff9c00;
                padding: 17px 0;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                margin: 0;
                cursor: pointer;
                transition: all 0.5s ease;
                min-height: 58px;
                height: auto;
                box-sizing: border-box;
                &:before {
                    display: none;
                }
                &:after {
                    position: relative;
                    display: inline-block;
                    content: "";
                    height: 6px;
                    width: 6px;
                    margin-left: 6px;
                    top: -2px;
                    border-bottom: 2px solid currentColor;
                    border-right: 2px solid currentColor;
                    transform: rotate(45deg);
                }
                &[aria-expanded="true"]:after,
                &[aria-selected="true"]:after {
                    top: 0;
                    transform: rotate(225deg);
                }
            }
            .accordion__panel {
                padding: 2px 0 20px 67px;
                text-align: left;
                font-size: 16px;
                line-height: 24px;
                color: #494949;
                font-weight: 400;
                p {
                    margin-bottom: 10px;
                    margin-left: -27px;
                    font-size: 16px;
                    line-height: 24px;
                    color: #494949;
                    font-weight: 400;
                }
                li {
                    position: relative;
                    padding-left: 35px;
                    font-size: 16px;
                    line-height: 24px;
                    color: #494949;
                    font-weight: 400;
                    span {
                        font-weight: 600;
                        position: absolute;
                        display: inline-block;
                        left: 0;
                        top: 0;
                    }
                    & + li {
                        margin-top: 10px;
                    }
                }
                ul.list {
                    list-style: circle;
                    padding-top: 5px;
                    li {
                        list-style-type: disc;
                        list-style-position: outside;
                        padding-left: 0;
                        margin-left: 19px;
                    }
                }
                a {
                    color: #009fde;
                    transition: all 0.3s ease-in-out;
                    text-decoration: none;
                    &:hover {
                        filter: brightness(50%);
                    }
                }
                @media (max-width: 767px) {
                    padding: 2px 0 20px 30px;
                }
            }
        }
    }
`;

import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import "./assets/style.css";
import Project from "../../../models/Project";
import { getProjects } from "../../../api";
import { asset } from "../../../utils";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import Country from "../../../models/Country";
import { withTranslation, WithTranslation } from "react-i18next";
import { constants } from "../../../config";

interface ProjectsByCountry {
    [countryId: number]: Project[];
}

interface State {
    isLoading: boolean;
    projects: Project[];
    projectsByCountry: ProjectsByCountry;
    countries: Country[];
}

const projectsByCountries = (projects: Project[]): [Country[], ProjectsByCountry] => {
    const countries: Country[] = [];
    const projectsByCountry: ProjectsByCountry = {};

    projects.forEach(project => {
        const country = project.country;
        const countryId = country.id;

        const index = countries.findIndex(existedCountry => {
            return existedCountry.id === countryId;
        });

        if (index === -1) {
            countries.push(country);
        }

        if (!projectsByCountry[countryId]) {
            projectsByCountry[countryId] = [];
        }

        projectsByCountry[countryId].push(project);
    });

    return [countries, projectsByCountry];
};

class ProjectsMain extends Component<RouteComponentProps & WithTranslation, State> {
    state: State = {
        isLoading: true,
        projects: [],
        projectsByCountry: {},
        countries: []
    };

    async componentDidMount(): Promise<void> {
        const projects = await getProjects();
        const [countries, projectsByCountry] = projectsByCountries(projects);

        this.setState({ isLoading: false, projects, countries, projectsByCountry });
    }

    render() {
        const { isLoading, projectsByCountry, countries } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="projects">
                    <div className="partner_info clear">
                        <h1 className="h1">{t("projects.title")}</h1>
                        <p>{t("projects.description")}</p>
                    </div>
                    {countries.map(country => {
                        return (
                            <React.Fragment key={country.id}>
                                {constants.LOCALE === "en" && <h2 className="h2">{country.name}</h2>}
                                <div className="all_projects" key={country.id}>
                                    {projectsByCountry[country.id].map(project => {
                                        return (
                                            <Link
                                                key={project.id}
                                                className="project"
                                                to={`${this.props.match.url}/${project.slug}`}
                                            >
                                                <div className="project_img">
                                                    {project.previewPath && (
                                                        <img src={asset(project.previewPath)} alt="" />
                                                    )}
                                                </div>
                                                <div className="project_name">{project.title}</div>
                                                <div className="project_text">{project.subtitle}</div>
                                            </Link>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(ProjectsMain);

import React from "react";
import { TermsBlock } from "./Atoms";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from "../../components/ScrollToTop";
const TermsConditions: React.FC = () => {
    return (
        <Layout>
            <TermsBlock>
                <ScrollToTop />
                <div className="container">
                    <h1 className="h1">
                        PLEASE READ THESE TERMS AND
                        <br /> CONDITIONS CAREFULLY BEFORE USING
                        <br /> THIS SITE
                    </h1>
                    <div className="terms_update-date">
                        Last update <span>14.09.2018</span>
                    </div>
                    <div className="terms_head">What's in these terms?</div>
                    <div className="terms_text">
                        <p>
                            These Terms and Conditions are an agreement between the IQOPTION Charitable Foundation and
                            you and govern your use of our website{" "}
                            <Link target="_blank" to="/">
                                http://iqcharity.org
                            </Link>{" "}
                            and all information on or submitted through it.{" "}
                        </p>
                        <p>
                            Please read these Terms and Conditions carefully as they contain important information
                            regarding your legal rights, remedies and obligations with respect to your use of the Site,
                            including but not limited to various limitations and exclusions, and indemnities.{" "}
                        </p>
                    </div>
                    <div className="terms_how-to-use">
                        Click on the links below to go straight to more information on each area:
                    </div>
                    <Accordion allowZeroExpanded={true} preExpanded={[0]}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>1. Who we are and how to contact us </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>1.1</span>IQOPTION CHARITABLE FOUNDATION (
                                        <Link target="_blank" to="/">
                                            http://iqcharity.org
                                        </Link>
                                        ), is a Site operated by the IQOPTION Charitable Foundation, a charitable
                                        foundation existing under the laws of the Republic of Cyprus with foundation
                                        number ΛΕΜ/Ι/2 and governed by the laws of the Republic of Cyprus and the
                                        provisions of the founding act which was submitted to the Registrar of Societies
                                        and Institutions on 18/4/2018 (“Charity”, “Foundation”, “we”, “us” etc).{" "}
                                    </li>
                                    <li>
                                        <span>1.2</span>Our legal notice address is Agiou Athanasiou 33, YIANNIS
                                        NICOLAIDES BUSINESS CT, Agios Athanasios, 4102, Limassol, Cyprus. Our TIC number
                                        is 90007253F.
                                    </li>
                                    <li>
                                        <span>1.3</span>To contact us, please email{" "}
                                        <a href="mailto:info@iqcharity.org">info@iqcharity.org</a> telephone on 00357 -
                                        25 030 677
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    2. By using the Site you accept these Terms and Conditions
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>2.1</span>You understand that your use of the site, registration on the
                                        site or use of this or any other Foundation website, mobile app, online portal,
                                        electronic forms, surveys and interactive exhibits managed by the{" "}
                                        <Link target="_blank" to="/">
                                            http://iqcharity.org
                                        </Link>{" "}
                                        (collectively the “Site”), constitutes your agreement to these Terms and
                                        Conditions and that you agree to comply with them.
                                    </li>
                                    <li>
                                        <span>2.2</span>Do not use the Site if you do not agree to these Terms and
                                        Conditions or if your jurisdiction provides for contrary legal provisions.
                                    </li>
                                    <li>
                                        <span>2.3</span>Your use of the Site, and the Content and features accessed
                                        through them, constitutes your agreement to these Terms and Conditions; such
                                        agreement will be deemed for all legal purposes to be in writing and legally
                                        enforceable as a signed written agreement.{" "}
                                    </li>
                                    <li>
                                        <span>2.4</span>We recommend that you print a copy of these Terms and Conditions
                                        for future reference.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    3. Other terms and/or policies that may be applicable to you
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>3.1</span>These Terms and Conditions refer to the following additional
                                        terms, which also apply to your use of our site:
                                        <ul className="list">
                                            <li>
                                                <Link to="./privacy-policy/">Our Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Our Cookie Policy</Link>, which sets out information about
                                                the cookies on our site.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    4. We may make changes to these Terms and Conditions
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>4.1</span>We may update and change the Site as well as amend these Terms
                                        and Conditions from time to time without prior notice to you. Every time you
                                        wish to use the Site, please check these Terms and Conditions to ensure you
                                        understand the Terms and Conditions that apply at that time. We will do our best
                                        to inform you in case of any update of this Terms & Conditions as soon as
                                        practically possible by posting a notification on the site.
                                    </li>
                                    <li>
                                        <span>4.2</span>You understand that it is your sole responsibility to remain
                                        up-to-date with all changes. The applicable version shall be the latest version
                                        uploaded on the Site and in the event of a dispute the latest version shall
                                        prevail.
                                    </li>
                                    <li>
                                        <span>4.3</span>In case that you do not agree with the amendments, you must not
                                        use the Site and shall cease all use of the Content or of the services. If you
                                        continue to use the Site after the effective date of any amendment, you will be
                                        deemed to have accepted such amended version of the Terms and Conditions.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>5. We may make changes to our Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>5.1</span>We may update and change our Site from time to time. We will try
                                        to give you reasonable notice of any major changes.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>6. We may suspend or withdraw our Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>6.1</span>The Site is made available free of charge on an “as is”.
                                    </li>
                                    <li>
                                        <span>6.2</span>We do not guarantee that the Site, or any content on it, will
                                        always be available or be uninterrupted, safe and errors-free and immunity free
                                        from disruptions caused from damages, malfunctions or failures in hardware,
                                        software, communications and systems in your computers and in the Foundation’s
                                        suppliers.{" "}
                                    </li>
                                    <li>
                                        <span>6.3</span>We may suspend or withdraw or restrict the availability of all
                                        or any part of the Site for business and operational reasons at any time without
                                        notice.{" "}
                                    </li>
                                    <li>
                                        <span>6.4</span>You are also responsible for ensuring that all persons who
                                        access the Site through your internet connection are aware of these Terms and
                                        Conditions and other applicable terms and conditions, and that they comply with
                                        them.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>7. You must keep your account details safe</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>7.1</span>If you choose, or you are provided with, a user identification
                                        code, password or any other piece of information as part of our security
                                        procedures, you must treat such information as confidential. You must not
                                        disclose it to any third party.
                                    </li>
                                    <li>
                                        <span>7.2</span>We have the right to disable any user identification code or
                                        password, whether chosen by you or allocated by us, at any time, if in our
                                        reasonable opinion you have failed to comply with any of the provisions of these
                                        Terms and Conditions.
                                    </li>
                                    <li>
                                        <span>7.3</span>If you know or suspect that anyone other than you knows your
                                        user identification code or password, you must promptly notify us at{" "}
                                        <a href="mailto:info@iqcharity.org">info@iqcharity.org</a>.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>8. How you may use material on the Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>8.1</span>Except where expressly stated otherwise, all right, title, and
                                        interest in and to the Site and all material and content, including designs,
                                        editorial, text, graphics, audio-visual materials, multimedia elements,
                                        photographs, videos, music, sound recordings, reports, documents, software,
                                        information, formulae, patterns, data and any other work (collectively the
                                        “Content”), source code, processes, designs, technologies, URLs, domain names,
                                        marks and logos forming any part of the Site are (a) fully vested in us, our
                                        licensors, or our suppliers and (b) protected by applicable copyrights,
                                        trademarks, patents, trade secrets, or other proprietary rights and laws. All
                                        such rights are reserved. Much of the Content on the Site is not available for
                                        downloading, such as our copyrighted works that we do not distribute or works of
                                        others that we are not permitted to distribute. However, we also may have
                                        Content that if so designated may be downloaded by you pursuant to these Terms
                                        and Conditions ("Available Content"). YOU MAY REVIEW, DOWNLOAD, COPY, DISTRIBUTE
                                        AND USE THE AVAILABLE CONTENT SOLELY FOR THE PURPOSE OF FURTHERING YOUR MISSION
                                        IN THE ORDINARY COURSE OF YOUR GOVERNMENTAL OR CHARITABLE PURPOSE AND ATTENDANT
                                        OPERATIONS. YOU MAY NOT SELL THE AVAILABLE CONTENT OR OTHERWISE DISTRIBUTE IT
                                        FOR A FEE. YOU WILL NOT USE OR DISCLOSE IT OR THE SITE TO ANY THIRD PARTIES
                                        EXCEPT AS EXPRESSLY PERMITTED BY THESE TERMS OF USE.
                                    </li>
                                    <li>
                                        <span>8.2</span>You must not modify the paper or digital copies of any materials
                                        you have printed off or downloaded in any way, and you must not use any
                                        illustrations, photographs, video or audio sequences or any graphics separately
                                        from any accompanying text.
                                    </li>
                                    <li>
                                        <span>8.3</span>Our status (and that of any identified contributors) as the
                                        authors of the Content on the Site must always be acknowledged.
                                    </li>
                                    <li>
                                        <span>8.4</span>You must not use any part of the Content on our Site for
                                        commercial purposes without obtaining a licence to do so from us or our
                                        licensors.
                                    </li>
                                    <li>
                                        <span>8.5</span>If you print off, copy or download any part of our Site in
                                        breach of these Terms and Conditions, your right to use our Site will cease
                                        immediately and you must, at our option, return or destroy any copies of the
                                        materials you have made.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>9. Do not rely on information on this Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>9.1</span>The Content on our Site is provided for general information
                                        only. It is not intended to amount to advice on which you should rely. You must
                                        obtain professional or specialist advice before taking, or refraining from, any
                                        action on the basis of the Content on the Site.
                                    </li>
                                    <li>
                                        <span>9.2</span>Although we make reasonable efforts to update the information on
                                        the Site, we make no representations, warranties or guarantees, whether express
                                        or implied, that the Content on the Site is accurate, complete or up to date.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    10. We are not responsible for websites we proved links to
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>10.1</span>Where the Site contains links to other sites and resources
                                        provided by third parties, these links are provided for your information only.
                                        Such links should not be interpreted as approval by us of those linked websites
                                        or information you may obtain from them. We have no control over the contents of
                                        those sites or resources.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    11. User-generated content is not approved by us
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>11.1</span>This Site may include information and materials uploaded by
                                        other users of the Site, including to bulletin boards and chat rooms. This
                                        information and these materials have not been verified or approved by us. The
                                        views expressed by other users on our Site do not represent our views or values.
                                    </li>
                                    <li>
                                        <span>11.2</span>If you wish to complain about information and materials
                                        uploaded by other users please contact us on <br />
                                        Email address: <a href="mailto:info@iqcharity.org">info@iqcharity.org</a> <br />
                                        Postal address: 33 Agiou Athanasiou, YIANNIS NICOLAIDES BUSINESS CT, Agios
                                        Athanasios, 4102, Limassol, Cyprus
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>12. Uploading content to our Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>12.1</span>Whenever you make use of a feature that allows you to upload
                                        Content to the Site, or to make contact with other users of the Site, you:
                                        <ul className="list">
                                            <li>
                                                represent and warrant that any such contribution is (a) wholly original
                                                work, or (b) that you have all necessary right, title, interest and
                                                licenses to upload it and make it available to the Foundation and other
                                                users for download, distribution and use under these Terms and
                                                Conditions without (i) violation (by you, us, third parties, users or
                                                anyone else) of any applicable licence, restriction or law, or (ii) a
                                                potentially adverse consequence to us, third parties, users or anyone
                                                else that you have not conspicuously disclosed in the content. For the
                                                purposes of these Terms & Conditions, “adverse consequence”, means a
                                                restriction governing the content which was uploaded which could be
                                                triggered if we or others exercise a right under the licence you grant
                                                below and which imposes an obligation, liability or impairment of rights
                                                on us or others that was not conspicuously disclosed by you before
                                                exercise of a right. To disclose an adverse action, you agree to
                                                conspicuously place on or in the content which you uploaded, notice,
                                                that adverse actions can result and that you may be contacted at the
                                                email address and telephone number in the notice to obtain your full
                                                disclosure of applicable licences and restrictions applicable to the
                                                content which you uploaded.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>12.2</span>We do not claim ownership of your submissions; however, you
                                        agree that by posting, uploading, inputting, providing, submitting, entering or
                                        otherwise transmitting your submissions to us or any third party using the Site,
                                        you:
                                        <ul className="list">
                                            <li>
                                                grant the Foundation, its affiliates, third parties, sub-licensees,
                                                successors and assignees and each Site user who downloads the Content
                                                under the Terms and Conditions, a perpetual, non-exclusive, worldwide,
                                                royalty-free, fully paid up, irrevocable licence to (i) use, copy,
                                                distribute, modify and create derivative works from the content; (ii)
                                                publicly perform or display, licence and distribute copies of the
                                                content, modified the content and derivative works of the content; and
                                                (iii) sublicense to third parties the foregoing rights, including the
                                                right to sublicense to further third parties, as necessary or advisable
                                                (as determine by the Foundation or other licensed person in its sole
                                                discretion) to allow the Foundation or other licensed person to fulfil
                                                its charitable mission, to further its related operations, and to
                                                create, advertise, operate and manage the Site.
                                            </li>
                                            <li>
                                                confirm, represent and warrant to us that you have all rights, titled
                                                and interest, as well as the power and authority necessary, to grant the
                                                license to your submitted content set out above.
                                            </li>
                                            <li>
                                                acknowledge and agree that the technical processing and transmission of
                                                the Site, including your submissions, may involve transmissions over
                                                various networks and changes to conform and adapt to technical
                                                requirements of connecting networking or devices and that your
                                                submissions may be subject to “caching” or other technical processing or
                                                transmission policies and procedures by us or at intermediate locations
                                                on the Internet.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span>12.3</span>In addition to the rights above, you acknowledge and agree that
                                        we may keep the Content you submitted for as long as we are legally allowed to
                                        and disclose it for any purpose, including but not limited to:
                                        <p>(a) comply with legal process;</p>
                                        <p>(b) enforce these Terms and Conditions;</p>
                                        <p>
                                            (c) respond to claims that any the content violates the rights of third
                                            parties; or{" "}
                                        </p>
                                        <p>
                                            (d) without undertaking a duty to do so, protect the rights, property, or
                                            personal safety of the Foundation, its users and the public.
                                        </p>
                                    </li>
                                    <li>
                                        <span>12.4</span>You agree not to upload, post or otherwise transmit through the
                                        Site any content or any other materials whatsoever that are or could appear to:
                                        <p>
                                            (i) be defamatory, obscene, invasive to another person's privacy or
                                            protected data, or tortious; (ii) be infringing upon anyone's intellectual
                                            property rights, including any patent, trademark, trade secret, copyright,
                                            or right of publicity; (iii) contain any software viruses or any other
                                            harmful computer code, files, or programs, including any designed to
                                            interrupt, destroy, or limit the functionality of any computer software or
                                            hardware or telecommunications equipment; and (iv) in violation of any
                                            applicable license, law or contractual or fiduciary duty or provision
                                            (including by exercise of the rights you grant to the Foundation above).
                                        </p>
                                    </li>
                                    <li>
                                        <span>12.5</span>The Foundation anticipates that substantial Content or even
                                        assistance will be made available to you and others through the Site or
                                        otherwise. Please note that the Content may be outdated or contain typographical
                                        errors, inaccuracies, omissions, or problematic or defective functionality, and
                                        assistance (such as Foundation help with a report you are obligated to make)
                                        will need to be considered and reviewed before use is made.
                                    </li>
                                    <li>
                                        <span>12.6</span>YOU AGREE NOT TO RELY ON CONTENT OR ASSISTANCE AND TO DETERMINE
                                        ALL RELEVANT FACTORS ON YOUR OWN, INCLUDING (WITHOUT LIMITATION) ACCURACY,
                                        FUNCTIONALITY, QUALITY, RELIABILITY AND OTHER RELEVANT FACTORS. We reserve the
                                        right to monitor and exercise all other rights of ours, and also to modify or
                                        remove any content or assistance at any time, but do not undertake any duty to
                                        do so.
                                    </li>
                                    <li>
                                        <span>12.7</span>Any opinions, advice, statements, assistance, services, offers,
                                        or information expressed or made available by third parties, including users,
                                        are those of the respective author(s) or distributor(s) and not of the
                                        Foundation.
                                    </li>
                                    <li>
                                        <span>12.8</span>In the case that any content posted or uploaded by you to our
                                        Site constitutes a violation of another person’s intellectual property rights,
                                        or of their right to privacy we may, if required and/or subject to the
                                        provisions of the applicable law disclose your personal data (to the degree
                                        necessary).
                                    </li>
                                    <li>
                                        <span>12.9</span>We have the right to remove any posting you make on our Site.
                                    </li>
                                    <li>
                                        <span>12.10</span>You are solely responsible for securing and backing up your
                                        content.
                                    </li>
                                    <li>
                                        <span>12.11</span>The Site is regulated by the provisions of the Privacy Policy
                                        and any personal data collected and/or otherwise processed shall be processed in
                                        accordance with the provisions of the Privacy Policy and the General Data
                                        Protection Regulation (GDPR) and all applicable local restrictions and
                                        regulations as amended from time to time.
                                    </li>
                                    <li>
                                        <span>12.12</span>The Foundation will not be liable for the content of any
                                        third-party websites or the actions or omissions of their proprietors nor for
                                        the contents of third party advertisements and sponsorship on those websites.
                                        The hyperlinks to other websites are provided for information purposes only. You
                                        use any such links at your own risk.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    13. We are not responsible for viruses and you are solely responsible for taking all
                                    necessary actions to ensure that you do not upload any malicious material
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>13.1</span>We do not guarantee that our Site will be secure or free from
                                        bugs or viruses. We will do our best to safeguard the Site to the best possible
                                        level however we strongly encourage you to take all appropriate actions to
                                        safeguard against malicious software.
                                    </li>
                                    <li>
                                        <span>13.2</span>You are responsible for configuring your information
                                        technology, computer programmes and platform to access our Site. You should use
                                        your own virus protection software.
                                    </li>
                                    <li>
                                        <span>13.3</span>You must not misuse our Site by knowingly introducing viruses,
                                        trojans, worms, logic bombs or other material that is malicious or
                                        technologically harmful. You must not attempt to gain unauthorised access to our
                                        Site, the server on which our Site is stored or any server, computer or database
                                        connected to our Site. You must not attack our Site via a denial-of-service
                                        attack or a distributed denial-of service attack. By breaching this provision,
                                        you would commit a criminal offence. We will report any such breach to the
                                        relevant law enforcement authorities and we will cooperate with those
                                        authorities by disclosing your identity to them. In the event of such a breach,
                                        your right to use our site will be terminated immediately.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>14. Rules about linking to our Site</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>14.1</span>You may establish a link to our home page, provided you do so
                                        in a way that is fair and legal and does not damage our reputation or take
                                        advantage of it.
                                    </li>
                                    <li>
                                        <span>14.2</span>You must not establish a link in such a way as to suggest any
                                        form of association, approval or endorsement on our part where none exists.
                                    </li>
                                    <li>
                                        <span>14.3</span>You must not establish a link to our Site in any website that
                                        is not owned by you.
                                    </li>
                                    <li>
                                        <span>14.4</span>Our Site must not be framed on any other site, nor may you
                                        create a link to any part of our Site other than the home page.
                                    </li>
                                    <li>
                                        <span>14.5</span>We reserve the right to withdraw linking permission without
                                        notice.
                                    </li>
                                    <li>
                                        <span>14.6</span>If you wish to link to or make any use of content on our Site
                                        other than that set out above, please contact{" "}
                                        <a href="mailto:info@iqcharity.org">info@iqcharity.org</a>.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>15. Privacy Policy</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>15.1</span>
                                        <Link to="./privacy-policy/" target="_blank">
                                            Our Privacy Policy
                                        </Link>{" "}
                                        forms part of these Terms and Conditions and is incorporated herein. We urge you
                                        not to provide personal data about others unless you are authorised or required
                                        to do so by contract or applicable law. You may provide personal data on behalf
                                        of another person if you have provided them with a copy of this notice and any
                                        applicable supplementary privacy policy, and obtained their explicit consent. We
                                        may ask you at any time to provide evidence of that consent.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>16. How we may use your personal information</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>16.1</span>We will only use your personal information as set out in our{" "}
                                        <Link to="./privacy-policy/" target="_blank">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <span>16.2</span>
                                        You may at any time notify the Foundation that you wish to exercise any of its
                                        rights afforded to it by the GDPR and listed below (and explained in detail in
                                        the Privacy Policy) in accordance with the procedure described in the Privacy
                                        Policy:
                                        <ul className="list">
                                            <li>
                                                Request access to your personal data (commonly known as a “data subject
                                                access request”).
                                            </li>
                                            <li>
                                                Request correction of the personal data that we hold about you. This
                                                enables you to have any incomplete or inaccurate data we hold about you
                                                corrected, though we may need to verify the accuracy of the new data you
                                                provide to us.
                                            </li>
                                            <li>
                                                Request erasure of your personal data. This enables you to ask us to
                                                delete or remove personal data where there is no good reason for us
                                                continuing to process it. Please note however that we may not always be
                                                able to comply with your request of erasure for specific legal reasons
                                                which will be notified to you, if applicable, at the time of your
                                                request. In such a case, your data will be stored but not processed
                                                until expiration of the retention obligation.
                                            </li>
                                            <li>
                                                Subject to the legal basis on which the processing activity is based,
                                                you may object to processing of your personal data. Please note that in
                                                some cases, we may have compelling legitimate grounds to process your
                                                information which we need to comply with.
                                            </li>
                                            <li>
                                                Request restriction of processing of your personal data (a) if it is not
                                                accurate;(b) where processing may be unlawful but you do not want us to
                                                erase your data; (c) where you need us to hold the data even if we no
                                                longer require it; or (d) where you may have objected to our use of your
                                                data but we need to verify whether we have overriding legitimate grounds
                                                to use it.
                                            </li>
                                            <li>
                                                Request the transfer of your personal data to you or to a third party.
                                            </li>
                                            <li>
                                                In case the processing of the data is performed subject to your consent,
                                                you may withdraw consent at any time where we are relying on consent to
                                                process your personal data. However, we note that this will not affect
                                                the lawfulness of any processing carried out before you withdraw your
                                                consent. If you withdraw your consent, we may not be able to provide
                                                certain products or services to you. We will of course advise you if
                                                this is the case at the time you withdraw your consent.
                                            </li>
                                            <li>
                                                Please note that we may charge you with an administrative fee, in cases
                                                where requests are deemed manifestly unfounded or excessive, in
                                                particular because of their repetitive character.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    17. Which country's laws apply to any disputes?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>17.1</span>Please note that these Terms and Conditions, their subject
                                        matter and their formation (and any non-contractual disputes or claims), are
                                        governed by Cyprus law. You and we both agree that the courts of the Republic of
                                        Cyprus will have exclusive jurisdiction.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    18. Infringement of Our Rights or Rights of Others; Your Warranty
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>18.1</span>The Site is protected by intellectual property laws and you
                                        agree to respect them. All rights not expressly granted to you are reserved. It
                                        is our policy to terminate in appropriate circumstances any (if any) account or
                                        right of access for repeated infringement, and we also reserve the right to
                                        terminate for even one infringement.{" "}
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>19. Indemnification</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>19.1</span>You agree to indemnify, defend and hold harmless the Foundation
                                        and other third parties with whom we work in order to provide the Site, Content,
                                        services or pursue our mission (including but not limited to our and their
                                        respective affiliates, officers, employees and agents) (collectively “Third
                                        Parties”), from and against any and all losses, damage, liability and costs of
                                        every nature incurred by any of them in connection with any claim, damage or
                                        loss related to or arising out of: the Content, use of the Site or related
                                        sites, any assistance or services provided by us or Third Parties, any alleged
                                        unauthorized use of the Site, or any breach or alleged breach by you of these
                                        Terms and Conditions. You agree to cooperate fully in the defence of any of the
                                        foregoing. We reserve the right, at our own expense, to control exclusively the
                                        defence of any matter otherwise subject to indemnification by you and you will
                                        not settle any matter without our consent in a non-electronic record. Your
                                        obligation to indemnify, defend and hold harmless shall be limited to the extent
                                        that you are afforded sovereign immunity under applicable laws. In such cases
                                        where your obligation to indemnify may be limited due to the requirements of
                                        applicable laws, you shall be responsible for the ordinary negligent acts and
                                        omissions of your agents and employees causing harm to persons not a party to
                                        this agreement.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    20. NO WARRANTIES, CONDITIONS OR OTHER DUTIES.
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>20.1</span>THE SITE AND ALL CONTENT (regardless of who generates it) ARE
                                        SUBJECT TO CHANGE AND PROVIDED BY US OR THIRD PARTIES "AS IS" WITHOUT ANY
                                        WARRANTY OR CONDITION, AND WITHOUT THE UNDERTAKING OF ANY DUTY, OF ANY KIND,
                                        EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY (IF ANY)
                                        WARRANTIES OR CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                                        PURPOSE, AND ANY DUTY (IF ANY) OF WORKMANLIKE EFFORT OR LACK OF NEGLIGENCE. THE
                                        COMPLETE SITE IS PROVIDED: (1) WITH ALL FAULTS AND THE ENTIRE RISK AS TO
                                        SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU; AND (2)
                                        WITHOUT ANY ASSURANCE, OR WARRANTY, CONDITION OR DUTY OF OR REGARDING:
                                        FUNCTIONALITY; PRIVACY; SECURITY; ACCURACY; AVAILABILITY; LACK OF: NEGLIGENCE,
                                        INTERRUPTION, VIRUSES OR OF OTHER HARMFUL CODE, COMPONENTS OR TRANSMISSIONS; OR
                                        THE NATURE OR CONSEQUENCES OF AVAILABLE CONTENT SUCH AS (WITHOUT LIMITATION)
                                        WHETHER SOFTWARE OR OTHER CONTENT IS SUBJECT TO ANY PARTICULAR LICENSE, OR
                                        WHETHER IT IS SUBJECT TO ANY RESTRICTIONS OR CONSEQUENCES THAT MIGHT BE
                                        TRIGGERED BY ANY EXERCISE OF A RIGHT GRANTED UNDER THESE TERMS AND CONDITIONS.
                                        ALSO, THERE IS NO WARRANTY BY US OR THIRD PARTIES OF TITLE OR AGAINST
                                        INFRINGEMENT OR INTERFERENCE WITH ENJOYMENT OF ANY ASPECT OF THE COMPLETE SITE.
                                        YOU AGREE THAT YOU WILL OBTAIN (INCLUDING THROUGH DOWNLOAD) ANY CONTENT ENTIRELY
                                        AT YOUR OWN RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY RESULTING
                                        INFRINGEMENT, BREACH OF CONTRACT, CONSEQUENCE OR DAMAGE, INCLUDING (WITHOUT
                                        LIMITATION) TO YOUR COMPUTER SYSTEM OR LOSS OF DATA.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    21. NO INCIDENTAL, CONSEQUENTIAL OR CERTAIN OTHER DAMAGES.
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>21.1</span>TO THE FULL EXTENT ALLOWED BY LAW, YOU AGREE THAT NEITHER THE
                                        FOUNDATION NOR ANY OF THE THIRD PARTIES, WILL BE LIABLE TO YOU OR ANYONE ELSE
                                        FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES, DAMAGES FOR LOST
                                        PROFITS, FOR LOSS OF PRIVACY OR SECURITY, FOR LOSS OF REPUTATION, FOR FAILURE TO
                                        MEET ANY DUTY (INCLUDING WITHOUT LIMITATION ANY DUTY OF GOOD FAITH OR LACK OF
                                        NEGLIGENCE OR OF WORKMANLIKE EFFORT), OR FOR ANY OTHER SIMILAR DAMAGES
                                        WHATSOEVER THAT ARISE OUT OF OR ARE RELATED TO ANY ASPECT OF THE COMPLETE SITE
                                        OR TO ANY BREACH OF THESE TERMS AND CONDITIONS (INCLUDING WITHOUT LIMITATION,
                                        THE PRIVACY NOTICE), EVEN IF WE OR A THIRD PARTY HAS BEEN ADVISED OF THE
                                        POSSIBILITY OF SUCH DAMAGES AND EVEN IN THE EVENT OF FAULT, TORT (INCLUDING
                                        NEGLIGENCE) OR STRICT OR PRODUCTS LIABILITY OR MISREPRESENTATION.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>22. Limitation of liability</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>22.1</span>The Site is provided for information purposes only and, to the
                                        maximum extent permitted by applicable law, we and our associated persons
                                        exclude all liability for any loss or damage of whatever kind and however
                                        arising in connection with your use of, or inability to use, the Site and any
                                        materials you obtain via the Site.
                                    </li>
                                    <li>
                                        <span>22.2</span>You acknowledge and accept that use of the Site is subject to
                                        the risks inherent in any connection and transmission on the internet, in
                                        particular in relation to security risks and vulnerabilities, technical
                                        performance and risk of interruption. Accordingly the Foundation is not liable
                                        to you in any circumstances for any losses or damages caused by disruption or
                                        failure of internet networks or for any interruptions to or restrictions on the
                                        accessibility of the Site arising for any reason, including, but not limited to,
                                        by reason of virus, security related vulnerability, or technical or operational
                                        failure of any nature.
                                    </li>
                                    <li>
                                        <span>22.3</span>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
                                        FOUNDATION DISCLAIM ALL LIABILITY AND SHALL NOT BE LIABLE FOR ANY INDIRECT,
                                        INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT
                                        LIMITATION DAMAGES FOR LOST PROFITS OR REVENUES, GOODWILL, WORK STOPPAGE,
                                        SECURITY BREACHES, VIRUSES, COMPUTER FAILURE OR MALFUNCTION, USE, DATA OR OTHER
                                        INTANGIBLE LOSSES OR COMMERCIAL DAMAGES, EVEN IF ANY OF SUCH PARTIES ARE ADVISED
                                        OF THE POSSIBILITY OF SUCH LOSSES, ARISING UNDER OR IN CONNECTION WITH THESE
                                        TERMS AND CONDITIONS, THE SITE, THE USE OF OR INABILITY TO USE THE SAME, OR ANY
                                        OTHER SUBJECT MATTER HEREOF.
                                    </li>
                                    <li>
                                        <span>22.4</span>THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE FOUNDATION
                                        SHALL NOT BE RESPONSIBLE OR LIABLE TO YOU OR ANY OTHER PERSON FOR ANY ERRORS OR
                                        OMISSIONS IN THE SITE; ANY INFORMATION MADE AVAILABLE THROUGH THEM; ANY DECISION
                                        MADE OR ACTION TAKEN IN RELIANCE ON THE SITE OR THE INFORMATION MADE AVAILABLE
                                        THROUGH THEM; OR FOR ANY LOSS OR DAMAGE- INCLUDING CONSEQUENTIAL, SPECIAL OR
                                        SIMILAR DAMAGES- THAT ARISE OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR
                                        ANY INFORMATION OR MATERIALS OBTAINED BY YOU VIA OR IN CONNECTION WITH THE SITE.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    23. Termination or Cancellation; No Continuing Rights.
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>23.1</span>You have no continuing right to use the Site and we may deny or
                                        suspend access, or terminate or cancel this agreement with or without cause and
                                        at any time and without prior notice.
                                    </li>
                                    <li>
                                        <span>23.2</span>Termination or cancellation will not eliminate the surviving
                                        provisions of these Terms and Conditions and you will still be liable for
                                        obligations incurred before the agreement or access ended.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>24. Entire Agreement</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>24.1</span>These Terms and Conditions, including the Privacy Policy, any
                                        amendments and any: (a) notices, terms and items incorporated into any of them;
                                        (b) additional terms and conditions contained on the Site for particular
                                        activities or content; (c) our disclosures and your consents provided on or in
                                        connection with the Site or any Content, service or other activity; constitutes
                                        the entire agreement between you and the Foundation regarding the Site or the
                                        subject matter of the foregoing (collectively, “Entire Agreement”). If any
                                        provision of the Entire Agreement is found by a court of competent jurisdiction
                                        to be invalid, its remaining provisions will remain in full force and effect,
                                        provided that the allocation of risks described herein is given effect to the
                                        fullest extent possible. The foregoing does not impair the enforceability of
                                        additional agreements you enter into such as an agreement for a grant.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>25. Miscellaneous</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>25.1</span>Our failure to act with respect to a breach by you does not
                                        waive our right to act with respect to subsequent or similar breaches. Time is
                                        of the essence of the entire agreement and there are no third party
                                        beneficiaries of it. The provisions of the Terms and Conditions and our rights
                                        under the Privacy Notice will survive termination or cancellation of this
                                        agreement. You may print or make an electronic copy of the agreement for your
                                        official records; to the extent required by law, we hereby instruct you to do
                                        so.
                                    </li>
                                    <li>
                                        <span>25.2</span>We may at any time assign our rights and obligations under
                                        these Terms and Conditions, in whole or in part, without notice to you. You may
                                        not assign these Terms and Conditions or any of your rights or obligations under
                                        these Terms and Conditions without our prior written consent.
                                    </li>
                                    <li>
                                        <span>25.3</span>If any provision of the agreement or its implementation towards
                                        any person or in any circumstances shall be invalid, illegal or unenforceable,
                                        the remainder of the agreement and its implementation shall not be affected and
                                        will be enforceable in any manner allowed by law.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>26. Our trademarks are registered</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <ul>
                                    <li>
                                        <span>26.1</span>"IQOPTION" is a trademark of IQOPTION CHARITABLE FOUNDATION
                                        registered on the territory of the European Union countries. You are not
                                        permitted to use it without our approval.{" "}
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </TermsBlock>
        </Layout>
    );
};

export default TermsConditions;

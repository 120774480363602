import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ReportsMain from "./ReportsMain";
import ReportPage from "./ReportPage";
import Layout from "../../components/Layout";
import Page404 from "../../components/Page404";

class Reports extends Component<RouteComponentProps> {
    render() {
        const url = this.props.match.url;

        return (
            <Switch>
                <Route path={url} exact component={ReportsMain} />
                <Route path={`${url}/:slug`} component={ReportPage} />
                <Route>
                    <Layout>
                        <Page404 />
                    </Layout>
                </Route>
            </Switch>
        );
    }
}

export default Reports;

import React, { Component } from "react";
import Lightbox, { Image } from "react-images";
import "./assets/style.css";

interface DefaultProps {
    backdropClosesModal?: boolean;
}

interface Props extends DefaultProps {
    images: Image[];
}

interface State {
    lightBoxIsOpen: boolean;
    currentImage: number;
}

export default class Gallery extends Component<Props, State> {
    static defaultProps: DefaultProps = {
        backdropClosesModal: true
    };

    state: State = {
        lightBoxIsOpen: false,
        currentImage: 0
    };

    handleLightBoxClose = () => {
        this.setState({
            lightBoxIsOpen: false
        });
    };

    handleGalleryClick = (e: React.MouseEvent, newCurrentImage: number) => {
        e.preventDefault();
        this.setState({
            currentImage: newCurrentImage,
            lightBoxIsOpen: true
        });
    };

    handleLightBoxClickNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1
        });
    };

    handleLightBoxClickPrev = () => {
        this.setState({
            currentImage: this.state.currentImage - 1
        });
    };

    render() {
        const { images, backdropClosesModal } = this.props;
        const { lightBoxIsOpen, currentImage } = this.state;

        return (
            <div className="galleryWrapper">
                {images.map((image, index) => {
                    return (
                        <a
                            href={image.src}
                            key={index}
                            onClick={e => {
                                this.handleGalleryClick(e, index);
                            }}
                        >
                            <img src={image.src} alt="" />
                            <span />
                        </a>
                    );
                })}
                <Lightbox
                    isOpen={lightBoxIsOpen}
                    images={images}
                    currentImage={currentImage}
                    onClose={this.handleLightBoxClose}
                    onClickNext={this.handleLightBoxClickNext}
                    onClickPrev={this.handleLightBoxClickPrev}
                    backdropClosesModal={backdropClosesModal}
                />
            </div>
        );
    }
}

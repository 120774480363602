import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, RouteComponentProps } from "react-router-dom";
import axios from "axios";
import { constants } from "./config";
import Foundation from "./pages/Foundation";
import Help from "./pages/Help";
import Partners from "./pages/Partners";
import Main from "./pages/Main";
import Contacts from "./pages/Contacts";
import Projects from "./pages/Projects";
import Reports from "./pages/Reports";
import Layout from "./components/Layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Page404 from "./components/Page404";
import { StaticContext } from "react-router";
import TermsConditions from "./pages/TermsConditions";
import MyFirstCode from "./pages/Projects/MyFirstCode";

axios.defaults.baseURL = constants.API_HOST;
axios.defaults.headers.common["X-portal-id"] = constants.PORTAL_ID;
const TITLE_RU = "Благотворительный фонд «Возможности интеллекта»";
const TITLE_EN = "IQ Option Charitable Foundation";

class App extends Component {
    componentDidMount() {
        if (constants.LOCALE === "ru") {
            document.title = TITLE_RU;
        } else {
            document.title = TITLE_EN;
        }
    }
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Main} />
                    <Route
                        path="/foundation"
                        render={(props: RouteComponentProps<{}, StaticContext>) => {
                            if (constants.LOCALE === "ru") {
                                return <Foundation {...props} />;
                            } else {
                                return <Foundation {...props} />;
                            }
                        }}
                    />
                    <Route path="/partners" component={Partners} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/reports" component={Reports} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/kak-pomoch" component={Help} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms&conditions" component={TermsConditions} />
                    <Route path="/:slug(myfirstcode)" component={MyFirstCode} />
                    <Route>
                        <Layout>
                            <Page404 />
                        </Layout>
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import Layout from "../../../components/Layout";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import "./assets/style.css";
import rwandaFlag from "./assets/img/rwanda.svg";
import faithFound from "./assets/img/faith.png";
import corn from "./assets/img/11.svg";
import book from "./assets/img/12.svg";
import wheat from "./assets/img/6.svg";
import basket from "./assets/img/7.svg";
import workshops from "./assets/img/8.svg";
import { Link } from "react-router-dom";

interface State {
    isLoading: boolean;
    project: Project | null;
}

interface Props {
    slug: string;
}

class FaithFund extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }
    }

    render() {
        const { isLoading, project } = this.state;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="faithFound">
                    <div className="faithFound_head">
                        <div className="intro clear">
                            <div className="responsive_bg" />
                            <div className="container">
                                <div className="main_text">
                                    <h1 className="h1">FARMING AND BASKET WEAVING PROJECT IN RWANDA</h1>
                                    <p className="subhead">
                                        Educating, restoring hope and empowering dreams of women and children in Rwanda
                                    </p>
                                </div>
                            </div>
                            <div className="mobile_image" />
                        </div>
                    </div>
                    <div className="faithFound_about">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">ABOUT THE PROJECT</h2>
                                <p className="text">
                                    The project is implemented in Kinigi, Musanze (Rwanda) in cooperation with{" "}
                                    <Link to="http://en.iqcharity.org/partners/14">Faith Foundation</Link> to help
                                    vulnerable women build strong parenting skills, to expand their vocational
                                    abilities, and to provide them with positive community and cultural activities.
                                    Approximately 45 adults a month attend monthly classes to learn how to keep record
                                    and balance expenses, how to budget, and how to plan for purchasing cost-effective
                                    household supplies.
                                </p>
                                <div className="project_info">
                                    <div className="project_info_block">
                                        <p>Country</p>
                                        <div>
                                            <span>
                                                <img src={rwandaFlag} alt="" />
                                            </span>
                                            Rwanda
                                        </div>
                                    </div>
                                    <div className="project_info_block">
                                        <p>Project partners</p>
                                        <div>
                                            <span>
                                                <img src={faithFound} alt="" />
                                            </span>
                                            Faith Foundation
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faithFound_goals">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">GOALS</h2>
                                <div className="goals_grid">
                                    <div className="goals_block">
                                        <span>
                                            <img src={corn} alt="" />
                                        </span>
                                        <p>
                                            To provide for income generating activities in farming and basket weaving so
                                            as to ensure livelihoods for women and their families as well as continued,
                                            self-reliant funding of Faith Foundation.
                                        </p>
                                    </div>
                                    <div className="goals_block">
                                        <span>
                                            <img src={book} alt="" />
                                        </span>
                                        <p>
                                            To provide activities and workshops designed to learn more about starting
                                            small, sustainable businesses with which women can systematically increase
                                            income and be able to start a regular savings schedule.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faithFound_participants">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">PARTICIPANTS</h2>
                                <div className="participants_grid">
                                    <div className="participants_block">
                                        <div className="participants_num">
                                            <span>35</span>
                                        </div>
                                        <div className="participants_name">Women in farming</div>
                                    </div>
                                    <div className="participants_block">
                                        <div className="participants_num">
                                            <span>15</span>
                                        </div>
                                        <div className="participants_name">
                                            Women <br />
                                            in basket weaving
                                        </div>
                                    </div>
                                    <div className="participants_block">
                                        <div className="participants_num">
                                            <span>4</span>
                                        </div>
                                        <div className="participants_name">Invited experts</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="faithFound_results">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">RESULTS IN FIGURES</h2>
                                <div className="result_grid">
                                    <div className="result_block">
                                        <div className="result_block-img">
                                            <img src={wheat} alt="" />
                                            <span>10</span>
                                        </div>
                                        <div className="result_name">
                                            Tonnes <br /> of vegetables
                                        </div>
                                    </div>
                                    <div className="result_block">
                                        <div className="result_block-img">
                                            <img src={basket} alt="" />
                                            <span>225</span>
                                        </div>
                                        <div className="result_name">Baskets</div>
                                    </div>
                                    <div className="result_block">
                                        <div className="result_block-img">
                                            <img src={workshops} alt="" />
                                            <span>4</span>
                                        </div>
                                        <div className="result_name">
                                            Workshops
                                            <br /> and activities
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default FaithFund;

import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import PartnersMain from "./PartnersMain";
import PartnersPage from "./PartnersPage";
import Layout from "../../components/Layout";
import Page404 from "../../components/Page404";

class Partners extends Component<RouteComponentProps> {
    render() {
        const url = this.props.match.url;

        return (
            <Switch>
                <Route path={url} exact component={PartnersMain} />
                <Route path={`${url}/:id`} component={PartnersPage} />
                <Route>
                    <Layout>
                        <Page404 />
                    </Layout>
                </Route>
            </Switch>
        );
    }
}

export default Partners;

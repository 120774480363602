export interface MainMenuLink {
    to: string;
    label: string;
}

export const mainMenuLinks: MainMenuLink[] = [
    {
        to: "/foundation",
        label: "mainMenu.fond"
    },
    {
        to: "/partners",
        label: "mainMenu.partners"
    },
    {
        to: "/projects",
        label: "mainMenu.projects"
    },
    {
        to: "/reports",
        label: "mainMenu.reports"
    },
    {
        to: "/contacts",
        label: "mainMenu.contacts"
    },
    {
        to: "/kak-pomoch",
        label: "mainMenu.help"
    }
];

export const mobileMenuLinks: MainMenuLink[] = [
    {
        to: "/",
        label: "mainMenu.main"
    },
    ...mainMenuLinks
];

import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Layout: React.FC = props => {
    return (
        <>
            <Header>{props.children}</Header>
            <Wrapper>{props.children}</Wrapper>
            <Footer>{props.children}</Footer>
        </>
    );
};

export default Layout;

import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import "./assets/style.css";
import Partner from "../../../models/Partner";
import { getOnePartner } from "../../../api";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { asset } from "../../../utils";
import ReportPreview from "../../../components/ReportPreview";
import Page404 from "../../../components/Page404";

interface IdParams {
    id: string;
}

interface State {
    isLoading: boolean;
    partner: Partner | null;
}

function createMarkup(partner: Partner) {
    if (!partner.description) {
        return { __html: "" };
    }

    return { __html: partner.description };
}

class PartnersPage extends Component<RouteComponentProps<IdParams> & WithTranslation, State> {
    state: State = {
        isLoading: true,
        partner: null
    };

    async componentDidMount(): Promise<void> {
        try {
            const partner = await getOnePartner(Number(this.props.match.params.id));
            this.setState({ isLoading: false, partner });
        } catch (e) {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { isLoading, partner } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        if (partner === null) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="partnerPage">
                    <div className="container">
                        <div className="partner_info">
                            <div className="partner_info_img">
                                <img src={asset(partner.logoPath!)} alt="" />
                            </div>
                            <div className="partner_info_right">
                                <h1 className="h1">{partner.name}</h1>
                                <p className="subhead">{partner.scope}</p>
                                <ul className="partner_info_list">
                                    <li>{partner.address}</li>
                                    <li>
                                        <a href={partner.website} target="_blank" rel="noopener noreferrer">
                                            {t("partners.partnersPage.website")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <article>
                            <h2 className="h2">{t("partners.partnersPage.title")}</h2>
                            <p dangerouslySetInnerHTML={createMarkup(partner)} />
                        </article>
                        <div className="more_news">
                            <h2 className="h2">{t("partners.partnersPage.moreNews")}</h2>
                            <div className="all_reports">
                                {partner.reports.map(report => {
                                    return <ReportPreview report={report} key={report.id} />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(PartnersPage);

import React from "react";
import { asset } from "../../utils";
import styled from "styled-components";
import Moment from "react-moment";
import "moment/locale/ru";
import { constants } from "../../config";

const Date = styled.div<{ dateColor?: string }>`
    line-height: 21px;
    height: 21px;
    vertical-align: top;
    font-size: 16px;
    color: ${({ dateColor }) => (dateColor ? dateColor : "#9a9a9a")};
    font-weight: 400;
    padding-bottom: 5px;
    text-transform: capitalize;
    display: inline-block;
`;

interface Props {
    flagPath: string | null;
    publicationDate: string;
    dateColor?: string;
}

const DateBlock: React.FC<Props> = props => {
    const { flagPath, publicationDate, dateColor } = props;
    return (
        <div className="date_block">
            {flagPath && (
                <span className="flag">
                    <img src={asset(flagPath)} alt="" />
                </span>
            )}
            <Date dateColor={dateColor}>
                <Moment locale={constants.LOCALE} format="DD MMM YYYY">
                    {publicationDate}
                </Moment>
            </Date>
        </div>
    );
};

export default DateBlock;

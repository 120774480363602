import React from "react";
import Layout from "../../../components/Layout";
import "./assets/style.css";
import { RouteComponentProps } from "react-router";
import Report from "../../../models/Report";
import Loader from "../../../components/Loader/Loader";
import { getOneReport } from "../../../api";
import "./assets/style.css";
import { asset } from "../../../utils";
import "moment-timezone";
import Gallery from "../../../components/Gallery";
import DateBlock from "../../../components/DateBlock";
import { HeadSlideBg, HeadSlideTextBlock } from "./Atoms";
import { withTranslation, WithTranslation } from "react-i18next";
import Page404 from "../../../components/Page404";

interface SlugParams {
    slug: string;
}

interface State {
    isLoading: boolean;
    report: Report | null;
}

function createMarkup(report: Report) {
    if (!report.pageArticle) {
        return { __html: "" };
    }

    return { __html: report.pageArticle };
}

class ReportPage extends React.Component<RouteComponentProps<SlugParams> & WithTranslation, State> {
    state: State = {
        isLoading: true,
        report: null
    };

    async componentDidMount(): Promise<void> {
        try {
            const report = await getOneReport(this.props.match.params.slug);
            this.setState({ isLoading: false, report });
        } catch (e) {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { isLoading, report } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        if (report === null || report.linkToProject) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="reportPage">
                    <div className="head_slide">
                        <HeadSlideBg background={report.backgroundPath!} />
                        <HeadSlideTextBlock color={report.pageTitleColor!} subtitleColor={report.pageSubtitleColor!}>
                            <DateBlock
                                flagPath={report.country ? report.country.flagPath : null}
                                publicationDate={report.publicationDate}
                            />
                            <div>
                                <h1>{report.pageTitle}</h1>
                                <p>{report.pageSubtitle}</p>
                            </div>
                        </HeadSlideTextBlock>
                    </div>
                    <div className="wrapper">
                        <div className="container">
                            <article dangerouslySetInnerHTML={createMarkup(report)} />
                        </div>
                        {report.galleryEnabled && (
                            <div className="gallery">
                                <div className="section-inner">
                                    <h2 className="h2">{t("reports.gallery")}</h2>
                                    <Gallery
                                        images={report.images.map(image => ({
                                            src: asset(image.imagePath)
                                        }))}
                                    />
                                </div>
                            </div>
                        )}
                        {report.videoEnabled && (
                            <div className="videoBlock">
                                <h2 className="h2">{t("reports.video")}</h2>
                                <iframe
                                    title="video"
                                    width="100%"
                                    height="100%"
                                    src={report.videoUrl!}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(ReportPage);

import React, { Component } from "react";
import Select from "react-select";
import Slider from "react-slick";
import { RouteComponentProps } from "react-router";
import Layout from "../../../components/Layout";
import { getOneProject } from "../../../api";
import Gallery from "../../../components/Gallery";
import Page404 from "../../../components/Page404";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import "./assets/style.css";
import robo from "./assets/img/robo.png";
import robo2 from "./assets/img/robo2.png";
import aim from "./assets/img/aim.png";
import algoritmika from "./assets/img/algoritmika.png";
import iconBooks from "./assets/img/icon-books.svg";
import iconAccess from "./assets/img/icon-access.svg";
import iconSupport from "./assets/img/icon-support.svg";
import gal1 from "./assets/img/gallery/img1.jpg";
import gal2 from "./assets/img/gallery/img2.jpg";
import gal3 from "./assets/img/gallery/img3.jpg";
import gal4 from "./assets/img/gallery/img4.jpg";
import gal5 from "./assets/img/gallery/img5.jpg";
import gal6 from "./assets/img/gallery/img6.jpg";
import { ActionMeta, OptionsType, ValueType } from "react-select/lib/types";
import ReportPreview from "../../../components/ReportPreview";
import { withTranslation, WithTranslation } from "react-i18next";

interface State {
    isLoading: boolean;
    project: Project | null;
    showSlides: boolean;
    inputValue: string;
}

interface Props {
    slug: string;
}

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true
};

const MyFirstCodeImages = [{ src: gal1 }, { src: gal2 }, { src: gal3 }, { src: gal4 }, { src: gal5 }, { src: gal6 }];

interface Option {
    value: string;
    label: string;
}

const SELECT_OPTIONS: OptionsType<Option> = [
    { value: "https://snazzymaps.com/embed/45115", label: "Санкт-Петербург" },
    { value: "https://snazzymaps.com/embed/52129", label: "Ленинградская область" },
    { value: "https://snazzymaps.com/embed/52131", label: "Калининградская область" },
    { value: "https://snazzymaps.com/embed/116566", label: "Мурманская область" },
    { value: "https://snazzymaps.com/embed/117062", label: "Архангельская область" },
    { value: "https://snazzymaps.com/embed/117094", label: "Республика Карелия" },
    { value: "https://snazzymaps.com/embed/52121", label: "Вологодская область" },
    { value: "https://snazzymaps.com/embed/117166", label: "Удмуртская республика" },
    { value: "https://snazzymaps.com/embed/117174", label: "Республика Коми" },
    { value: "https://snazzymaps.com/embed/117175", label: "Новгородская область" }
];

const FIRST_CODE_SLIDES = [
    <div key="1" className="grant-block">
        <div className="grant-img">
            <img src={iconBooks} alt="" />
        </div>
        <div className="grant-text">
            <strong>Бесплатное обучение преподавателей</strong> образовательных учрежденийработе на платформе.
        </div>
    </div>,
    <div key="2" className="grant-block">
        <div className="grant-img">
            <img src={iconAccess} alt="" />
        </div>
        <div className="grant-text">
            <strong>Бесплатный доступ к онлайн-платформе:</strong> индивидуальные аккаунты для учеников (передача по
            договору пожертвования).
        </div>
    </div>,
    <div key="3" className="grant-block">
        <div className="grant-img">
            <img src={iconSupport} alt="" />
        </div>
        <div className="grant-text">
            <strong>Бесплатную поддержку преподавателей</strong> на протяжении всего курса и{" "}
            <strong>стимулирующие мероприятия для учеников:</strong> Конкурс спрайтов и Олимпиада «Марсианские игры
            имени Кеплера».
        </div>
    </div>
];

const renderFoundationSlider = (inSlider: boolean, slides: React.ReactNode[]): React.ReactNode => {
    if (inSlider) {
        return (
            <Slider {...sliderSettings} className="slider2">
                {slides}
            </Slider>
        );
    }

    return slides;
};

class MyFirstCode extends Component<RouteComponentProps<Props> & WithTranslation, State> {
    state: State = {
        project: null,
        isLoading: true,
        showSlides: false,
        inputValue: SELECT_OPTIONS[0].value
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }

        this.updateShowSlides();
        window.addEventListener("resize", this.updateShowSlides);
    }

    updateShowSlides = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.setState({
            showSlides: width < 1200
        });
    };

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateShowSlides);
    }

    render() {
        const { isLoading, project, showSlides } = this.state;
        const { t } = this.props;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="myFirstCode">
                    <div className="first_code-slide">
                        <div className="section first_code-head">
                            <div className="section-inner clear">
                                <div className="img-block">
                                    <img src={robo} alt="" />
                                </div>
                                <div className="text-block">
                                    <h1 className="h1">Мой первый код</h1>
                                    <p>
                                        Благотворительный проект, в рамках которого преподаватели получают методический
                                        комплекс и административную поддержку для проведения занятий по программированию
                                        на Scratch для учеников 8-12 лет.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="first_code-about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    <a href="http://iq-charity.com/" target="_blank" rel="noopener noreferrer">
                                        Благотворительный фонд «Возможности интеллекта»
                                    </a>{" "}
                                    реализует проект совместно с Лабораторией программирования
                                    <a href="http://algoritmika.org/" target="_blank" rel="noopener noreferrer">
                                        “Алгоритмика”
                                    </a>
                                    . Мы помогаем школам и центрам дополнительного образования организовать интересное и
                                    качественное обучение основам программирования без инвестиций в методологию. В
                                    течение года мы оказываем преподавателям поддержку и проводим стимулирующие
                                    мероприятия для учеников: Конкурс спрайтов и Олимпиаду «Марсианские игры имени
                                    Кеплера.
                                </div>
                            </div>
                        </div>
                        <div className="first_code-target">
                            <div className="section-inner">
                                <div className="target-img">
                                    <img src={aim} alt="" />
                                </div>
                                <div className="target-text">
                                    <span>Цель проекта – </span>популяризация раннего обучения навыкам программирования
                                    в игровой мультимедийной среде
                                </div>
                            </div>
                        </div>
                        <div className="first_code-course">
                            <div className="section-inner clear">
                                <div className="course-img">
                                    <img src={algoritmika} alt="" />
                                </div>
                                <div className="course-text">
                                    <h2 className="h2">Алгоритмика</h2>
                                    <p className="subhead">Онлайн-платформа для обучения основам программирования</p>
                                    <ul>
                                        <li>Контроль индивидуального прогресса ученика</li>
                                        <li>
                                            Продолжительность: до <strong>академических часов</strong>
                                        </li>
                                        <li>
                                            Количество учеников в группе: <strong>от 4 до 15 человек</strong>
                                        </li>
                                        <li>
                                            Возраст учеников: <strong>8-12 лет</strong>
                                        </li>
                                        <li>
                                            Продолжительность: <strong>до 64 академических часов</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="space_grant">
                            <div className="section-inner">
                                <h2 className="h2">Проект в цифрах за 2019/20 учебный год</h2>
                                <div className="grant-text">
                                    <div className="stats">
                                        <span>88</span> преподавателей
                                    </div>
                                    <div className="stats">
                                        <span>2200+</span> учеников
                                    </div>
                                    <div className="stats">
                                        <span>84</span> учебных заведения
                                    </div>
                                    <div className="stats">
                                        <span>11</span> регионов
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="first_code-grant">
                            <div className="section-inner">
                                <h2 className="h2">
                                    Благотворительный фонд <br />
                                    предоставляет:
                                </h2>
                                <div className="slider-wrapper">
                                    {renderFoundationSlider(showSlides, FIRST_CODE_SLIDES)}
                                </div>
                            </div>
                        </div>
                        <div className="first_code-geography">
                            <div className="select">
                                <h2 className="h2">География проекта</h2>
                                <Select
                                    className="select"
                                    classNamePrefix="select"
                                    defaultValue={SELECT_OPTIONS[0]}
                                    options={SELECT_OPTIONS}
                                    onChange={(value: ValueType<Option>, actionMeta: ActionMeta) => {
                                        if (actionMeta.action === "select-option" && value && !Array.isArray(value)) {
                                            this.setState({
                                                inputValue: (value as Option).value
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="map">
                                <iframe title="map" src={this.state.inputValue} width="100%" height="450px" />
                            </div>
                        </div>
                        <div className="gallery">
                            <div className="section-inner">
                                <h2 className="h2">Галерея</h2>
                                <Gallery images={MyFirstCodeImages} />
                            </div>
                        </div>
                        <div className="first_code-reviews">
                            <div className="section-inner">
                                <h2 className="h2">Отзывы</h2>
                                <div className="slider-wrapper">
                                    <Slider {...sliderSettings} className="sliderReview">
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Какие изменения в учениках Вы заметили за время ведения курса?
                                                    <br />
                                                    Ребята раскрепостились. На первых занятиях видно было, что они не до
                                                    конца осознают, зачем их собрали и что от них хотят. Но со временем
                                                    пришло понимание. Глаза детей "зажглись".
                                                    <br />
                                                    Какие изменения в своей работе Вы заметили после участия в проекте?
                                                    <br />
                                                    Пришло понимание процесса раннего обучения программированию.
                                                    Осознал, как можно выстроить работу с детьми 5-6 класса именно в
                                                    рамках занятий по программированию. Спасибо большое за вашу
                                                    Работу!!! Хотелось бы продолжить сотрудничество с вашим фондом.
                                                </div>
                                                <div className="review__owner">
                                                    Михеев Рудольф, <span>Г. Балтийск, МБОУ СОШ №5</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Какие изменения в учениках Вы заметили за время ведения курса?
                                                    <br />
                                                    Многое узнали, стараются, занимаются с большим интересом.
                                                    <br />
                                                    Какие изменения в своей работе Вы заметили после участия в проекте?
                                                    <br />
                                                    Мне интересно, но требуется время.
                                                    <br />
                                                    Спасибо за уникальную возможность поучаствовать в проекте!
                                                </div>
                                                <div className="review__owner">
                                                    Хорина Светлана, <span>г. Санкт-Петербург, СОШ № 319</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Какие изменения в учениках Вы заметили за время ведения курса?
                                                    <br />
                                                    Умеют организовать свою работу, понимают что и зачем делают. У
                                                    некоторых школьников появились цели, которые связаны с областью
                                                    программирования.
                                                    <br />
                                                    Какие изменения в своей работе Вы заметили после участия в проекте?
                                                    <br />
                                                    Более плотное применение дистанционных технологий, проектная
                                                    деятельность школьников.
                                                </div>
                                                <div className="review__owner">
                                                    Полянская Ирина, <span>г. Оленегорск, СОШ №4</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Какие изменения в учениках Вы заметили за время ведения курса?
                                                    <br />
                                                    Лучше стали понимать в математике и логике, есть явные лидеры.
                                                    <br />
                                                    Какие изменения в своей работе Вы заметили после участия в проекте?
                                                    <br />
                                                    Некоторые элементы этих занятий использую даже со старшеклассниками.
                                                    <br />
                                                    Спасибо за интересный материал, поддержку!
                                                </div>
                                                <div className="review__owner">
                                                    Ключева Елена, <span>г. Санкт-Петербург, ГБОУ лицей №378</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                    <div className="sliderRobo">
                                        <img src={robo2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {project && project.reports && project.reports.length && (
                            <div className="more_news">
                                <h2 className="h2">{t("partners.partnersPage.moreNews")}</h2>
                                <div className="all_reports">
                                    {project.reports.map(report => {
                                        return <ReportPreview report={report} key={report.id} />;
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(MyFirstCode);

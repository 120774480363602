import React from "react";
import { asset } from "../../utils";
import { Link } from "react-router-dom";
import Report from "../../models/Report";
import { SimpleReport } from "../../models/SimpleReport";
import DateBlock from "../DateBlock";

interface Props {
    report: Report | SimpleReport;
}

const ReportPreview: React.FC<Props> = props => {
    const { report } = props;
    let url = "#";

    if (report.linkToProject && report.project) {
        url = "/projects/" + report.project.slug;
    }

    if (!report.linkToProject) {
        url = "/reports/" + report.slug;
    }

    return (
        <Link to={url}>
            <div className="report">
                <div className="report-inner">
                    <div className="report-img">
                        {report.previewPath && <img src={asset(report.previewPath)} alt="" />}
                    </div>
                    <div className="report-text">
                        <div className="report-text_head">
                            <DateBlock
                                flagPath={report.country ? report.country.flagPath : null}
                                publicationDate={report.publicationDate}
                            />
                            <h2>{report.title}</h2>
                        </div>
                        <div className="hidden_text">{report.subtitle}</div>
                        <div className="btn_square">
                            <i />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ReportPreview;

import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-scroll";
import "./assets/style.css";
import foundationSvg from "./assets/img/foundation.svg";
// import belix from "./assets/img/belix.jpg";
// import bykova from "./assets/img/bykova.jpg";
// import jdanovskya from "./assets/img/jdanovskaya.jpg";
// import sevastyanova from "./assets/img/sevastyanova.jpg";
// import galak from "./assets/img/galak.jpg";
// import anuchin from "./assets/img/anuchin.jpg";
import zaretskii from "./assets/img/Zaretskii.jpg";
import zhigalov from "./assets/img/Zhigalov.jpg";
import tychenko from "./assets/img/Tychenko.jpg";
import lopez from "./assets/img/Lopez.jpg";
import iqHeart from "./assets/img/iq-heart.png";
import pdf from "./assets/img/pdf.png";
import report2017 from "./assets/files/report2017.pdf";
import report2018 from "./assets/files/report2018.pdf";
import report2019 from "./assets/files/report2019.pdf";
import report2020 from "./assets/files/report2020.pdf";
import report2021 from "./assets/files/report2021.pdf";
import auditreport2017 from "./assets/files/audit_report_2017.pdf";
import auditreport2018 from "./assets/files/audit_report_2018.pdf";
import auditreport2019 from "./assets/files/audit_report_2019.pdf";
import auditreport2020 from "./assets/files/audit_report_2020.pdf";
import auditreport2021 from "./assets/files/audit_report_2021.pdf";
import report0001 from "./assets/files/report_0001.pdf";
import report0002 from "./assets/files/report_0002.pdf";
// import rekviziti from "./assets/files/rekviziti.pdf";
// import svidetelstvofns from "./assets/files/svidetelstvo_fns.pdf";
// import svidetelstvominyust from "./assets/files/svidetelstvo_minyust.pdf";
import svidetelstvo from "./assets/files/svidetelstvo.pdf";
import ustaviq from "./assets/files/ustav_iq.pdf";
// import vipiska from "./assets/files/vipiska.pdf";
import Layout from "../../components/Layout";
import { RouteComponentProps } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";
import { constants } from "../../config";
import report2022 from "../Partners/PartnersMain/assets/files/report-2022.pdf";

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: true,
    pauseOnHover: true,
    dots: true
};

// const TeamSlider = () => {
//     const { t } = useTranslation();
//     const avatars = [belix, jdanovskya, sevastyanova, galak, bykova, anuchin];
//     const team = Array.from(Array(5), (_, index) => {
//         return {
//             avatar: avatars[index],
//             name: `foundation.team.member${index + 1}.name`,
//             position: `foundation.team.member${index + 1}.position`,
//             scope: `foundation.team.member${index + 1}.scope`
//         };
//     });
//
//     return (
//         <Slider {...sliderSettings}>
//             {team.map((member, index) => {
//                 return (
//                     <div key={index}>
//                         <div className="slide">
//                             <div className="slide_img">
//                                 <img src={member.avatar} alt="" />
//                             </div>
//                             <div className="slide_head">
//                                 {t(member.name)}
//                                 <span>{t(member.position)}</span>
//                             </div>
//                             <div className="slide_text">{t(member.scope)}</div>
//                         </div>
//                     </div>
//                 );
//             })}
//         </Slider>
//     );
// };

const FOUNDATION_SLIDES = [
    <div key="1" className="slide">
        <div className="slide_img">
            <img src={zaretskii} alt="" />
        </div>
        <div className="slide_head">
            Dmitry Zaretsky
            <span>President</span>
        </div>
        <div className="slide_text">
            As the President of the Foundation I feel a special responsibility towards the people in Africa, to whom we
            are giving hope for today’s qualitative changes. I am convinced that active and coordinated efforts of
            Governments, international financial institutions, the business community and non-governmental organizations
            will make it possible to resolve many of the problems, which are difficult to solve on its own. We are
            always glad to cooperate with local foundations and non-governmental organizations, whose activities are
            aimed at the social well-being of the most vulnerable local community of least developed African countries.
        </div>
    </div>,
    <div key="2" className="slide">
        <div className="slide_img">
            <img src={zhigalov} alt="" />
        </div>
        <div className="slide_head">
            Dmitry Zhigalov
            <span>Vice President</span>
        </div>
        <div className="slide_text">
            Our Foundation was established recently, but we have been given a clear set of objectives for achieving the
            main goal - improving the quality of life of the local African community and providing equal opportunities
            for education. I wish our team and partners energy, determination and confidence. I am sure, together we
            will be able to solve all the tasks set before us!
        </div>
    </div>,
    <div key="3" className="slide">
        <div className="slide_img">
            <img src={tychenko} alt="" />
        </div>
        <div className="slide_head">
            Evgeny Tychenko
            <span>Secretary</span>
        </div>
        <div className="slide_text">
            As the Secretary of the Foundation, I communicate a lot with our partners - the local funds. Through
            interaction, we fully realize the needs and see the strong desire to change the situation in the region. Our
            foundation is open to cooperation and we are pleased to support charity projects with a common goal. The
            geographical location should not become an obstacle in joint problem-solving of the African region. With our
            partners’ support we can influence the situation from a different continent.
        </div>
    </div>,
    <div key="4" className="slide">
        <div className="slide_img">
            <img src={lopez} alt="" />
        </div>
        <div className="slide_head">
            Joan Lopez
            <span>Operations Manager - Africa</span>
        </div>
        <div className="slide_text">
            Once the company made the great decision to create a Foundation to help those most in need, I was very clear
            that Africa would be one of our main focuses. I have lived there and visited the continent numerous times. I
            know the terrain and the needs. I have also participated in several projects with local NGOs. I am pretty
            sure that it is they, who know what the needs are and what is required.
        </div>
    </div>
];

const renderFoundationSlider = (inSlider: boolean, slides: React.ReactNode[]): React.ReactNode => {
    if (inSlider) {
        return (
            <Slider {...sliderSettings} className="slider2">
                {slides}
            </Slider>
        );
    }

    return slides;
};

interface State {
    showMoreText: boolean;
    showSlides: boolean;
}

class Foundation extends Component<RouteComponentProps & WithTranslation, State> {
    state: State = {
        showMoreText: false,
        showSlides: true
    };

    handleShowMoreText = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({
            showMoreText: !this.state.showMoreText
        });
    };

    render() {
        const { showSlides } = this.state;
        const { t } = this.props;
        return (
            <Layout>
                {constants.LOCALE === "ru" && (
                    <div className="Foundation">
                        <div className="foundation_top foundation-for-russia">
                            <div className="foundation_inner">
                                <div className="inner-img">
                                    <img src={foundationSvg} alt="" />
                                </div>
                                <div className="inner-text">
                                    <p className="text-about">{t("foundation.about")}</p>
                                    <p className="text-mission">{t("foundation.mission")}</p>
                                    <Link
                                        activeClass="active"
                                        to="reports_block"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={500}
                                        className="button"
                                        id="foundation_top__button"
                                    >
                                        Отчеты
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="foundation-target">
                            <h2 className="h2">{t("foundation.goalsAndTasks")}</h2>
                            <div className="foundation-target_inner">
                                {Array(6)
                                    .fill(true)
                                    .map((_, index) => {
                                        return <div key={index}>{t(`foundation.goals.goal${index + 1}`)}</div>;
                                    })}
                            </div>
                        </div>
                        {/*<div className="team_block">*/}
                        {/*    <h2 className="h2">{t("foundation.outTeam")}</h2>*/}
                        {/*    <div className="slider_wrapper">*/}
                        {/*        <TeamSlider />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="reports_block" id="reports_block">
                            <h2 className="h2">{t("foundation.reports.head")}</h2>
                            <div className="documents">
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2022}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Годовой отчет Фонда за 2022 год</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report0001}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет по форме ОН0001 за 2021г</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report0002}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет по форме ОН0002 за 2021г</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2021}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет о деятельности Фонда за 2021 год</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2020}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет о деятельности Фонда за 2020 год</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2019}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет о деятельности Фонда за 2019 год</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2018}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет о деятельности Фонда за 2018 год</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={report2017}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Отчет о деятельности Фонда за 2017 год</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="reports_block" id="reports_block">
                            <h2 className="h2">{t("foundation.reports.head2")}</h2>
                            <div className="documents">
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={ustaviq}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Устав Фонда</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={svidetelstvo}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Свидетельство</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={auditreport2017}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Аудиторское заключение 2017</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={auditreport2018}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Аудиторское заключение 2018</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={auditreport2019}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Аудиторское заключение 2019</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={auditreport2020}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Аудиторское заключение 2020</span>
                                    </div>
                                </a>
                                <a
                                    className="juristic_documents_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={auditreport2021}
                                    download=""
                                >
                                    <div className="link_img">
                                        <img src={pdf} alt="" />
                                    </div>
                                    <div className="link_text">
                                        <span>Аудиторское заключение 2021</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
                {constants.LOCALE === "en" && (
                    <div className="foundation_en">
                        <div className="foundation_top_en">
                            <div className="foundation_inner">
                                <div className="heart">
                                    <img src={iqHeart} alt="" />
                                </div>
                                <div className="foundation_head">
                                    <h1 className="h1">
                                        Enhancing <span>Smart</span> Future
                                    </h1>
                                    <div className="subhead">
                                        The actions of the Charity are designed solely <br />
                                        and exclusively for the benefit of children.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <article className="article">
                                <h2 className="h2">MISSION STATEMENT</h2>
                                <p>
                                    IQ Option Charity has been created out of an idea, with the mission and conviction
                                    to dedicate a portion of company profits, in addition to money collected by future
                                    donors, to social and charitable causes. The objective of the Charity is to benefit
                                    children around the world who due to reasons beyond their control cannot enjoy the
                                    basic privileges, needs, and comforts that every child should have and be able to
                                    enjoy.
                                </p>
                                <p>
                                    To that end, the company has created a team of people and special equipment to
                                    further our cause: qualified professionals with the education, knowledge, and
                                    experience necessary to carry out different projects focused on the development and
                                    implementation of solutions to different problems and situations in areas of the
                                    greatest need.
                                </p>
                                <p>
                                    In the most direct way possible and without intermediaries, IQ Option Charity
                                    identifies specific people or small organizations to provide them with the necessary
                                    means for them to carry out the projects they believe are necessary to further the
                                    Charity’s objective. In no case are projects managed in a unilateral way; those who
                                    are familiar with each project and its particular situation will advise the actions
                                    of the foundation.
                                </p>
                                <div className={"hidden_block " + (this.state.showMoreText ? "active" : "")}>
                                    <p>
                                        Our desire is none other than to provide everything that we can (including
                                        money, infrastructure, knowledge, people, and technology) and implement it in
                                        such a way that beneficiaries will be able to maintain, manage, and sustain what
                                        has been contributed.
                                    </p>
                                    <p>
                                        The actions of the Charity are designed solely and exclusively for the benefit
                                        of children. Broad in scope, they cover the purchase and supply of school
                                        materials, medicines or clothing, as well as the construction and remodeling of
                                        schools, orphanages, hospitals and similar facilities.
                                    </p>
                                    <p>
                                        The ultimate goal is to ensure that the small local foundations that benefit
                                        from the Charity’s work will actively participate in the projects and then
                                        manage them independently with the assistance and supervision of our experts.
                                        Beneficiaries will be provided with the necessary tools and knowledge as well as
                                        the necessary technology or services to allow them to monitor and expand this
                                        work and go on to create their own projects. Websites, training, tools, and
                                        materials will be developed or implemented collectively between the Charity and
                                        the relevant local entity.
                                    </p>
                                </div>
                                <div className="text-center">
                                    <a
                                        className={"show_more " + (this.state.showMoreText ? "opened" : "")}
                                        href="/"
                                        onClick={e => {
                                            this.handleShowMoreText(e);
                                        }}
                                    >
                                        <span>Read more</span> <i />
                                    </a>
                                </div>
                            </article>
                        </div>
                        <div className="team_block">
                            <h2 className="h2">MEET THE TEAM</h2>
                            <div className="slider_wrapper">
                                {renderFoundationSlider(showSlides, FOUNDATION_SLIDES)}
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

export default withTranslation()(Foundation);

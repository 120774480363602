import React, { Component } from "react";
import Layout from "../../../components/Layout";
import "./assets/style.css";
import { RouteComponentProps } from "react-router";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import { getOneProject } from "../../../api";
import hand from "./assets/img/hand2.png";
import iconEn from "./assets/img/icon-en.svg";
import iconHead from "./assets/img/icon-head.svg";
import iconLocation from "./assets/img/icon-location.svg";
import iconMsg from "./assets/img/icon-msg.svg";
import iconRocket from "./assets/img/icon-rocket.svg";
import iconRuby from "./assets/img/icon-ruby.svg";
import gal1 from "./assets/img/gallery/img7.jpg";
import gal2 from "./assets/img/gallery/img8.jpg";
import gal3 from "./assets/img/gallery/img9.jpg";
import gal4 from "./assets/img/gallery/img10.jpg";
import gal5 from "./assets/img/gallery/img11.jpg";
import gal6 from "./assets/img/gallery/img12.jpg";
import Gallery from "../../../components/Gallery";
import Slider from "react-slick";
import Page404 from "../../../components/Page404";
import ReportPreview from "../../../components/ReportPreview";
import { withTranslation, WithTranslation } from "react-i18next";

interface State {
    isLoading: boolean;
    project: Project | null;
    showSlides: boolean;
}

interface Props {
    slug: string;
}

const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    dots: true
};

const MakeItImages = [{ src: gal1 }, { src: gal2 }, { src: gal3 }, { src: gal4 }, { src: gal5 }, { src: gal6 }];

const FOUNDATION_SLIDES = [
    <div className="grant-block" key="1">
        <div className="grant-img">
            <img src={iconMsg} alt="" />
        </div>
        <div className="grant-text">Мастер-классы по программированию на Python</div>
    </div>,
    <div className="grant-block" key="2">
        <div className="grant-img">
            <img src={iconRuby} alt="" />
        </div>
        <div className="grant-text">Мастер-классы по программированию на Python</div>
    </div>,
    <div className="grant-block" key="3">
        <div className="grant-img">
            <img src={iconHead} alt="" />
        </div>
        <div className="grant-text">Профориентационные тренинги</div>
    </div>,
    <div className="grant-block" key="4">
        <div className="grant-img">
            <img src={iconLocation} alt="" />
        </div>
        <div className="grant-text">Экскурсии в IT-компании</div>
    </div>,
    <div className="grant-block" key="5">
        <div className="grant-img">
            <img src={iconEn} alt="" />
        </div>
        <div className="grant-text">Мастер-классы по IT-английскому</div>
    </div>,
    <div className="grant-block" key="6">
        <div className="grant-img">
            <img src={iconRocket} alt="" />
        </div>
        <div className="grant-text">Интенсивы в каникулы</div>
    </div>
];

const renderFoundationSlider = (inSlider: boolean, slides: React.ReactNode[]): React.ReactNode => {
    if (inSlider) {
        return (
            <Slider {...sliderSettings} className="slider2">
                {slides}
            </Slider>
        );
    }

    return slides;
};

class MakeIt extends Component<RouteComponentProps<Props> & WithTranslation, State> {
    state: State = {
        project: null,
        isLoading: true,
        showSlides: false
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }

        this.updateShowSlides();
        window.addEventListener("resize", this.updateShowSlides);
    }

    updateShowSlides = () => {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
        this.setState({
            showSlides: width < 1200
        });
    };

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateShowSlides);
    }

    render() {
        const { isLoading, project, showSlides } = this.state;
        const { t } = this.props;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="makeIt">
                    <div className="make_it-slide">
                        <div className="section-inner clear">
                            <div className="img-block">
                                <img src={hand} alt="" />
                            </div>
                            <div className="text-block">
                                <div className="h1">Make IT!</div>
                                <p>
                                    Проект Благотворительного фонда развития образования «Возможности интеллекта» по
                                    профориентации подростков в IT-сфере.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="contentWrapper">
                        <div className="make_it-about about">
                            <div className="section-inner">
                                <div className="about__text">
                                    Цель проекта - популяризация раннего обучения навыкам программирования в игровой
                                    мультимедийной среде
                                </div>
                            </div>
                        </div>
                        <div className="project-in-numbers">
                            <div className="section-inner">
                                <h2 className="h2">Проект в цифрах за 2018/19 учебный год</h2>
                                <div className="grant-text">
                                    <div className="stats">
                                        <span>200+</span> участников
                                    </div>
                                    <div className="stats">
                                        <span>20+</span> экспертов
                                    </div>
                                    <div className="stats">
                                        <span>15+</span> мероприятий
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="make_it-grant">
                            <div className="section-inner">
                                <h2 className="h2">Благотворительный фонд организует</h2>
                                <div className="slider-wrapper">
                                    {renderFoundationSlider(showSlides, FOUNDATION_SLIDES)}
                                </div>
                            </div>
                        </div>
                        <div className="gallery">
                            <div className="section-inner">
                                <h2 className="h2">Галерея</h2>
                                <Gallery images={MakeItImages} />
                            </div>
                        </div>
                        <div className="make_it-reviews">
                            <div className="section-inner">
                                <h2 className="h2">Отзывы</h2>
                                <div className="slider-wrapper">
                                    <Slider {...sliderSettings} className="sliderReview">
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    БФ «Возможности интеллекта» развеял все мои сомнения насчет
                                                    IT-сферы. Теперь я знаю, какую профессию я выберу в дальнейшем, что
                                                    я сейчас уже буду изучать. Хотелось бы сказать всем больше спасибо!
                                                    Абсолютно
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    У меня появилось желание получать знания, я открыл для себя новые
                                                    грани IT-сферы, познакомился с новыми людьми.
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Развеялись мои сомнения по поводу программистов, о том, что они все
                                                    в толстых очках и что это скучные люди. Оказывается здесь такая
                                                    атмосфера! Я догадывалась, конечно, что это классно и здорово, я
                                                    знала, что я хочу работать в этой сфере!
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review">
                                                <div className="review__text">
                                                    Самое главное и самое классное не только для IT, но и для жизни в
                                                    целом то, что сюда приходит много людей хороших, интересных
                                                    индивидуальностей, с которыми становишься друзьями.
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        {project && project.reports && project.reports.length && (
                            <div className="more_news">
                                <h2 className="h2">{t("partners.partnersPage.moreNews")}</h2>
                                <div className="all_reports">
                                    {project.reports.map(report => {
                                        return <ReportPreview report={report} key={report.id} />;
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(MakeIt);

import styled from "styled-components";

export const HeaderElem = styled.div`
    .menu {
        float: right;
        padding-right: 40px;
        min-width: 300px;
    }
    .hamburger {
        width: 22px;
        height: 20px;
        position: fixed;
        z-index: 103;
        transition: all 0.4s ease-out;
        display: none;
        background: none;
        border: none;
        cursor: pointer;
        .line {
            display: block;
            background: #2a2a2a;
            width: 22px;
            height: 4px;
            position: absolute;
            left: 0;
            border-radius: 0;
            transition: all 0.4s ease-out;
            &.line-1 {
                top: 0;
            }
            &.line-2 {
                top: 8px;
            }
            &.line-3 {
                top: 16px;
            }
        }
        &:hover {
            .line {
                &.line-1 {
                    transform: translateY(-2px);
                }
                &.line-3 {
                    transform: translateY(2px);
                }
            }
        }
        &.active {
            .line {
                background: #000;
                transition: all 0.4s ease-out;
                &.line-1 {
                    transform: translateY(8px) translateX(0) rotate(45deg);
                }
                &.line-2 {
                    opacity: 0;
                }
                &.line-3 {
                    transform: translateY(-8px) translateX(0) rotate(-45deg);
                }
            }
        }
    }
    .hidden_body_cover {
        background: rgba(0, 0, 0, 0);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 102;
        transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
        transition-duration: 0.5s;
        max-height: 0;
        &.active {
            max-height: 10000px;
            background: rgba(0, 0, 0, 0.5);
            transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
            transition-duration: 0.5s;
        }
    }
    .hidden_menu {
        position: fixed;
        width: 100%;
        height: 100%;
        right: -110%;
        top: 0;
        z-index: 102;
        .hidden_menu_inner {
            background: #fff;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            min-height: 100%;
            z-index: 103;
            transform-origin: center center;
            transition: all 0.25s ease-in-out 0s;
            -webkit-transform-origin: center center;
            -webkit-transition: all 0.25s ease-in-out 0s;
        }
        .vert_menu {
            list-style: none;
            position: absolute;
            top: 50%;
            margin-top: -169px;
            min-width: 200px;
            right: 200px;
            li {
                a {
                    display: block;
                    position: relative;
                    z-index: 2;
                    color: #000000;
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 30px;
                    line-height: 48px;
                    text-transform: uppercase;
                    opacity: 0;
                    transform: translateX(85px);
                    transition: all 0.15s ease-out 800ms;
                }
                &:nth-of-type(2) {
                    a {
                        transition: all 0.19s ease-out 800ms;
                    }
                }
                &:nth-of-type(3) {
                    a {
                        transition: all 0.23s ease-out 800ms;
                    }
                }
                &:nth-of-type(4) {
                    a {
                        transition: all 0.27s ease-out 800ms;
                    }
                }
                &:nth-of-type(5) {
                    a {
                        transition: all 0.31s ease-out 800ms;
                    }
                }
                &:nth-of-type(6) {
                    a {
                        transition: all 0.35s ease-out 800ms;
                    }
                }
                &:nth-of-type(7) {
                    a {
                        transition: all 0.2s ease-out 800ms;
                    }
                }
                &:nth-of-type(8) {
                    a {
                        transition: all 0.2s ease-out 800ms;
                    }
                }
            }
        }
        &.active {
            animation: animIn 700ms linear;
            transform: translateX(-110%);
            .vert_menu {
                opacity: 0.9;
                transform: translateX(0) translateY(0);
                li a {
                    opacity: 0.9;
                    transform: translateY(0);
                }
            }
        }
        &.animOut {
            right: -110%;
            animation: animOut 500ms linear, moveBack 0s linear;
        }
    }
    @keyframes animIn {
        from {
            transform: skew(0deg, 0deg) translateX(110%);
        }
        80% {
            transform: skew(20deg, 0deg) translateX(-55%);
        }
        to {
            transform: skew(0deg, 0deg) translateX(-110%);
        }
    }
    @keyframes animOut {
        from {
            transform: skew(0deg, 0deg) translateX(-110%);
        }
        50% {
            transform: skew(20deg, 0deg) translateX(-165%);
        }
        to {
            transform: skew(0deg, 0deg) translateX(-220%);
        }
    }
    @keyframes moveBack {
        from {
            opacity: 0;
        }
        to {
            transform: skew(0deg, 0deg) translateX(110%);
        }
    }
    @media (max-width: 1024px) {
        .hamburger {
            display: block;
            top: 22px;
            right: 25px;
        }
    }
    @media (max-width: 767px) {
        .hidden_menu {
            .vert_menu {
                position: relative;
                right: 0;
                top: 0;
                width: 200px;
                margin: 80px auto 0;
            }
        }
    }
`;

import styled from "styled-components";
import Country from "../../models/Country";
import { asset } from "../../utils";

interface Props {
    className?: string;
    title: string;
    subtitle: string;
    buttonText: string;
    backgroundPath: string | null;
    mobileBackgroundPath: string | null;
    link: string;
    titleColor: string;
    subtitleColor: string;
    buttonTextColor: string;
    buttonBodyColor: string;
    country: Country | null;
    startedAt: string;
}

export const MainScreenWrapper = styled.div<Props>`
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    overflow: hidden;
    color: #000;
    background: ${({ backgroundPath }: Props) => (backgroundPath ? `url(${asset(backgroundPath)})` : "#fff")} center
        no-repeat;
    background-size: cover;
    .intro {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        .videoBg {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            video {
                right: 0;
                bottom: 0;
                min-width: 100%;
                min-height: 100%;
                position: relative;
            }
        }
        .mobileBg {
            display: none;
        }
        .main_text {
            width: 33%;
            max-width: 450px;
            z-index: 100;
            text-align: left;
            color: #000;
            margin-left: 155px;
            .date_block {
            }
            .h1 {
                font-size: 36px;
                line-height: 43px;
                color: ${props => props.titleColor};
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 600;
                padding-top: 13px;
                padding-bottom: 22px;
            }
            .subhead {
                font-size: 20px;
                line-height: 30px;
                color: ${props => props.subtitleColor};
                width: 100%;
                font-weight: 400;
                margin-left: 0 !important;
                margin-right: 0 !important;
                position: relative;
                padding-bottom: 20px;
                margin-bottom: 33px;
            }
            .button {
                font-size: 18px;
                line-height: 26px;
                height: 60px;
                display: inline-block;
                padding: 17px 20px;
                box-sizing: border-box;
                width: 100%;
                max-width: 285px;
                background: ${props => props.buttonBodyColor};
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 600;
                color: ${props => props.buttonTextColor};
                text-align: center;
                transition: opacity 0.5s ease-out;
                cursor: pointer;
                position: relative;
                &:after {
                    display: block;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: transparent;
                    transition: background-color 0.5s ease-out;
                    position: absolute;
                    content: "";
                }
                &:hover {
                    &:after {
                        background: rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
    .formBtn {
        font-size: 18px;
        line-height: 20px;
        height: 40px;
        display: inline-block;
        box-sizing: border-box;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        color: ${props => props.buttonTextColor};
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 100px;
        right: 40px;
        padding: 10px 20px;
        background: ${props => props.buttonBodyColor};
        transition: opacity 0.5s ease-out 0s;
        z-index: 2;
        border: none;
        &:after {
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            content: "";
            background: transparent;
            transition: background-color 0.5s ease-out 0s;
        }
        &:hover {
            &:after {
                background: rgba(0, 0, 0, 0.1);
            }
        }
        @media (max-width: 767px) {
            top: 20px;
            right: 20px;
        }
    }
    @media (max-width: 1024px) {
        .intro {
            .main_text {
                width: 50%;
                padding-left: 40px;
                margin-left: 0;
                box-sizing: border-box;
            }
        }
    }
    @media (max-width: 767px) {
        height: auto;
        padding-top: 0;
        .intro {
            height: auto;
            padding-top: 70px;
            padding-bottom: 70px;
            .main_text {
                width: 50%;
                .h1 {
                    font-size: 25px;
                    line-height: 1.2;
                }
                .subhead {
                    font-size: 17px;
                    line-height: 1.2;
                    margin-bottom: 15px;
                }
            }
        }
    }
    @media (max-width: 500px) {
        background: none;
        .intro {
            padding: 0 0 20px 0;
            flex-direction: column;
            .videoBg {
                display: none;
            }
            .mobileBg {
                display: block;
                width: 100%;
                height: 100vw;
                background: url(${props => asset(props.mobileBackgroundPath!)}) center no-repeat;
                background-size: 90% auto;
            }
            .main_text {
                width: 100%;
                padding: 0 20px;
                display: block;
                .date_block {
                    div {
                        color: #000;
                    }
                }
                .h1 {
                    color: #000;
                }
                .subhead {
                    color: #000;
                }
                .button {
                    width: 100%;
                    max-width: 100%;
                    background: #ff9c00;
                    color: #fff;
                }
            }
        }
    }
`;

import React from "react";
import { PrivacyBlock } from "./Atoms";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ScrollToTop from "../../components/ScrollToTop";
const PrivacyPolicy: React.FC = () => {
    return (
        <Layout>
            <ScrollToTop />
            <PrivacyBlock>
                <div className="container">
                    <h1 className="h1">Privacy Policy</h1>
                    <div className="terms_head">Welcome to the IQOPTION CHARITABLE FOUNDATION's privacy policy.</div>
                    <div className="terms_text">
                        <p>
                            The IQOPTION Charitable Foundation respects your privacy and is committed to protecting your
                            personal data. This privacy policy aims to give you information on how the IQPTION
                            Charitable Foundation collects and processes your personal data through your use of this
                            Site (regardless of where you visit it from), including any data you may provide through
                            this Site when you sign up to our newsletter and/or make a donation and/or make a request
                            for a grant.
                        </p>
                        <p>
                            This privacy policy is provided in a layered format so you can click through to the specific
                            areas set out below.
                        </p>
                        <p>
                            In this privacy policy, unless the context otherwise requires, expressions defined herein
                            shall have the meaning ascribed to them in the{" "}
                            <Link target="_blank" to="./terms&conditions">
                                Terms & Conditions
                            </Link>
                            .
                        </p>
                    </div>
                    <div className="terms_how-to-use">
                        Click on the links below to go straight to more information on each area:
                    </div>
                    <Accordion allowZeroExpanded={true} preExpanded={[0]}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>1. Important information and who we are</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    <strong>Purpose of this privacy policy</strong>
                                </p>
                                <p>
                                    IQOPTION takes all reasonable and appropriate measures to implement advanced data
                                    protection policies and procedures and to update them from time to time for the
                                    purpose of safeguarding your data. Your data is protected by the legal,
                                    administrative and technical measures that we take to ensure the privacy, integrity
                                    and accessibility of data. To prevent security incidents with your data, we use a
                                    mixed organizational and technical approach based on the results of annual risk
                                    assessment.
                                </p>
                                <p>
                                    IQOPTION shall not divulge any private information of its users unless you approve
                                    such disclosure or unless such disclosure is required under applicable law or is
                                    required in order to verify your identity or it is required for IQOPTION to perform
                                    its obligations under any agreement concluded with you. Your information is
                                    processed only by the employees of IQOPTION and/or employees and/or trusted
                                    associates and third parties. All such information shall be stored on electronic and
                                    physical storage media according to applicable law.
                                </p>
                                <p>
                                    This Site is not intended for children and we do not knowingly collect data relating
                                    to children.
                                </p>
                                <p>
                                    It is important that you read this privacy policy together with any other privacy
                                    policy and/or notice and/or fair processing policy as well as the Terms & Conditions
                                    we may provide on specific occasions when we are collecting or processing personal
                                    data about you so that you are fully aware of how and why we are using your data.
                                    This privacy policy supplements other notices and privacy policies and is not
                                    intended to override them.
                                </p>
                                <p>
                                    <strong>Controller</strong>
                                </p>
                                <p>
                                    IQOPTION, a charitable foundation existing under the laws of the Republic of Cyprus
                                    with foundation number ΛΕΜ/Ι/2 and governed by the laws of the Republic of Cyprus
                                    and the provisions of the founding act which was submitted to the Registrar of
                                    Societies and Institutions on 18/4/2018, is the controller and responsible for your
                                    personal data (collectively referred to as “IQOPTON”, the "Foundation", "Charity"
                                    "we", "us" or "our" in this Privacy Policy).
                                </p>
                                <p>
                                    We have appointed a data privacy manager who is responsible for overseeing questions
                                    in relation to this privacy policy. If you have any questions about this privacy
                                    policy, including any requests to exercise [your legal rights], please contact the
                                    data privacy manager using the details set out below.
                                </p>
                                <p>
                                    <strong>Contact details</strong>
                                </p>
                                <p>
                                    If you have any questions about this privacy policy or our privacy practices, please
                                    contact our data privacy manager in the following ways:
                                </p>
                                <p>Email address: info@iqcharity.org</p>
                                <p>
                                    Postal address: 33 Agiou Athanasiou, YIANNIS NICOLAIDES BUSINESS CT, Agios
                                    Athanasios, 4102, Limassol, Cyprus
                                </p>
                                <p>Telephone number: (357) 25 030 677</p>
                                <p>
                                    We do our best to ensure that we protect your data, keep you informed about how we
                                    process your data and comply with the applicable data protection regulation. In case
                                    you are not satisfied with the processing and protection of your data or the
                                    information you have received from us, then we urge you to inform us in order for us
                                    to improve. Please also do not hesitate to contact us, if you want to make use of
                                    your rights.{" "}
                                </p>
                                <p>
                                    If you want to exercise any of your rights mentioned above and/or obtain more
                                    information regarding your rights and/or our policies and procedures please contact
                                    us through the points of contact listed in the section CONTACT DETAILS above. Please
                                    also provide us with the relevant information to take care of your request,
                                    including your full name and email address so that we can identify you. We will
                                    respond to your request without undue delay.
                                </p>
                                <p>
                                    <strong>No fee usually required</strong>
                                </p>
                                <p>
                                    You will not have to pay a fee to access your personal data (or to exercise any of
                                    the other rights). However, we may charge a reasonable fee if your request is
                                    clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply
                                    with your request in these circumstances.
                                </p>
                                <p>
                                    <strong>What we may need from you</strong>
                                </p>
                                <p>
                                    We may need to request specific information from you to help us confirm your
                                    identity and ensure your right to access your personal data (or to exercise any of
                                    your other rights). This is a security measure to ensure that personal data is not
                                    disclosed to any person who has no right to receive it. We may also contact you to
                                    ask you for further information in relation to your request to speed up our
                                    response.
                                </p>
                                <p>
                                    <strong>Time limit to respond</strong>
                                </p>
                                <p>
                                    We try to respond to all legitimate requests within one month. Occasionally it could
                                    take us longer than a month if your request is particularly complex or you have made
                                    a number of requests. In such a case, we will notify you and keep you updated.
                                </p>
                                <p>
                                    If following your request to us we are unable to provide you with a satisfactory
                                    answer, then you may lodge a complaint with the local data protection supervisory
                                    authority. The data protection supervisory authority in Cyprus is:{" "}
                                </p>
                                <p>
                                    Commissioner for Data Protection P.O. Box 23378, <br />
                                    1682 Nicosia
                                    <br />
                                    Cyprus <br />
                                    www.dataprotection.gov.cy
                                </p>
                                <p>
                                    We would, however, appreciate the chance to deal with your concerns before you
                                    approach the local data protection supervisory authority so please contact us in the
                                    first instance.{" "}
                                </p>
                                <p>
                                    <strong>Changes to the privacy policy and your duty to inform us of changes</strong>
                                </p>
                                <p>
                                    We keep our privacy policy under regular review. It is important that the personal
                                    data we hold about you is accurate and current. Please keep us informed if your
                                    personal data changes during your relationship with us.
                                </p>
                                <p>
                                    <strong>Third-party links</strong>
                                </p>
                                <p>
                                    This Site may include links to third-party websites, plug-ins and applications.
                                    Clicking on those links or enabling those connections may allow third parties to
                                    collect or share data about you. We do not control these third-party websites and
                                    are not responsible for their privacy statements. When you leave our Site, we
                                    encourage you to read the privacy policy of every website you visit.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>2. The data we collect about you</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Personal data, or personal information, means any information about an individual
                                    from which that person can be identified. It does not include data where the
                                    identity has been removed (anonymous data).
                                </p>
                                <p>
                                    We may collect, use, store and transfer different kinds of personal data about you
                                    which we have grouped together as follows:
                                </p>
                                <ul className="list">
                                    <li>
                                        <strong>Identity Data</strong> includes first name, last name, and patronymic
                                        (if available), date of birth gender, passport, ID or driver’s license number,
                                        and copy of photo.
                                    </li>
                                    <li>
                                        <strong>Contact Data</strong> includes billing address, email address and
                                        telephone numbers.
                                    </li>
                                    <li>
                                        <strong>Financial Data</strong> includes bank account and payment card details.
                                    </li>
                                    <li>
                                        <strong>Technical Data</strong> includes internet protocol (IP) address, your
                                        login data, browser type and version, time zone setting and location, browser
                                        plug-in type and versions, operating system and platform, and other technologies
                                        on the devices you use to access the Site and use of cookies stored on your
                                        device.
                                    </li>
                                    <li>
                                        <strong>Profile Data</strong> includes your account details, username and
                                        password, transactions made by you, your interests, preferences, feedback and
                                        survey responses provided in the course of the use of our services. The service
                                        data may be processed for the purposes of operating our Site, providing our
                                        services to you, ensuring the security of the Site, maintaining back-ups of our
                                        databases and communicating with you.
                                    </li>
                                    <li>
                                        <strong>Usage Data</strong> includes information about how you use the Site.
                                    </li>
                                    <li>
                                        <strong>Marketing and Communication Data</strong> includes your preferences in
                                        receiving marketing from us and your communication preferences.
                                    </li>
                                    <li>
                                        <strong>Banking Data</strong> includes details of number of payment wallets
                                        and/or information of your bank card, including information of the issuing bank,
                                        card number, cardholder name, card expiration date, payment system, card
                                        validation code (CVV2 / CVC2), and photos of both sides of the bank card.
                                    </li>
                                    <li>
                                        <strong>Data in KYC</strong> (Know Your Customer) includes identity document
                                        information, including copies of recent dated Utility Bills, Identity Card,
                                        Passport, and/or Driver’s License.
                                    </li>
                                    <li>
                                        <strong>Location Data</strong> includes details on your actual location when
                                        interacting with our Site (for example, a set of parameters that determine
                                        regional settings of your interface, namely residency country, time <br />
                                        (the above collectively referred to as <strong>“Personal Data”</strong>)
                                    </li>
                                </ul>
                                <p>
                                    We also collect, use and share Aggregated Data such as statistical or demographic
                                    data for any purpose. <strong>Aggregated Data</strong> could be derived from your
                                    personal data but is not considered personal data in law as this data will not
                                    directly or indirectly reveal your identity. For example, we may aggregate your
                                    Usage Data to calculate the percentage of users accessing a specific website
                                    feature. However, if we combine or connect Aggregated Data with your personal data
                                    so that it can directly or indirectly identify you, we treat the combined data as
                                    personal data which will be used in accordance with this privacy policy.
                                </p>
                                <p>
                                    We do not collect any <strong>Special Categories of Personal Data</strong> about you
                                    (this includes details about your race or ethnicity, religious or philosophical
                                    beliefs, sex life, sexual orientation, political opinions, trade union membership,
                                    information about your health, and genetic and biometric data). Nor do we collect
                                    any information about criminal convictions and offences.
                                </p>
                                <p>
                                    Processing of your data is carried out by IQOPTION following the principles of
                                    lawfulness, fairness, transparency, and always adhering to the intended purpose of
                                    data processing, the principle of data minimization, accuracy, limited data storage,
                                    data integrity, confidentiality and accountability.
                                </p>
                                <p>
                                    <strong>If you fail to provide personal data</strong>
                                </p>
                                <p>
                                    Where we need to collect personal data by law, or under the terms of a contract we
                                    have with you, and you fail to provide that data when requested, we may not be able
                                    to perform the contract we have or are trying to enter into with you (for example,
                                    to provide you with a grant). In this case, we may have to cancel a product or
                                    service you have with us but we will notify you if this is the case at the time.{" "}
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>3. How is your personal data collected?</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>We use different methods to collect data from and about you including through:</p>
                                <ul className="list">
                                    <li>
                                        <strong>Direct interactions.</strong> You may give us your Identity, Contact and
                                        Financial Data]by filling in forms or by corresponding with us by post, phone,
                                        email or otherwise. This includes personal data you provide when you :
                                    </li>
                                    <li>apply for a grant;</li>
                                    <li>create an account on our Site;</li>
                                    <li>subscribe to our publications;</li>
                                    <li>request marketing to be sent to you;</li>
                                    <li>enter a competition, promotion or survey; or</li>
                                    <li>give us feedback or contact us.</li>
                                    <li>
                                        <strong>Automated technologiesor interactions.</strong> As you interact with our
                                        Site, we may automatically collect Technical Data about your equipment, browsing
                                        actions and patterns. We collect this personal data by using cookies and other
                                        similar technologies. Please see our cookie policy [LINK] for further details.
                                    </li>
                                    <li>
                                        <strong>Third parties or publicly available sources.</strong> We may receive
                                        personal data about you from various third parties and publicly available
                                        sources.
                                    </li>
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>4. How we use your personal data</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We will only use your personal data in accordance with the provisions of the
                                    applicable legislation. Most commonly, we will process your personal data in the
                                    following circumstances:
                                </p>
                                <ul className="list">
                                    <li>
                                        Where we need to perform the contract, we are about to enter into, or have
                                        entered into with you.
                                    </li>
                                    <li>
                                        Where it is necessary for our legitimate interests (or those of a third party)
                                        and your interests and fundamental rights do not override those interests.
                                    </li>
                                    <li>Where we need to comply with a legal obligation.</li>
                                </ul>
                                <p>
                                    You are responsible for updating any data provided to us in case of any change.
                                    Although we will strive to keep your data up to date and review and inspect any
                                    information provided by you, we may not be able to always do so without your help.
                                    You acknowledge that IQOPTION holds neither commitment nor responsibility to you due
                                    to any aforesaid review or inspection of information.
                                </p>
                                <p>
                                    <strong>Purposes for which we will use your personal data</strong>
                                </p>
                                <p>We may use your personal data to: </p>
                                <p>a) send you information you have expressly chosen to receive [with your consent];</p>
                                <p>
                                    b) review and respond to proposal documents, feedback, comments, photos, videos, or
                                    other information you submit via online portals, electronic forms, surveys, or
                                    interactive portions of our sites [for our legitimate interests and/or to perform a
                                    contract with you];
                                </p>
                                <p>
                                    c) administer and inform our program strategies and charitable activities [for our
                                    legitimate interest];{" "}
                                </p>
                                <p>
                                    d) administer, safeguard, and improve our sites, systems, facilities, events and
                                    other business operations [for our legitimate interests];{" "}
                                </p>
                                <p>e) protect our rights and the safety of others [for our legitimate interests]; </p>
                                <p>
                                    f) contribute to our archive of information in the public interest [for our
                                    legitimate interests]; and/or comply with applicable law, court order, subpoena, or
                                    legal process served on us [to comply with legal obligations].
                                </p>
                                <p>
                                    <strong>Marketing</strong>
                                </p>
                                <p>
                                    If you are registered with{" "}
                                    <Link target="_blank" to="/">
                                        http://iqcharity.org
                                    </Link>
                                    , we may collect and process your personal data to communicate with you in case of
                                    support and/or sending newsletters, push-messages and calls to keep you in touch
                                    with our new features, news and events and the efficient provision of the full scope
                                    of our services. We may also use your data to send you marketing information
                                    regarding our services that we believe may be of interest to you via email.
                                </p>
                                <p>
                                    We will not provide your personal data to any third party for marketing purposes nor
                                    will we communicate with you to provide third party marketing material without your
                                    consent.
                                </p>
                                <p>
                                    <strong>Opting out</strong>
                                </p>
                                <p>
                                    You can ask us to stop sending you marketing messages at any time by logging into
                                    the Site and checking or unchecking relevant boxes to adjust your marketing
                                    preferences and/or by following the opt-out links on any marketing message sent to
                                    you and/or by contacting us at any time using the Contact Details above.
                                </p>
                                <p>
                                    <strong>Cookies</strong>
                                </p>
                                <p>
                                    A cookie is a small amount of data that often includes a unique identifier that is
                                    sent to your computer or device browser from a website's computer and is stored on
                                    your device's hard drive for tracking site usage. A Site may send its own cookie to
                                    your browser if your browser's preferences allow it, but, to protect your privacy,
                                    your browser only permits a website to access the cookies it has already sent to
                                    you, not the cookies sent to you by other websites. Many websites do this whenever a
                                    user visits their website in order to track online traffic flows. When you visit our
                                    Site, our system automatically collects information about your visit, such as your
                                    Technical Data, including inter alia to, your browser type, your IP address and the
                                    referring website.
                                </p>
                                <p>
                                    Cookies stored may determine the path you took on our site and used to anonymously
                                    identify repeat users of the Site and what pages were most popular for you. However,
                                    the Foundation protects your privacy by not storing your names, personal details,
                                    emails, etc. Using cookies is an industry standard and is currently used by most
                                    major websites. Stored cookies allow the Site to be more user-friendly and efficient
                                    for you by allowing IQOPTION to learn what information is more valued by you versus
                                    what isn’t. You can set your browser not to save any cookies of this website and you
                                    may also delete cookies automatically or manually. However, please note that by
                                    doing so you may not be able to use all the provided functions of our website in
                                    full.
                                </p>
                                <p>Please see our cookies policy [LINK] for further details. </p>
                                <p>
                                    <strong>Change of purpose</strong>
                                </p>
                                <p>
                                    We will only use your personal data for the purposes for which we collected it,
                                    unless we reasonably consider that we need to use it for another reason and that
                                    reason is compatible with the original purpose. If you wish to get an explanation as
                                    to how the processing for the new purpose is compatible with the original purpose,
                                    please contact us.
                                </p>
                                <p>
                                    If we need to use your personal data for an unrelated purpose, we will notify you
                                    and we will explain the legal basis which allows us to do so.
                                </p>
                                <p>
                                    Please note that we may process your personal data without your knowledge or
                                    consent, in compliance with the above rules, where this is required or permitted by
                                    law.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>5. Disclosures of your personal data</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We may share your data with the parties set out below for the purposes set out
                                    above.
                                </p>
                                <p>
                                    We require any third parties processing data on our behalf to respect your data and
                                    to treat it in accordance with the provisions of the GDPR and applicable local
                                    legislation as amended from time to time. We take all reasonable steps so that our
                                    third-party service providers do not use your personal data for their own purposes
                                    and only permit them to process your data for specific purposes and in accordance
                                    with our instructions.
                                </p>
                                <p>
                                    We may disclose your personal data to any member of our group of companies (this
                                    means any entity owned by us, our holding or ultimate holding entity and any of its
                                    subsidiaries) insofar as reasonably necessary for the purposes and on the legal
                                    basis set out in this Privacy Policy.
                                </p>
                                <p>
                                    With regard to the transfer of data to recipients outside the IQOPTION group, we
                                    note that we strive to maintain discretion with respect to subscribers and/or
                                    partners related matters and assessments of which we acquire knowledge. We may
                                    disclose data that concerns you only if (i) we are legally required to do so; (ii)
                                    if required when you expressly order us to process a transaction or any other
                                    service and (iii) it is required for the provision of our services under our
                                    contractual relationship and/or (iv) protection of our legitimate interests, in
                                    accordance with the provisions of the GDPR and applicable local legislation as
                                    amended from time to time.
                                </p>
                                <p>
                                    <strong>External Third Parties</strong>. Your data is shared with third party
                                    organisations/entities including but not limited to:
                                </p>
                                <p>
                                    <strong>A) Service Providers</strong>. We may share your data with our trusted third
                                    party service providers, who, on our behalf, operate, maintain, and/or support our
                                    IT systems and IT infrastructure, our websites, manage our payment solutions,
                                    perform statistical analysis, marketing and advertising purposes, sending
                                    newsletters, provide customer support and perform other important services for us.{" "}
                                </p>
                                <p>
                                    <strong>B) State authorities</strong>. Your details that are provided and/or that
                                    will be provided by you during your activity on the site may be disclosed by the
                                    Foundation to official authorities. The Foundation will make such disclosure only if
                                    required to be disclosed by the Foundation by applicable law, regulation or court
                                    order and to the minimum required extent.
                                </p>
                                <p>
                                    <strong>C) Other disclosures</strong>. In addition to where you have consented to a
                                    disclosure of the data or where disclosure is necessary to achieve the purpose(s)
                                    for which, it was collected, data may also be disclosed in special situations, where
                                    we have reason to believe that doing so is necessary to identify, contact or bring
                                    legal action against anyone damaging, injuring, or interfering (intentionally or
                                    unintentionally) with our rights or property, users, or anyone else who could be
                                    harmed by such activities, or otherwise where necessary for the establishment,
                                    exercise or defence of legal claims.
                                </p>
                                <p>
                                    For the subsequent provision of you with targeted and informative advertising we may
                                    transmit your data to{" "}
                                    <Link target="_blank" to="https://www.facebook.com/">
                                        Facebook
                                    </Link>{" "}
                                    and{" "}
                                    <Link target="_blank" to="https://www.google.com/">
                                        Google
                                    </Link>
                                    . In addition, we may use{" "}
                                    <Link target="_blank" to="https://www.google.com/">
                                        Google
                                    </Link>{" "}
                                    services for the performance of data analytics. You may at any time choose to
                                    disable and/or restrict such transfers by notifying us at the contact details listed
                                    in the section CONTACT DETAILS above.
                                </p>
                                <p>
                                    For the recognition of the text specified in the documents in order to clarify the
                                    correctness of the your data, which were previously personally indicated in the
                                    service profile, we use{" "}
                                    <Link target="_blank" to="https://cloud.google.com/vision/">
                                        Google Vision
                                    </Link>{" "}
                                    and{" "}
                                    <Link target="_blank" to="https://www.jumio.com/">
                                        Jumio
                                    </Link>
                                    . For the comparison of the user’s first and last name contained in the recognized
                                    documents with the user’s first and last name, which were previously personally
                                    indicated in the service profile, we use{" "}
                                    <Link target="_blank" to="https://rosette.com/">
                                        Rosette API
                                    </Link>
                                    .
                                </p>
                                <p>
                                    Data may be transferred to{" "}
                                    <Link target="_blank" to="https://complyadvantage.com/">
                                        Comply
                                    </Link>{" "}
                                    Advantage, which provides us with screening service for the possible presence of the
                                    user in sanctions sheets, PEP sheets (Politically exposed person), media references
                                    for participation in criminal activities or presence in Interpol's and other
                                    agencies search lists. This kind of user verification is for fighting corruption and
                                    laundering of money, obtained as part of criminal activity.
                                </p>
                                <p>
                                    We may transfer your contact data to{" "}
                                    <Link to="https://www.nexmo.com/" target="_blank">
                                        Nexmo
                                    </Link>{" "}
                                    mass texting operator and{" "}
                                    <Link to="https://sendgrid.com/" target="_blank">
                                        Sendgrid only for the purpose of providing us
                                    </Link>{" "}
                                    with emailing operator services for efficient communication with you.{" "}
                                </p>
                                <p>
                                    We also cooperate with the following next payment systems to whom your data may be
                                    disclosed for the sole purpose of performing payments:{" "}
                                    <Link to="https://www.skrill.com" target="_blank">
                                        Skrill
                                    </Link>
                                    ,{" "}
                                    <Link to="https://www.neteller.com" target="_blank">
                                        Neteller
                                    </Link>
                                    ,{" "}
                                    <Link to="https://www.cardpay.com" target="_blank">
                                        CardPay
                                    </Link>
                                    , and{" "}
                                    <Link to="https://ecommpay.com/" target="_blank">
                                        Ecommpay
                                    </Link>
                                    .
                                </p>
                                <p>
                                    In order to ensure safe storage of your data we locate our equipment in the{" "}
                                    <Link to="https://webzilla.com/" target="_blank">
                                        Webzilla
                                    </Link>
                                    ,{" "}
                                    <Link to="http://www.iptp.net/" target="_blank">
                                        IPTP
                                    </Link>{" "}
                                    and{" "}
                                    <Link to="https://www.rackspace.com/" target="_blank">
                                        Rackspace
                                    </Link>{" "}
                                    data centres.
                                </p>
                                <p>
                                    Where reasonably possible, management shall ensure that third parties collecting,
                                    storing or processing personal information on behalf of the Company have:
                                </p>
                                <p>
                                    (i) Signed agreements to protect personal information consistent with this privacy
                                    policy and information security practices or implemented measures as prescribed by
                                    GDPR;
                                </p>
                                <p>
                                    (ii) Signed non-disclosure agreements or confidentiality agreements which includes
                                    privacy clauses in the contract; and
                                </p>
                                <p>
                                    (iii) Established procedures to meet the terms of their agreement with third party
                                    to protect personal information.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>6. International transfers</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We ensure your personal data is protected by requiring all our affiliate companies
                                    and/or partners to follow the same rules when processing your personal data.
                                </p>
                                <p>
                                    Whenever we transfer your personal data out of the EEA, we ensure a similar degree
                                    of protection is afforded to it by ensuring at least one of the following safeguards
                                    is implemented:
                                </p>
                                <p>(i) The individual has given consent to the transfer of information</p>
                                <p>
                                    (ii) The transfer is necessary for the performance of a contract between the
                                    individual and the Company, or the implementation of pre-contractual measures taken
                                    in response to the individual’s request.
                                </p>
                                <p>
                                    (iii) The transfer is necessary for the conclusion or performance of a contract
                                    concluded in the interest of the individual between the IQOPTION and a third party.
                                </p>
                                <p>
                                    (iv) The transfer is necessary or legally required on important public interest
                                    grounds or for the establishment, exercise or defence of legal claims.
                                </p>
                                <p>(v) The transfer is required by law.</p>
                                <p>
                                    (vi) The transfer is necessary in order to protect the vital interests of the
                                    individual.
                                </p>
                                <p>(vii) The transfer is made under a data transfer agreement.</p>
                                <p>(viii) The transfer is otherwise legitimised by applicable law.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>7. Data security</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We have put in place appropriate security measures to prevent your data from being
                                    accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In
                                    addition, we limit access to your data to those employees, agents, contractors and
                                    other third parties who have a business need to know. They will only process your
                                    data on our instructions and they are subject to a duty of confidentiality.
                                </p>
                                <p>
                                    We have put in place procedures to deal with any suspected data breach and will
                                    notify you and the competent regulator of a breach where we are legally required to
                                    do so.
                                </p>
                                <p>
                                    Your help is always valuable in ensuring that your data is kept safe. Upon
                                    registration to{" "}
                                    <Link to="/" target="_blank">
                                        http://iqcharity.org
                                    </Link>
                                    , you will be asked to choose a username and password to be used by you on each
                                    future login. In order to protect your privacy and operation with{" "}
                                    <Link to="/" target="_blank">
                                        http://iqcharity.org
                                    </Link>{" "}
                                    sharing registration details (including without limitation, username and password)
                                    by you with other persons or business entities is strictly prohibited. The
                                    Foundation shall not be held responsible for any damage or loss caused to you due to
                                    improper use (including prohibited and unprotected use) or storage of such username
                                    and password, including any such use made by a third party, and whether or not known
                                    to or authorized by you.
                                </p>
                                <p>
                                    Any use of{" "}
                                    <Link to="/" target="_blank">
                                        http://iqcharity.org
                                    </Link>{" "}
                                    with your username and password is your sole responsibility. The Foundation shall
                                    not be held responsible for any such use, including for validation that your are
                                    actually operating in your account.
                                </p>
                                <p>
                                    You are obliged to forthwith notify the Foundation of any suspicion for unauthorized
                                    use of the your account. You may contact us at any time through the points of
                                    contact listed in the section CONTACT DETAILS above.
                                </p>
                                <p>
                                    <strong>Encryption of your data in transit</strong>. Encryption provides a high
                                    level of security and privacy for your data. When you enter your data in our
                                    platform we use strong encryption technologies (such as Transport Layer Security) to
                                    protect your data during transmission from your devices to our servers.
                                </p>
                                <p>
                                    For providing more trust and security we use digital EV (Extended Validation)
                                    Certificates issued by trusted Certificate Authorities. You can see the ‘Green Bar’
                                    in the supported browser versions which confirms what all transmitted data is
                                    secure.
                                </p>
                                <p>
                                    <strong>Protection of your data in our infrastructure</strong>. We make it a
                                    priority to develop services that are secure "by default". The "default" security of
                                    our services means that every new services and features are designed with strict
                                    security requirements in mind before we even begin development. This is the key to
                                    guaranteed protection and privacy of all data that our services handle and store,
                                    once the service or new feature is released.
                                </p>
                                <p>
                                    For secure your data we use the pseudonymisation which allows most of our services
                                    to operate without using your actual data. Instead of that, our services use a
                                    system ID that can't be traced back to identify you.{" "}
                                </p>
                                <p>
                                    The Foundation is always vigilant about the security of your data stored in our
                                    infrastructure. Because of that we locate all our equipment which used for your data
                                    processing in secure data centres. Network access to this equipment is isolated from
                                    the Internet. We use network segmentation for isolation of services which need
                                    different level of security from each other. In addition, we restrict logical access
                                    to your data for our employees on "need to know" basis. So, only personnel, who
                                    really requires access to your data for the purpose of providing you with our best
                                    service, will have access to it.
                                </p>
                                <p>
                                    <strong>Threats protection</strong>. The Foundation is highly knowledgeable about
                                    modern threats to data security and privacy, and we are well prepared to combat
                                    them. All events that occur in our infrastructure are continuously monitored,
                                    analysed and responded, which allows us to ensure proper protection of your data,
                                    keeping it safe from threats, vulnerabilities, and the effects of malware.
                                </p>
                                <p>
                                    In the event of a failure that affects the accessibility of your data, we have data
                                    backup and recovery procedures in place that will us help to restore your data in
                                    short time. To guarantee the quick recovery we use high availability mode enabled
                                    for most critical databases which allows us to minimize downtime.
                                </p>
                                <p>
                                    <strong>Employee awareness of data security</strong>. Our employees may handle your
                                    data in order to provide you with the first-class service. To guarantee the security
                                    and confidentiality of your data, we monitor all employees’ actions with access to
                                    your data in our systems and grant access strictly on a "need to know" basis: only
                                    employees who need access will receive it. We hold regular training sessions to make
                                    sure that each employee understands the principles that the Foundation follows to
                                    achieve robust data security and privacy.
                                </p>
                                <p>
                                    <strong>If you choose not to give your personal information</strong>. In the context
                                    of our business relationship we may need to collect data by law, or under the terms
                                    of a contract we have with you. Without this data, we are, in principle, not in a
                                    position to close or execute a contract with you.
                                </p>
                                <p>
                                    If you choose not to give us this data, it may delay or prevent us from meeting our
                                    obligations. It may also mean that we cannot perform services needed to run your
                                    accounts or policies.{" "}
                                </p>
                                <p>
                                    <strong>To what extent we carry automated decision-making and profiling</strong>. In
                                    establishing and carrying out a business relationship, we generally do not use
                                    automated decision-making. If we use this procedure in individual cases, we will
                                    inform you of this separately. In some cases, we may proceed with profiling in order
                                    to evaluate certain personal aspects. We shall inform you accordingly in case we
                                    perform any profiling. In general, any data collection that is optional would be
                                    made clear at the point of collection.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>8. Data retention</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    <strong>How long will you use my personal data for?</strong>
                                </p>
                                <p>
                                    We will only retain your personal data for as long as reasonably necessary to fulfil
                                    the purposes we collected it for, including for the purposes of satisfying any
                                    legal, regulatory, tax, accounting or reporting requirements. We may retain your
                                    personal data for a longer period in the event of a complaint or if we reasonably
                                    believe there is a prospect of litigation in respect to our relationship with you.
                                </p>
                                <p>
                                    To determine the appropriate retention period for personal data, we consider the
                                    amount, nature and sensitivity of the personal data, the potential risk of harm from
                                    unauthorised use or disclosure of your personal data, the purposes for which we
                                    process your personal data and whether we can achieve those purposes through other
                                    means, and the applicable legal, regulatory, tax, accounting or other requirements.
                                </p>
                                <p>
                                    In some circumstances you can ask us to delete your data: see Your legal rights
                                    below for further information.
                                </p>
                                <p>
                                    In some circumstances we will anonymise your personal data (so that it can no longer
                                    be associated with you) for research or statistical purposes, in which case we may
                                    use this information indefinitely without further notice to you.{" "}
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>9. Your legal rights</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Under certain circumstances, you have rights in accordance with the provisions of
                                    GDPR and the applicable local legislation as amended from time to time. Some of the
                                    rights are rather complex and include exemptions, thus we strongly advise you to
                                    contact us (at the contact details listed in the section OUR CONTACT DETAILS below)
                                    and/or seek guidance from the regulatory authorities for a full explanation of these
                                    rights. You can find a summary of your rights below in this section.
                                </p>
                                <p>
                                    <strong>A) The right to access</strong>. You have a right to obtain the confirmation
                                    as to whether or not your data are being processed by us. In addition, you have a
                                    right to obtain more detailed information about the data kept and the processing
                                    undertaken by us and under certain circumstances the right to receive a copy of this
                                    data.
                                </p>
                                <p>
                                    <strong>B) The right to rectification</strong>. You have the right to have
                                    inaccurate data about you rectified, and, taking into account the purpose of the
                                    processing, to have incomplete data completed.{" "}
                                </p>
                                <p>
                                    <strong>C) The right to erasure</strong>. This enables you to ask us to delete or
                                    remove personal data where there is no good reason for us continuing to process it.
                                    Please note however that we may not always be able to comply with your request of
                                    erasure for specific legal reasons which will be notified to you, if applicable, at
                                    the time of your request. In case if you want to obtain complete erasure of your
                                    data (to apply the “right to be forgotten”), please note that we may not always be
                                    able to comply with your request of erasure for specific legal reasons which will be
                                    notified to you, if applicable, at the time of your request.{" "}
                                </p>
                                <p>
                                    <strong>D) The right to restriction of processing</strong>. You have the right to
                                    request the restriction of processing of your personal data (a) if it is not
                                    accurate;(b) where processing may be unlawful but you do not want us to erase your
                                    data; (c) where you need us to hold the data even if we no longer require it; or (d)
                                    where you may have objected to our use of your data but we need to verify whether we
                                    have overriding legitimate grounds to use it.
                                </p>
                                <p>
                                    <strong>E) The right to data portability</strong>. To the extent the legal basis for
                                    the processing is your consent, and such processing is carried out by automated
                                    means, you have the right to receive your data in a structured, commonly used and
                                    machine-readable format. However, this right does not apply where it would adversely
                                    affect the rights and freedoms of others and/or in case it may be technically
                                    impossible to do so.
                                </p>
                                <p>
                                    <strong>F) The right to object</strong>. Subject to the legal basis on which the
                                    processing activity is based, you may object to processing of your personal data.
                                    Please note that in some cases, we may have compelling legitimate grounds to process
                                    your information which we need to comply with.
                                </p>
                                <p>
                                    <strong>G) The right to withdraw consent</strong>. To the extent that the legal
                                    basis for the processing is your consent, you have the right to withdraw from that
                                    consent at any time. This may apply to marketing purposes and/or with regards to the
                                    transfer of your data to third parties. In case you withdraw from a consent given,
                                    then we will cease to process your data, unless and to the extent the continued
                                    processing is permitted or required according to the applicable data regulation or
                                    other applicable laws and regulations. The withdrawal from your consent will in no
                                    event effect the lawfulness of processing based on consent before its withdrawal.{" "}
                                </p>
                                <p>
                                    <strong>H) The right to complaint to data protection supervisory authority</strong>.
                                    We do our best to ensure that we protect your data, keep you informed about how we
                                    process your data and comply with the applicable data protection regulation. In case
                                    you are not satisfied with the processing and protection of your data or the
                                    information you have received from us, then we urge you to inform us in order for us
                                    to improve. Please also do not hesitate to contact us, if you want to make use of
                                    your rights.{" "}
                                </p>
                                <p>
                                    If you want to exercise any of your rights mentioned above and/or obtain more
                                    information regarding your rights and/or our policies and procedures please contact
                                    us through the points of contact listed in the section CONTACT DETAILS above. Please
                                    also provide us with relevant information to take care of your request, including
                                    your full name and email address so that we can identify you. We will respond to
                                    your request without undue delay.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>10. Glossary</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    <strong>LAWFUL BASIS</strong>
                                </p>
                                <p>
                                    <strong>Legitimate Interest</strong> means the interest of our business in
                                    conducting and managing our business to enable us to give you the best
                                    service/product and the best and most secure experience. We make sure we consider
                                    and balance any potential impact on you (both positive and negative) and your rights
                                    before we process your personal data for our legitimate interests. We do not use
                                    your personal data for activities where our interests are overridden by the impact
                                    on you (unless we have your consent or are otherwise required or permitted to by
                                    law). You can obtain further information about how we assess our legitimate
                                    interests against any potential impact on you in respect of specific activities by
                                    contacting us.
                                </p>
                                <p>
                                    <strong>Performance of Contract</strong> means processing your data where it is
                                    necessary for the performance of a contract to which you are a party or to take
                                    steps at your request before entering into such a contract.
                                </p>
                                <p>
                                    <strong>Comply with a legal obligation</strong> means processing your personal data
                                    where it is necessary for compliance with a legal obligation that we are subject to.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </PrivacyBlock>
        </Layout>
    );
};

export default PrivacyPolicy;

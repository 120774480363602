import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ProjectsMain from "./ProjectsMain";
import MakeIt from "./MakeIt";
import Layout from "../../components/Layout";
import Page404 from "../../components/Page404";
import MyFirstCode from "./MyFirstCode";
import CosmosLayout from "./CosmosLayout";
import FaithFund from "./FaithFund";
import RwandaWater from "./RwandaWater";
import Kosmos from "./Kosmos";
import KonkursCode from "./KonkursCode";
import Python from "./Python";
import PythonGen from "./PythonGen";
import GirlsDoIt from "./GirlsDoIt";
import Test from "./Test";
import PythonPro from "./PythonPro";
import Javascript from "./Javascript";
import FreeCourses from "./FreeCourses";

const Projects: React.FC<RouteComponentProps> = props => {
    const url = props.match.url;

    return (
        <Switch>
            <Route path={url} exact component={ProjectsMain} />
            <Route path={`${url}/:slug(makeit)`} component={MakeIt} />
            <Route path={`${url}/:slug(myfirstcode)`} component={MyFirstCode} />
            <Route path={`${url}/:slug(cosmoslayout)`} component={CosmosLayout} />
            <Route path={`${url}/:slug(faithfund)`} component={FaithFund} />
            <Route path={`${url}/:slug(water)`} component={RwandaWater} />
            <Route path={`${url}/:slug(kosmos)`} component={Kosmos} />
            <Route path={`${url}/:slug(konkurs-code)`} component={KonkursCode} />
            <Route path={`${url}/:slug(python)`} component={Python} />
            <Route path={`${url}/:slug(pythononline)`} component={PythonGen} />
            <Route path={`${url}/:slug(girlsdoit)`} component={GirlsDoIt} />
            <Route path={`${url}/:slug(scratch)`} component={Test} />
            <Route path={`${url}/:slug(pythononlinepro)`} component={PythonPro} />
            <Route path={`${url}/:slug(javascript)`} component={Javascript} />
            <Route path={`${url}/:slug(freecourses)`} component={FreeCourses} />
            <Route>
                <Layout>
                    <Page404 />
                </Layout>
            </Route>
        </Switch>
    );
};

export default Projects;

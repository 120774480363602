import React, { Component } from "react";
import TagManager from "react-gtm-module";
import { Link, NavLink } from "react-router-dom";
import { constants, mainMenuLinks, mobileMenuLinks } from "../../../config";
import { withTranslation, WithTranslation } from "react-i18next";
import { HeaderElem } from "./Atoms";
import "./assets/style.css";

interface State {
    isOpen: boolean;
    isAnimOut: boolean;
}

const tagManagerArgs = {
    gtmId: "GTM-PSJ5Z9F"
};
TagManager.initialize(tagManagerArgs);

const YANDEX_RU_SCRIPT = `
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
ym(56419471, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true
})
`;

const YANDEX_RU_NOSCRIPT = `
<div><img src="https://mc.yandex.ru/watch/56419471" style="position:absolute; left:-9999px;" alt="" /></div>
`;
const YANDEX_EN_SCRIPT = `
(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
ym(56419639, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true
});
`;
const YANDEX_EN_NOSCRIPT = `
<div><img src="https://mc.yandex.ru/watch/56419639" style="position:absolute; left:-9999px;" alt="" /></div>
`;
const GOGGLE_TAG = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag("js", new Date());
  gtag("config", "UA-167965543-1");
`;
const GOGGLE_TAG2 = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag("js", new Date());
  gtag("config", "UA-167965543-2");
  console.log
`;
const VK_TARGET = `!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){VK.Retargeting.Init("VK-RTRG-901536-7lYEh"),VK.Retargeting.Hit()},document.head.appendChild(t)}();`;
const VK_TARGET2 = `<img src="https://vk.com/rtrg?p=VK-RTRG-901536-7lYEh" style="position:fixed; left:-999px;" alt=""/>`;
var marker = 0;
var a = document.querySelectorAll(".button");

class Header extends Component<WithTranslation, State> {
    componentDidMount(): void {
        const script = document.createElement("script");
        const script2 = document.createElement("script");
        const script3 = document.createElement("script");
        const noscript = document.createElement("noscript");
        const script4 = document.createElement("script");
        const noscript2 = document.createElement("noscript");
        script.async = true;
        script3.async = true;
        script.innerHTML = constants.LOCALE === "ru" ? YANDEX_RU_SCRIPT : YANDEX_EN_SCRIPT;
        noscript.innerHTML = constants.LOCALE === "ru" ? YANDEX_RU_NOSCRIPT : YANDEX_EN_NOSCRIPT;
        script2.innerHTML = constants.LOCALE === "ru" ? GOGGLE_TAG : GOGGLE_TAG2;
        script4.innerHTML = VK_TARGET;
        noscript2.innerHTML = VK_TARGET2;
        if (constants.LOCALE === "ru") {
            script3.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=UA-167965543-1");
        } else {
            script3.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=UA-167965543-2");
        }
        document.getElementById("header")!.appendChild(script);
        document.getElementById("header")!.appendChild(noscript);
        document.getElementById("header")!.appendChild(script3);
        document.getElementById("header")!.appendChild(script2);
        document.getElementById("header")!.appendChild(script4);
        document.getElementById("header")!.appendChild(noscript2);

        // google gtag scripts
        // @ts-ignore
        window.onscroll = function() {
            // Получаем высоту окна пользователя
            var clientHeight = document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : document.body.clientHeight;
            // Получаем высоту страницы
            var documentHeight = document.documentElement.scrollHeight
                ? document.documentElement.scrollHeight
                : document.body.scrollHeight;
            // Рассчитываем сколько уже прокрученно
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // Если высота прокрутки равна высоте страницы, значит прокрутили до конца
            if (documentHeight - clientHeight <= scrollTop) {
                // Проверяем, что впервые дошли до конца
                if (marker === 0) {
                    console.log("Достигнут конец документа");
                    // Помечаем, что один раз уже станица была прокручена до конца
                    marker = 1;
                } else {
                    console.log("Уже докручивали до конца");
                }
                // @ts-ignore
                window.gtag("event", "пользователь пролистал страницу до конца", {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    event_category: "page",
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    event_action: "scroll"
                });
                // @ts-ignore
                window.ym(56419471, "reachGoal", "scroll");
                console.log("пользователь пролистал страницу до конца");
            }
        };
    }

    state: State = {
        isOpen: false,
        isAnimOut: false
    };

    handleClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
        this.state.isOpen && this.setState({ isAnimOut: true });
        setTimeout(() => this.setState({ isAnimOut: false }), 700);
    };

    render() {
        const { isOpen, isAnimOut } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <HeaderElem className="header" id="header">
                    <div className="header-inner clear">
                        {constants.LOCALE === "en" && <NavLink to="/" className="logo" />}
                        {constants.LOCALE === "ru" && <NavLink to="/" className="logo2" />}
                        <ul className="navigation">
                            {mainMenuLinks.map((link, index) => {
                                return (
                                    <li key={index}>
                                        <NavLink to={link.to}>{t(link.label)}</NavLink>
                                    </li>
                                );
                            })}
                        </ul>
                        <Link to="/kak-pomoch" className="head-help-now">
                            <i className="heart blink" />
                            Поддержать
                        </Link>
                        <div className="menu">
                            <button className={`hamburger ${isOpen ? " active" : ""}`} onClick={this.handleClick}>
                                <span className="line line-1" />
                                <span className="line line-2" />
                                <span className="line line-3" />
                            </button>
                            <div className={`hidden_body_cover ${isOpen ? "active" : ""}`} />
                            <div className={`hidden_menu ${isOpen ? " active" : ""}${isAnimOut ? "animOut" : ""}`}>
                                <div className="hidden_menu_inner clear">
                                    <div className="vert_menu">
                                        <ul>
                                            {mobileMenuLinks.map((link, index) => {
                                                return (
                                                    <li key={index}>
                                                        <NavLink to={link.to}>{t(link.label)}</NavLink>
                                                    </li>
                                                );
                                            })}
                                            <li className="list-6 inlined">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.facebook.com/IQOptionCharitableFoundation/"
                                                >
                                                    <i className="fa fa-facebook-square" aria-hidden="true" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </HeaderElem>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Header);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { constants } from "./index";
import enTranslation from "../locales/en/translation";
import ruTranslation from "../locales/ru/translation";

i18n.use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        lng: constants.LOCALE,
        debug: process.env.NODE_ENV !== "production",
        resources: {
            en: {
                translation: enTranslation
            },
            ru: {
                translation: ruTranslation
            }
        },
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;

import React from "react";
import { ErrorPage } from "./Atoms";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404: React.FC = () => {
    const { t } = useTranslation();
    return (
        <ErrorPage>
            <div className="notfound">
                <div className="notfound-404">
                    <div />
                    <h1>404</h1>
                </div>
                <h2>{t("page404.title")}</h2>
                <p>{t("page404.text")}</p>
                <Link to="/">{t("page404.buttonText")}</Link>
            </div>
        </ErrorPage>
    );
};

export default Page404;

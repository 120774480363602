import React, { Component } from "react";
import "./assets/style.css";
import Report from "../../../models/Report";
import { getReports } from "../../../api";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import ReportPreview from "../../../components/ReportPreview";
import { RouteComponentProps } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";

interface State {
    isLoading: boolean;
    reports: Report[];
}

class ReportsMain extends Component<RouteComponentProps & WithTranslation, State> {
    state: State = {
        isLoading: true,
        reports: []
    };

    async componentDidMount(): Promise<void> {
        const reports = await getReports();
        this.setState({ isLoading: false, reports });
    }
    render() {
        const { isLoading, reports } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="reports">
                    <h1 className="h1">{t("reports.title")}</h1>
                    <div className="all_reports">
                        {reports.map(report => {
                            return <ReportPreview key={report.id} report={report} />;
                        })}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(ReportsMain);

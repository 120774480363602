import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";
import "./assets/style.css";
import Partner from "../../../models/Partner";
import { getPartners } from "../../../api";
import Layout from "../../../components/Layout";
import Loader from "../../../components/Loader";
import PartnerPreview from "../../../components/PartnerPreview";
import { withTranslation, WithTranslation } from "react-i18next";
// import { constants } from "../../../config";
import { RouteComponentProps } from "react-router";
// import styled from "styled-components";
import newImg from "./assets/img/new.png";
import reportImg from "./assets/img/report.png";
import pdf from "../../Foundation/assets/img/pdf.png";
import report2022 from "./assets/files/report-2022.pdf";

// const BITRIX_FORM_RU = `
// (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//         (w[b].forms=w[b].forms||[]).push(arguments[0])};
//         if(w[b]['forms']) return;
//         var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//         var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
// })(window,document,'https://b24.iq-charity.com/bitrix/js/crm/form_loader.js','b24form');
//
// b24form({"id":"15","lang":"ru","sec":"7kepvh","type":"inline"});
// `;
// const BITRIX_FORM_EN = `
// (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//         (w[b].forms=w[b].forms||[]).push(arguments[0])};
//         if(w[b]['forms']) return;
//         var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//         var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
// })(window,document,'https://b24.iqcharity.org/bitrix/js/crm/form_loader.js','b24form');
//
// b24form({"id":"7","lang":"en","sec":"e30e7n","type":"inline"});
// `;
// const BITRIX_FORM_RU2 = `
// (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//         (w[b].forms=w[b].forms||[]).push(arguments[0])};
//         if(w[b]['forms']) return;
//         var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//         var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
// })(window,document,'https://b24.iq-charity.com/bitrix/js/crm/form_loader.js','b24form');
//
// b24form({"id":"16","lang":"ru","sec":"jhd31f","type":"inline"});
// `;
// const BITRIX_FORM_EN2 = `
// (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//         (w[b].forms=w[b].forms||[]).push(arguments[0])};
//         if(w[b]['forms']) return;
//         var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//         var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
// })(window,document,'https://b24.iqcharity.org/bitrix/js/crm/form_loader.js','b24form');
//
// b24form({"id":"8","lang":"en","sec":"y420sc","type":"inline"});
// `;

interface State {
    isLoading: boolean;
    partners: Partner[];
    infoPartners: Partner[];
    projectPartners: Partner[];
    activeFirst: boolean;
    activeSecond: boolean;
}

const partnerByType = (partners: Partner[]): [Partner[], Partner[]] => {
    const infoPartners: Partner[] = [];
    const projectPartners: Partner[] = [];

    partners.forEach(partner => {
        if (partner.partnerType === "info") {
            infoPartners.push(partner);
        } else {
            projectPartners.push(partner);
        }
    });

    return [infoPartners, projectPartners];
};

// const Tabs = styled.div`
//     position: relative;
// `;
// const TabList = styled.div`
//     border-bottom: none;
//     text-align: center;
//     font-size: 0;
//     line-height: 0;
//     padding-bottom: 60px;
// `;
// const Tab = styled.button`
//     font-size: 24px;
//     line-height: 1.2;
//     font-family: "Source Sans Pro", sans-serif;
//     font-weight: 600;
//     text-transform: none;
//     text-align: center;
//     display: inline-block;
//     margin: 0 15px 0;
//     color: #e4e3e2;
//     background: none;
//     cursor: pointer;
//     border: none;
//     transition: color 0.3s ease-out;
//
//     &:hover,
//     &.active {
//         color: #2b2b2b;
//         background: none;
//     }
// `;
// const TabPanel = styled.div`
//     position: relative;
//     display: none;
//
//     &.active {
//         display: block;
//     }
// `;

class PartnersMain extends Component<RouteComponentProps & WithTranslation, State> {
    state: State = {
        isLoading: true,
        partners: [],
        infoPartners: [],
        projectPartners: [],
        activeFirst: true,
        activeSecond: false
    };

    async componentDidMount(): Promise<void> {
        const partners = await getPartners();
        const [infoPartners, projectPartners] = partnerByType(partners);
        const script = document.createElement("script");

        this.setState({ isLoading: false, partners, infoPartners, projectPartners });
        // script.async = true;
        // script.id = "bx24_form_inline";
        // script.setAttribute("data-skip-moving", "true");
        // script.innerHTML = constants.LOCALE === "ru" ? BITRIX_FORM_RU : BITRIX_FORM_EN;
        // document.getElementById("formDiv")!.appendChild(script);
        // script2.async = true;
        // script2.id = "bx24_form_inline2";
        // script2.setAttribute("data-skip-moving", "true");
        // script2.innerHTML = constants.LOCALE === "ru" ? BITRIX_FORM_RU2 : BITRIX_FORM_EN2;
        // document.getElementById("formDiv")!.appendChild(script2);

        script.async = true;
        script.src = "https://yookassa.ru/integration/simplepay/js/yookassa_construct_form.js";
        document.getElementById("scriptDiv")!.appendChild(script);
    }

    // onClickFirstTab = () => {
    //     this.setState({ activeFirst: true });
    //     this.setState({ activeSecond: false });
    // };
    //
    // onClickSecondTab = () => {
    //     this.setState({ activeFirst: false });
    //     this.setState({ activeSecond: true });
    // };

    render() {
        const { isLoading, projectPartners, infoPartners } = this.state;
        const { t } = this.props;

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        return (
            <Layout>
                <div className="reports">
                    <h1 className="h1">{t("partners.title")}</h1>
                    {/*<Tabs>*/}
                    {/*    <TabList>*/}
                    {/*        <Tab*/}
                    {/*            className={"tab " + (this.state.activeFirst ? "active" : "")}*/}
                    {/*            onClick={this.onClickFirstTab}*/}
                    {/*        >*/}
                    {/*            {t("partners.forPartners")}*/}
                    {/*        </Tab>*/}
                    {/*        <Tab*/}
                    {/*            className={"tab " + (this.state.activeSecond ? "active" : "")}*/}
                    {/*            onClick={this.onClickSecondTab}*/}
                    {/*        >*/}
                    {/*            {t("partners.forVolunteers")}*/}
                    {/*        </Tab>*/}
                    {/*    </TabList>*/}
                    {/*    <TabPanel className={this.state.activeFirst ? "active" : ""}>*/}
                    {/*        <div className="partners_info">*/}
                    {/*            <p>{t("partners.partnersText")}</p>*/}
                    {/*        </div>*/}
                    {/*        <h2 className="h2">{t("partners.projectPartners")}</h2>*/}
                    {/*        <div className="partners_grid clear">*/}
                    {/*            {projectPartners.map(partner => {*/}
                    {/*                return <PartnerPreview key={partner.id} partner={partner} />;*/}
                    {/*            })}*/}
                    {/*        </div>*/}
                    {/*        <h2 className="h2">{t("partners.informationalPartners")}</h2>*/}
                    {/*        <div className="partners_grid clear">*/}
                    {/*            {infoPartners.map(partner => {*/}
                    {/*                return <PartnerPreview key={partner.id} partner={partner} />;*/}
                    {/*            })}*/}
                    {/*        </div>*/}
                    {/*        <div className="container">*/}
                    {/*            <div className="form_partners">*/}
                    {/*                <h2 className="h2">{t("partners.becomeAPartnerHead")}</h2>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </TabPanel>*/}
                    {/*    <TabPanel className={this.state.activeSecond ? "active" : ""}>*/}
                    {/*        <div className="partners_info">*/}
                    {/*            <p>{t("partners.volunteersText")}</p>*/}
                    {/*        </div>*/}
                    {/*        {constants.LOCALE === "ru" && (*/}
                    {/*            <>*/}
                    {/*                <h2 className="h2">{t("partners.becomeVolunteerText")}</h2>*/}
                    {/*                <p className="subtitle">{t("partners.becomeVolunteerFillTheForm")}</p>*/}
                    {/*            </>*/}
                    {/*        )}*/}
                    {/*    </TabPanel>*/}
                    {/*</Tabs>*/}

                    <div className="partners_info" style={{ textAlign: "center", paddingBottom: 0 }}>
                        <p style={{ marginBottom: "10px" }}>
                            Больше шести лет мы создаем бесплатные проекты по изучению программирования для школьников,
                            преподавателей и молодёжи. Наша миссия – сделать IT-образование доступным, в том числе в
                            сёлах и малых городах РФ. Среди наших партнёров такие компании как HTML Academy, Stepik и
                            Алгоритмика.
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            В феврале 2022 года наш Фонд лишился основного донора. Мы знаем, что наша деятельность важна
                            – об этом говорят благодарности партнёров и отзывы наших благополучателей. И мы надеемся
                            найти средства, чтобы и дальше помогать молодёжи развиваться.
                        </p>
                        <p style={{ marginBottom: "10px" }}>
                            Мы ищем спонсоров и партнёров, которые заинтересованы в развитии IT-образования, а также в
                            работе с целевой аудиторией Фонда – школьниками, молодыми людьми 20–35 лет и преподавателями
                            информатики и математики. Будем рады новым знакомствам и как финансовой, так и
                            инфраструктурной поддержке.
                        </p>
                        <p>
                            Если вы хотите узнать больше о вариантах поддержки и сотрудничества, напишите, пожалуйста,
                            директору Фонда Ольге Константиновой:{" "}
                            <a href="mailto:olga.konstantinova@iq-charity.com">olga.konstantinova@iq-charity.com</a>
                        </p>
                    </div>

                    <div className="partners_grid">
                        <img src={newImg} alt="" />
                    </div>

                    <div className="partners_grid" style={{ paddingBottom: 0 }}>
                        <img src={reportImg} alt="" />
                    </div>

                    <div className="reports_block" id="reports_block">
                        <h2 className="h2">{t("foundation.reports.head")}</h2>
                        <div className="documents">
                            <a
                                className="juristic_documents_link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={report2022}
                                download=""
                            >
                                <div className="link_img">
                                    <img src={pdf} alt="" />
                                </div>
                                <div className="link_text">
                                    <span>Годовой отчет Фонда за 2022 год</span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="help">
                        <div className="help__top" id="form" style={{ marginTop: 0 }}>
                            <div className="help__top-inner" style={{ paddingTop: 0 }}>
                                <div className="inner-text">
                                    <h1 className="h1">
                                        Как помочь?
                                        <span>Сделайте пожертвование</span>
                                    </h1>
                                    <div>
                                        Пожертвуйте любую сумму и помогите нам сделать IT-образование качественнее и
                                        доступнее. Помочь просто – выберите тот способ, который подходит вам больше
                                        всего. Вы можете поддержать нас разово или подписаться на регулярные
                                        пожертвования (опция будет доступна позже).
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="help__bottom">
                            <div className="help__bottom-inner">
                                <div className="help-form">
                                    <link
                                        rel="stylesheet"
                                        href="https://yookassa.ru/integration/simplepay/css/yookassa_construct_form.css"
                                    />
                                    <form
                                        className="yoomoney-payment-form"
                                        action="https://yookassa.ru/integration/simplepay/payment"
                                        method="post"
                                        acceptCharset="utf-8"
                                    >
                                        <div className="ym-products">
                                            <div className="ym-block-title ym-products-title">Поддержать фонд</div>
                                            <div className="ym-product">
                                                <div className="ym-product-line">
                                                    <span className="ym-product-description">
                                                        Разовое пожертвование:
                                                    </span>
                                                    <span className="ym-product-price" data-price="100" data-id="348">
                                                        300,00&nbsp;₽
                                                    </span>
                                                </div>
                                                <div className="ym-product-line ym-align-items-center">
                                                    <span className="ym-label-text">Измените сумму:</span>
                                                    <div className="ym-count-input">
                                                        <input
                                                            className="ym-input"
                                                            defaultValue="3.00"
                                                            type="number"
                                                            step="any"
                                                        />
                                                        <span className="ym-count-control ym-count-plus" />
                                                        <span className="ym-count-control ym-count-minus" />
                                                    </div>
                                                </div>
                                                <input
                                                    disabled
                                                    type="hidden"
                                                    name="text"
                                                    value="Однократное пожертвование"
                                                />
                                                <input disabled type="hidden" name="price" value="100" />
                                                <input disabled type="hidden" name="quantity" value="3" />
                                                <input
                                                    disabled
                                                    type="hidden"
                                                    name="paymentSubjectType"
                                                    value="commodity"
                                                />
                                                <input
                                                    disabled
                                                    type="hidden"
                                                    name="paymentMethodType"
                                                    value="full_prepayment"
                                                />
                                                <input disabled type="hidden" name="tax" value="1" />
                                            </div>
                                        </div>
                                        <input value="" type="hidden" name="ym_merchant_receipt" />
                                        <div className="ym-customer-info">
                                            <div className="ym-block-title">О вас</div>
                                            <input
                                                name="cps_email"
                                                className="ym-input"
                                                placeholder="Email"
                                                type="text"
                                            />
                                            <input
                                                name="custName"
                                                className="ym-input"
                                                placeholder="ФИО (необязательное поле)"
                                                type="text"
                                            />
                                            <textarea
                                                className="ym-textarea"
                                                name="orderDetails"
                                                placeholder="Комментарий (необязательное поле)"
                                            />
                                        </div>
                                        <div className="ym-hidden-inputs" />
                                        <div className="ym-payment-btn-block ym-before-line ym-align-space-between">
                                            <div className="ym-input-icon-rub ym-display-none">
                                                <input
                                                    name="sum"
                                                    placeholder="0.00"
                                                    className="ym-input ym-sum-input ym-required-input"
                                                    type="number"
                                                    step="any"
                                                    defaultValue="300"
                                                />
                                            </div>
                                            <button
                                                data-text="Поддержать фонд"
                                                className="ym-btn-pay ym-result-price button"
                                            >
                                                <span className="ym-text-crop">Поддержать фонд</span>
                                                <span className="ym-price-output">100,00&nbsp;₽</span>
                                            </button>
                                            <svg
                                                width="100%"
                                                height="100%"
                                                viewBox="0 0 266 63"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M138.76 17.337H130.17L124.091 28.02H120.962L120.862 4.90002H112.73V46.6555H120.862L120.962 35.1952H124.071L132.143 46.6555H141.152L130.628 31.4681L138.76 17.337Z"
                                                    fill="#000000"
                                                />
                                                <path
                                                    d="M200.307 31.0895C198.655 30.0204 196.864 29.1821 194.985 28.5981L193.191 27.9205L192.713 27.7411C191.597 27.3225 190.421 26.8841 190.381 25.748C190.371 25.4115 190.445 25.0778 190.599 24.7781C190.752 24.4783 190.979 24.2223 191.258 24.0339C191.847 23.6281 192.537 23.3935 193.251 23.3563C194.808 23.2488 196.352 23.709 197.596 24.6518L197.815 24.7913L202.16 19.7886L201.941 19.6093C201.4 19.127 200.813 18.6997 200.187 18.3337C199.066 17.6925 197.848 17.2413 196.58 16.9983C194.753 16.6097 192.865 16.6097 191.039 16.9983C189.273 17.232 187.598 17.9186 186.176 18.9914C185.266 19.7029 184.506 20.5867 183.938 21.5921C183.371 22.5975 183.007 23.7049 182.867 24.8511C182.618 26.9195 183.143 29.0069 184.342 30.7108C185.934 32.4741 188.016 33.7231 190.321 34.2984L190.68 34.418L191.497 34.697C194.447 35.6936 195.284 36.0922 195.762 36.6901C195.985 36.9909 196.11 37.3526 196.121 37.7265C196.121 39.1416 194.387 39.7196 193.211 40.0784C192.389 40.2328 191.544 40.217 190.728 40.0319C189.912 39.8468 189.143 39.4963 188.468 39.0021C187.375 38.2715 186.44 37.3295 185.717 36.2317C185.259 36.7101 180.754 41.1547 180.834 41.2344L180.974 41.4337C183.144 44.151 186.185 46.0374 189.584 46.7752C190.36 46.9254 191.146 47.0253 191.936 47.0742H192.753C195.438 47.1304 198.068 46.312 200.247 44.7423C201.721 43.6998 202.851 42.243 203.496 40.5567C203.888 39.4247 204.026 38.2205 203.902 37.029C203.778 35.8375 203.395 34.6877 202.778 33.6606C202.148 32.6364 201.305 31.7595 200.307 31.0895V31.0895Z"
                                                    fill="#000000"
                                                />
                                                <path
                                                    d="M226.576 31.0893C224.931 30.0203 223.148 29.182 221.275 28.5979L219.461 27.9203L219.003 27.7409C217.867 27.3223 216.711 26.8838 216.651 25.7478C216.656 25.4119 216.74 25.082 216.896 24.7843C217.052 24.4867 217.275 24.2296 217.548 24.0337C218.137 23.6278 218.827 23.3933 219.541 23.3561C221.098 23.2522 222.64 23.712 223.886 24.6516L224.085 24.7911L228.43 19.7884L228.231 19.609C227.686 19.1221 227.092 18.6945 226.457 18.3335C225.343 17.6931 224.131 17.2418 222.869 16.9981C221.036 16.609 219.142 16.609 217.309 16.9981C215.545 17.2387 213.871 17.9246 212.445 18.9912C211.53 19.696 210.763 20.5748 210.188 21.577C209.614 22.5792 209.243 23.685 209.097 24.831C208.836 26.9001 209.362 28.9917 210.572 30.6907C212.164 32.454 214.246 33.7029 216.551 34.2783L216.89 34.3979L217.707 34.6769C220.677 35.6734 221.514 36.0721 221.992 36.67C222.222 36.9657 222.342 37.3321 222.331 37.7064C222.331 39.1215 220.617 39.6995 219.441 40.0583C218.616 40.2131 217.767 40.1975 216.948 40.0125C216.129 39.8274 215.356 39.4767 214.678 38.982C213.594 38.2407 212.661 37.3006 211.927 36.2116C211.489 36.6899 206.984 41.1345 207.044 41.2143L207.203 41.4136C209.374 44.1309 212.415 46.0173 215.814 46.7551C216.59 46.9065 217.376 47.0064 218.166 47.054H218.983C221.668 47.1103 224.298 46.2918 226.477 44.7221C227.951 43.6797 229.081 42.2229 229.726 40.5366C230.117 39.4046 230.256 38.2004 230.132 37.0089C230.008 35.8174 229.625 34.6676 229.008 33.6405C228.386 32.6286 227.557 31.7593 226.576 31.0893V31.0893Z"
                                                    fill="#000000"
                                                />
                                                <path
                                                    d="M165.467 17.3371V20.2071H165.109C162.883 17.9784 159.867 16.7179 156.718 16.6993C154.787 16.6611 152.869 17.0301 151.091 17.7821C149.312 18.5342 147.711 19.6525 146.393 21.0642C143.735 24.0407 142.31 27.9173 142.407 31.9066C142.304 35.9629 143.726 39.9102 146.393 42.9683C147.68 44.381 149.255 45.5001 151.013 46.2499C152.77 46.9996 154.668 47.3623 156.578 47.3133C159.732 47.2541 162.765 46.0903 165.148 44.0247H165.467V46.5958H173.918V17.3371H165.467ZM165.886 32.0661C165.971 34.424 165.183 36.7303 163.673 38.5437C162.95 39.3539 162.057 39.9952 161.058 40.4223C160.06 40.8493 158.979 41.0517 157.894 41.0151C156.841 41.0328 155.797 40.8126 154.841 40.3709C153.885 39.9291 153.041 39.2773 152.373 38.4639C150.881 36.613 150.116 34.2815 150.22 31.9066C150.152 29.6032 150.932 27.3549 152.412 25.5885C153.094 24.7875 153.944 24.1469 154.902 23.7128C155.86 23.2786 156.902 23.0616 157.953 23.0772C159.032 23.0436 160.104 23.2497 161.093 23.6805C162.082 24.1113 162.964 24.7561 163.673 25.5686C165.183 27.3899 165.97 29.7023 165.886 32.0661Z"
                                                    fill="#000000"
                                                />
                                                <path
                                                    d="M257.389 17.337V20.2071H257.03C254.809 17.9832 251.802 16.723 248.659 16.6992C246.726 16.6633 244.806 17.0331 243.024 17.7849C241.243 18.5368 239.638 19.6539 238.315 21.0641C235.657 24.0406 234.232 27.9173 234.329 31.9066C234.226 35.9629 235.648 39.9102 238.315 42.9683C239.602 44.3809 241.177 45.5001 242.934 46.2498C244.692 46.9995 246.59 47.3622 248.5 47.3133C251.654 47.254 254.686 46.0903 257.07 44.0247H257.389V46.5958H265.84V17.337H257.389ZM257.808 32.066C257.902 34.4253 257.113 36.735 255.595 38.5436C254.872 39.3538 253.979 39.9952 252.98 40.4222C251.981 40.8493 250.901 41.0517 249.815 41.0151C248.762 41.0328 247.719 40.8126 246.763 40.3708C245.807 39.9291 244.963 39.2772 244.294 38.4639C242.803 36.613 242.037 34.2815 242.142 31.9066C242.074 29.6031 242.854 27.3549 244.334 25.5885C245.016 24.7874 245.866 24.1469 246.824 23.7127C247.782 23.2786 248.824 23.0615 249.875 23.0772C250.954 23.0436 252.026 23.2496 253.015 23.6804C254.004 24.1112 254.885 24.7561 255.595 25.5685C257.113 27.3852 257.901 29.7008 257.808 32.066Z"
                                                    fill="#000000"
                                                />
                                                <path
                                                    d="M57.2192 0C39.6749 0 25.7192 14.1551 25.7192 31.5C25.7192 49.0443 39.8743 63 57.2192 63C74.5642 63 88.7192 48.8449 88.7192 31.5C88.7192 14.1551 74.5642 0 57.2192 0ZM57.2192 43.0633C50.8395 43.0633 45.4566 37.6804 45.4566 31.3006C45.4566 24.9209 50.8395 19.538 57.2192 19.538C63.599 19.538 68.9819 24.9209 68.9819 31.3006C68.7825 37.8797 63.599 43.0633 57.2192 43.0633Z"
                                                    fill="url(#paint1_linear)"
                                                />
                                                <path
                                                    d="M25.519 8.97156V54.826H14.3544L0 8.97156H25.519Z"
                                                    fill="url(#paint1_linear)"
                                                />
                                                <defs>
                                                    <linearGradient
                                                        id="paint0_linear"
                                                        x1="57.2192"
                                                        y1="0"
                                                        x2="57.2192"
                                                        y2="63"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#0160D1" />
                                                        <stop offset="1" stopColor="#00479C" />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id="paint1_linear"
                                                        x1="12.7595"
                                                        y1="8.97156"
                                                        x2="12.7595"
                                                        y2="54.826"
                                                        gradientUnits="userSpaceOnUse"
                                                    >
                                                        <stop stopColor="#0160D1" />
                                                        <stop offset="1" stopColor="#00479C" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <input name="shopId" type="hidden" value="917259" />
                                    </form>
                                    <div id="scriptDiv" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="partners_info">
                        <p>{t("partners.partnersText")}</p>
                    </div>
                    <h2 className="h2">{t("partners.projectPartners")}</h2>
                    <div className="partners_grid clear" style={{ paddingBottom: "20px" }}>
                        {projectPartners.map(partner => {
                            return <PartnerPreview key={partner.id} partner={partner} />;
                        })}
                    </div>
                    <h2 className="h2">{t("partners.informationalPartners")}</h2>
                    <div className="partners_grid clear" style={{ paddingBottom: 0 }}>
                        {infoPartners.map(partner => {
                            return <PartnerPreview key={partner.id} partner={partner} />;
                        })}
                    </div>

                    <div className="partners_info" style={{ textAlign: "center" }}>
                        <p>Благодарим всех, кто уже с нами. Спасибо, вместе мы делаем IT-образование доступнее!</p>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(PartnersMain);

export default {
    mainMenu: {
        main: "Главная",
        fond: "Фонд",
        partners: "Партнеры",
        projects: "Проекты",
        reports: "События",
        contacts: "Контакты",
        help: "Как помочь"
    },
    footer: {
        menu: "Меню",
        address:
            "Создан 9 января 2017 года в Санкт-Петербурге и зарегистрирован Главным управлением\n" +
            "Министерства юстиции по г. Санкт-Петербургу за учетным номером 4561278511. Фонд был\n" +
            "учрежден с целью реализации долгосрочных программ и проектов в области образования на\n" +
            "территории всей Российской Федерации. Финансирование деятельности Фонда осуществляется\n" +
            "за счет пожертвований. Контроль за деятельностью осуществляет Попечительский совет.",
        officialDocuments: "Официальные документы",
        offerForDonation: "Публичная оферта о заключении договора пожертвования",
        offerPublic: "Публичная оферта",
        privacyPolicy: "Политика в отношении обработки и обеспечения безопасности перс. данных_17.06.2022",
        copy: "Благотворительный фонд «Возможности интеллекта» (ранее – БФ развития образования «Айкью Опшн») 2017 г."
    },
    mainScreen: {
        title: {
            text1: "Благотворительный ",
            text2: "фонд «Возможности ",
            text3: "интеллекта»"
        },
        subtitle: "Бесплатные проекты для учителей и учеников (ранее – БФ развития образования «Айкью Опшн»)"
    },
    foundation: {
        about:
            "Благотворительный фонд «Возможности интеллекта» (ранее – БФ развития образования «Айкью Опшн») был создан в Санкт-Петербурге в январе 2017 года.",
        mission:
            "Миссия – создать условия для доступного образования и оказать содействие в получении знаний в сфере современных информационных технологий.",
        goalsAndTasks: "Цели и задачи",
        outTeam: "Наша команда",
        goals: {
            goal1: "Содействие формированию системы дополнительного образования в сфере информационных технологий",
            goal2: "Популяризация современных информационных технологий в профессиональной ориентации учащихся",
            goal3:
                "Поддержка и распространение успешного опыта внедрения современных методов обучения среди учащихся в рамках дополнительного образования",
            goal4:
                "Формирование сообщества активных учителей, педагогов дополнительного образования, руководителей образовательных организаций, учеников",
            goal5:
                "Распространение и развитие дополнительного образования в сфере информационных технологий с помощью современных методов и средств",
            goal6: "Профориентация подростков в IT-сфере, знакомство с языками программирования и профессиями в IT"
        },
        team: {
            member1: {
                name: "Роман Белых",
                position: "Методист-координатор",
                scope: "Роман развивает проект «Поколение Python» и принимает участие в разработке новых проектов."
            },
            member2: {
                name: "Анна Ждановская",
                position: "Менеджер проекта",
                scope:
                    "Анна разрабатывает мероприятия для подростков, которые позволяют ребятам получить представление о современных профессиях в сфере IT и овладеть нужными практическими навыками."
            },
            member3: {
                name: "Ксения Севастьянова",
                position: "Координатор проектов",
                scope:
                    "Ксения поддерживает и развивает проект «Мой первый код». Ксения проводит консультации для преподавателей, занимается организацией конкурсов и Олимпиады для участников и координирует работу волонтеров фонда."
            },
            member4: {
                name: "Ольга Галак",
                position: "PR-менеджер",
                scope:
                    "Ольга занимается продвижением фонда в СМИ, соц. сетях и на мероприятиях, чтобы как можно больше людей знали о фонде и принимали участие в благотворительных проектах."
            },
            member5: {
                name: "Ольга Быкова",
                position: "Менеджер по документообороту",
                scope:
                    "Оля занимается такими серьезными вещами, как бюджет и документооборот фонда. А ваше образовательное учреждение уже отправило нам наш экземпляр договора?)"
            }
        },
        reports: {
            head: "Отчеты",
            head2: "Официальные документы"
        }
    },
    partners: {
        title: "СОТРУДНИЧЕСТВО",
        forPartners: "Партнерам",
        forVolunteers: "Волонтерам",
        partnersText:
            "Мы благодарны нашим партнерам и всегда открыты для сотрудничества со СМИ, образовательными учреждениями, коммерческими и некоммерческими организациями, площадками для проведения мероприятий, креативными пространствами, экспертами из области аналитики и методической работы, фотографами и другими специалистами.\n" +
            "Спасибо, вместе мы делаем IT-образование доступнее!",
        volunteersText:
            "Волонтеры – часть нашей большой команды. Это люди разных возрастов и профессий из разных городов, благодаря которым мы успеваем делать больше и лучше. Если вам уже исполнилось 18 лет, вы легко находите общий язык с детьми и подростками и не боитесь влюбиться в информационные технологии без памяти, мы будем рады пригласить вас стать частью нашей команды. Вы получите реальный опыт организации образовательных мероприятий, зарядитесь атмосферой современного образования и IT, сможете познакомиться с активными и целеустремленными представителями IT-отрасли!",
        becomeVolunteerText: "Стать волонтером",
        becomeVolunteerFillTheForm: "Заполните форму, чтобы стать волонтером Фонда",
        projectPartners: "Партнеры проектов",
        informationalPartners: "Информационные партнеры",
        buttonText: "Подробнее",
        becomeAPartnerHead: "Стать партнёром",
        becomeAVolunteerHead: "Стать волонтёром",
        partnersPage: {
            title: "ИЩЕМ ПАРТНЁРОВ И СПОНСОРОВ",
            website: "Официальный веб-сайт",
            moreNews: "Больше новостей"
        }
    },
    reports: {
        title: "События",
        gallery: "Галерея",
        video: "Видео"
    },
    projects: {
        title: "Благотворительные проекты фонда",
        description:
            "Курсы предназначены для организации внеурочной деятельности в бюджетных образовательных учреждениях РФ. Участие во всех проектах фонда бесплатное для учителей и их учеников."
    },
    contacts: {
        title: "Контакты",
        vkDesk: "Вступайте в группу Фонда ВКонтакте, чтобы оставаться в курсе всех новостей",
        enterGroup: "Вступить в группу",
        documentQuestions: "По вопросам оформления документов",
        documentManagerName: "Ольга Быкова",
        documentManagerPosition: "Менеджер по документообороту",
        documentManagerEmail: "olga.bykova@iq-charity.com",
        marketingQuestions: "По вопросам размещения информации в СМИ и инфопартнерства",
        marketingManagerName: "Ольга Галак",
        marketingManagerPosition: "PR-менеджер",
        marketingManagerEmail: "olga.galak@iq-charity.com",
        address:
            "197374 Г. САНКТ-ПЕТЕРБУРГ, ВН.ТЕР. Г.МУНИЦИПАЛЬНЫЙ ОКРУГ ОЗЕРО ДОЛГОЕ, ДОР ТОРФЯНАЯ Д. 7 ЛИТЕРА Ф, ПОМЕЩ. 13-Н, КАБИНЕТ 21 ОФИС 721"
    },
    page404: {
        title: "Страница не найдена",
        text: "Возможно, страница, которую вы ищете, была удалена, изменилось ее имя или временно недоступна.",
        buttonText: "На главную"
    },
    blocks: {
        buttonText: {
            "More info": "Узнать больше",
            "Read the article": "Читать дальше",
            "About the project": "О проекте"
        },
        subscribeBtnText: "Подписаться на новости"
    }
};

import styled from "styled-components";
import gif from "./assets/preloader.gif";

export const LoaderElem = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    span {
        width: 40px;
        height: 40px;
        display: inline-block;
        background: url(${gif}) center center no-repeat;
        background-size: 40px auto;
    }
`;

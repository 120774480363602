import React, { Component } from "react";
import "./assets/style.css";
import pdfSvg from "./assets/img/pdf.svg";
import { withTranslation, WithTranslation } from "react-i18next";
import { constants, mainMenuLinks, footerInfoLinks } from "../../../config";
import { Link, NavLink } from "react-router-dom";
import OfferForDonationPdf from "./assets/files/regulations.pdf";
import PrivacyPolicyPdf from "./assets/files/privacy-policy-new.pdf";

class Footer extends Component<WithTranslation> {
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="intro">
                        <div className="footer_row clear">
                            <div className="footer_column">
                                <Link to="/" className="logo" />
                                <div className="clear" />
                                <div className="address">{t("footer.address")}</div>
                            </div>
                            <div className="footer_column second_col">
                                <div className="footer_links">
                                    <div className="column_head">{t("footer.menu")}</div>
                                    <ul>
                                        {mainMenuLinks.map((link, index) => {
                                            return (
                                                <li key={index}>
                                                    <NavLink to={link.to}>{t(link.label)}</NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="footer_column">
                                <div className="footer_links2">
                                    {constants.LOCALE === "ru" && (
                                        <>
                                            <div className="column_head">{t("footer.officialDocuments")}</div>
                                            <ul>
                                                <li>
                                                    <a
                                                        href={OfferForDonationPdf}
                                                        className="files"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        download=""
                                                    >
                                                        <div className="link_img">
                                                            <img src={pdfSvg} alt="" />
                                                        </div>
                                                        <div className="link_text">
                                                            <span>{t("footer.offerForDonation")}</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href={PrivacyPolicyPdf}
                                                        className="files"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        download=""
                                                    >
                                                        <div className="link_img">
                                                            <img src={pdfSvg} alt="" />
                                                        </div>
                                                        <div className="link_text">
                                                            <span>{t("footer.privacyPolicy")}</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </>
                                    )}
                                    {constants.LOCALE === "en" && (
                                        <>
                                            <div className="column_head">{t("footer.officialDocuments")}</div>
                                            <ul>
                                                {footerInfoLinks.map((link, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <NavLink to={link.to}>{t(link.label)}</NavLink>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="socials">
                                <ul>
                                    {constants.LOCALE === "ru" && (
                                        <>
                                            <li>
                                                <a
                                                    className="vk"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://vk.com/iqcharity"
                                                >
                                                    <span />
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a
                                                    className="ins"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.instagram.com/iqcharity/"
                                                >
                                                    <span />
                                                </a>
                                            </li> */}
                                        </>
                                    )}
                                    {constants.LOCALE === "en" && (
                                        <>
                                            <li>
                                                <a
                                                    className="fb"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.facebook.com/IQOptionCharitableFoundation/"
                                                >
                                                    <span />
                                                </a>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                            <div className="copy">
                                <p>&copy; {t("footer.copy")}</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default withTranslation()(Footer);

import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout";
import { getOneProject } from "../../../api";
import Page404 from "../../../components/Page404";
import Project from "../../../models/Project";
import Loader from "../../../components/Loader/Loader";
import "./assets/style.css";
import rwandaFlag from "./assets/img/rwanda.svg";
import faithFound from "./assets/img/faith.png";
import tinyWater from "./assets/img/tiny-water.svg";

interface State {
    isLoading: boolean;
    project: Project | null;
}

interface Props {
    slug: string;
}

class RwandaWater extends Component<RouteComponentProps<Props>, State> {
    state: State = {
        project: null,
        isLoading: true
    };

    async componentDidMount(): Promise<void> {
        try {
            const project = await getOneProject(this.props.match.params.slug);
            this.setState({
                project,
                isLoading: false
            });
        } catch (e) {
            this.setState({
                project: null,
                isLoading: false
            });
        }
    }

    render() {
        const { isLoading, project } = this.state;

        if (project === null && !isLoading) {
            return (
                <Layout>
                    <Page404 />
                </Layout>
            );
        }

        if (isLoading) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }
        return (
            <Layout>
                <div className="rwandaWater">
                    <div className="rwandaWater_head">
                        <div className="intro clear">
                            <div className="responsive_bg" />
                            <div className="container">
                                <div className="main_text">
                                    <h1 className="h1">WATER PROJECT IN RWANDA</h1>
                                    <p className="subhead">When people get water, they get the source of life</p>
                                </div>
                            </div>
                            <div className="mobile_image" />
                        </div>
                    </div>
                    <div className="rwandaWater_about">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">ABOUT THE PROJECT</h2>
                                <p className="text">
                                    The project was implemented in Kinigi at the initiative of the local non-profit
                                    organization <Link to="../../partners/14">Faith Foundation</Link>. People did not
                                    People did not have access to clean drinking water that negatively affected and
                                    significantly reduced their standard of living. A good source of water was located
                                    nearby, at the school, built by the Faith Foundation, but it was only children who
                                    had access to water, and only during the training period.
                                </p>
                                <div className="project_info">
                                    <div className="project_info_block">
                                        <p>Country</p>
                                        <div>
                                            <span>
                                                <img src={rwandaFlag} alt="" />
                                            </span>
                                            Rwanda
                                        </div>
                                    </div>
                                    <div className="project_info_block">
                                        <p>Project partners</p>
                                        <div>
                                            <span>
                                                <img src={faithFound} alt="" />
                                            </span>
                                            <Link to="../../partners/14">Faith Foundation</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rwandaWater_goals">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">GOAL</h2>
                                <div className="goals_grid">
                                    <div className="goals_block">
                                        <span>
                                            <img src={tinyWater} alt="" />
                                        </span>
                                        <p>
                                            The goal of the project is to satisfy one of the urgent needs of the local
                                            community, providing adults and children access to clean drinking water on
                                            an ongoing basis. Two 5000 litres back-up water tanks were installed to
                                            supply the community in case of a water supply cut. Access to clean water
                                            helps the community to fight poverty: to take care, to grow food, to earn an
                                            income, to improve their homes and to get education.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rwandaWater_participants">
                        <div className="section_inner">
                            <div className="container">
                                <h2 className="h2">PARTICIPANTS</h2>
                                <div className="participants_grid">
                                    <div className="participants_block">
                                        <div className="participants_num">
                                            <span>>75</span>
                                        </div>
                                        <div className="participants_name">families</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default RwandaWater;

import React, { Component } from "react";
import "./assets/style.css";
import Layout from "../../components/Layout";
import { withTranslation, WithTranslation } from "react-i18next";
import { constants } from "../../config";

// const BITRIX_FORM_EN = `
// (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//         (w[b].forms=w[b].forms||[]).push(arguments[0])};
//         if(w[b]['forms']) return;
//         var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//         var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
// })(window,document,'https://b24.iqcharity.org/bitrix/js/crm/form_loader.js','b24form');
//
// b24form({"id":"9","lang":"en","sec":"ni2y30","type":"inline"});
// `;
//
// const BITRIX_FORM_RU = `
//     (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
//             (w[b].forms=w[b].forms||[]).push(arguments[0])};
//             if(w[b]['forms']) return;
//             var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
//             var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
//     })(window,document,'https://b24.iq-charity.com/bitrix/js/crm/form_loader.js','b24form');
//
//     b24form({"id":"17","lang":"ru","sec":"wcacmh","type":"inline"});
// `;

class Contacts extends Component<WithTranslation> {
    // componentDidMount(): void {
    //     const script = document.createElement("script");
    //     script.async = true;
    //     script.id = "bx24_form_inline";
    //     script.setAttribute("data-skip-moving", "true");
    //     script.innerHTML = constants.LOCALE === "ru" ? BITRIX_FORM_RU : BITRIX_FORM_EN;
    //     document.getElementById("formDiv")!.appendChild(script);
    // }

    render() {
        const { t } = this.props;
        return (
            <Layout>
                {constants.LOCALE === "ru" && (
                    <div className="contacts">
                        <div className="contacts_inner">
                            <h1 className="h1">{t("contacts.title")}</h1>
                            <div className="contacts-top clear">
                                <div className="column-vk">
                                    <p className="vk-icon">
                                        <i className="fa fa-vk" />
                                    </p>
                                    <p className="vk-desc">{t("contacts.vkDesk")}</p>
                                    <a
                                        href="https://vk.com/iqcharity"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="vk-button"
                                    >
                                        {t("contacts.enterGroup")}
                                    </a>
                                </div>
                                {/*<div className="column-text">*/}
                                {/*    <div className="column-head">{t("contacts.documentQuestions")}</div>*/}
                                {/*    <div className="column-name">{t("contacts.documentManagerName")}</div>*/}
                                {/*    <div className="column-post">{t("contacts.documentManagerPosition")}</div>*/}
                                {/*    <div className="column-email">*/}
                                {/*        <a href={`mailto:${t("contacts.documentManagerEmail")}`}>*/}
                                {/*            {t("contacts.documentManagerEmail")}*/}
                                {/*        </a>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="contacts-bottom">
                                <div className="address">
                                    <p>
                                        <span>Телефон:</span> +7 (911) 990-42-00
                                    </p>
                                    <p>{t("contacts.address")}</p>
                                    <a href="mailto:hello@iq-charity.com">hello@iq-charity.com</a>
                                </div>
                                <div className="map">
                                    <iframe
                                        title="map"
                                        src="https://snazzymaps.com/embed/145920"
                                        width="100%"
                                        height="348px"
                                    />
                                </div>
                            </div>
                            {/*<div className="container">*/}
                            {/*    <div className="form_contact">*/}
                            {/*        <h2 className="h3">Свяжитесь с нами</h2>*/}
                            {/*        <div id="formDiv" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )}
                {constants.LOCALE === "en" && (
                    <div className="contacts_en">
                        <div className="container">
                            <article className="article">
                                <h2 className="h3">Contacts</h2>
                                <div className="contacts_block">
                                    <div className="contacts_address">
                                        Agiou Athanasiou 33, YIANNIS NICOLAIDES BUSINESS CT, Agios Athanasios, 4102,
                                        Limassol, Cyprus
                                        <div className="br-25" />
                                        <a href="mailto:info@iqcharity.org" className="link">
                                            info@iqcharity.org
                                        </a>
                                        <p className="phoneNumber">+35725030677</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="map">
                            <iframe src="https://snazzymaps.com/embed/54784" width="100%" height="380px" title="map" />
                        </div>
                        <div className="container">
                            <div className="form_contact">
                                <h2 className="h3">Get in touch with us</h2>
                                <div id="formDiv" />
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        );
    }
}

export default withTranslation()(Contacts);

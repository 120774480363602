import React from "react";
import { asset } from "../../utils";
import Partner from "../../models/Partner";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
    partner: Partner;
}

const PartnerLink: React.FC<RouteComponentProps & { partner: Partner }> = props => {
    const { partner, children } = props;
    if (partner.partnerType === "info") {
        return (
            <a
                href={partner.website}
                target="_blank"
                rel="noopener noreferrer"
                key={partner.id}
                className="partner_block"
            >
                {children}
            </a>
        );
    }

    return (
        <Link to={`${props.match.url}/${partner.id}`} className="partner_block">
            {children}
        </Link>
    );
};

const PartnerLinkWithRouteProps = withRouter(PartnerLink);

const PartnerPreview: React.FC<Props> = props => {
    const { t } = useTranslation();
    const { partner } = props;

    return (
        <PartnerLinkWithRouteProps partner={partner}>
            <div className="partner_img">{partner.logoPath && <img src={asset(partner.logoPath)} alt="" />}</div>
            <div className="partner_text">
                <div>{partner.name}</div>
                <span>{t("partners.buttonText")}</span>
            </div>
        </PartnerLinkWithRouteProps>
    );
};

export default PartnerPreview;
